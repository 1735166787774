import { Dispatch } from 'redux'
import { ActionType } from '../action-types'
import { Action } from '../actions/index'
import AxiosCustom from '../../utils/Axios'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { endpoints } from '../../config'

export const getUserNotifications = (email: string) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const url = endpoints.GET_NOTIFICATION_BY_EMAIL.replace(':email', email)

			const { data } = await AxiosCustom.get(url)
			dispatch({
				type: ActionType.GET_USER_NOTIFICATIONS,
				payload: data,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

// export const toggleNotificationReadStatus = (id: string) => {
// 	return async (dispatch: Dispatch<Action>) => {
// 		try {
// 			const url = endpoints.MARK_AS_READ.replace(':id', id)

// 			const { data } = await AxiosCustom.post(url)
// 			dispatch({
// 				type: ActionType.UPDATE_NOTIFICATION,
// 				payload: data,
// 			})
// 		} catch (err) {
// 			console.error(err)
// 		}
// 	}
// }

export const markAsRead = (ids: string[]) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(endpoints.MARK_AS_READ, {
				ids,
			})
			dispatch({
				type: ActionType.GET_USER_NOTIFICATIONS,
				payload: data,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const markAsUnread = (ids: string[]) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(endpoints.MARK_AS_UNREAD, {
				ids,
			})
			dispatch({
				type: ActionType.GET_USER_NOTIFICATIONS,
				payload: data,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const deleteNotifications = (ids: string[]) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const url = endpoints.DELETE_NOTIFICATIONS

			const { data } = await AxiosCustom.post(url, { ids })
			dispatch({
				type: ActionType.DELETE_NOTIFICATION,
				payload: ids,
			})
			// dispatch({
			// 	type: ActionType.SET_SUCCESS,
			// 	payload: {
			// 		title: 'Remove Notification',
			// 		message: 'Notification removed successfully',
			// 	},
			// })
		} catch (err) {
			console.error(err)
		}
	}
}
