import React, { useEffect, useState } from 'react'
import { DialogContent, Box, Typography, Button, useTheme } from '@mui/material'
import { ReactComponent as ErrorMarkIcon } from '../../assets/svg/errormark-icon.svg'

interface ErrorModalProps {
	open: boolean
	title: string
	message: string
	details?: string
	onClose: () => void
	onRetry?: () => void
}

const ErrorModal: React.FC<ErrorModalProps> = ({
	open,
	title,
	message,
	details,
	onClose,
	onRetry,
}) => {
	const theme = useTheme()
	const [animateButton, setAnimateButton] = useState(false)
	const [showDetails, setShowDetails] = useState(false)

	const boxShadowColor =
		theme.palette.mode === 'dark'
			? 'rgba(255, 255, 255, 0.1)'
			: 'rgba(0, 0, 0, 0.1)'

	useEffect(() => {
		if (open) {
			const timer = setTimeout(() => {
				setAnimateButton(true)
			}, 100)
			return () => clearTimeout(timer)
		} else {
			setAnimateButton(false)
			setShowDetails(false)
		}
	}, [open])

	return (
		<>
			{/* Background Overlay */}
			{open && (
				<div
					style={{
						position: 'fixed',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						backgroundColor: theme.colors.modals.overlay,
						backdropFilter: 'blur(3px)',
						zIndex: 900,
					}}
				/>
			)}

			<div
				style={{
					position: 'fixed',
					zIndex: 1000,
					width: '30%',
					minWidth: '30%',
					// maxWidth: '60%',
					top: '35%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
				}}
			>
				{/* Error Icon Positioned Outside */}
				<Box
					sx={{
						width: 100,
						height: 100,
						borderRadius: '50%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						position: 'absolute',
						top: '-50px',
						left: '50%',
						transform: 'translateX(-50%)',
						zIndex: 1500,
						boxShadow: `0px 4px 6px ${boxShadowColor}`,
					}}
				>
					<ErrorMarkIcon
						style={{
							width: 100,
							height: 100,
							color: theme.colors.base.red400,
							stroke: theme.colors.modals.grey,

							fill: theme.colors.modals.grey,
						}}
					/>
				</Box>

				{/* Modal Card */}
				<div
					style={{
						position: 'relative',
						zIndex: 1400,
						width: '100%',
						backgroundColor: theme.colors.base.white,
						borderRadius: '8px',
						boxShadow: `0px 4px 6px ${boxShadowColor}`,
						overflow: 'hidden',
						border: `1px solid ${theme.colors.modals.cardBackground}`
					}}
				>
					<DialogContent
						sx={{
							display: 'flex',
							alignItems: 'flex-start',
							textAlign: 'left',
							flexDirection: 'column',
							padding: '10px 35px 35px 35px',
							gap: 2,
						}}
					>
						{/* Title and Message */}
						<div style={{ width: '100%' }}>
							<Typography
								variant='h6'
								sx={{ fontWeight: 'bold', mb: 1, mt: 6 }}
							>
								{`Error Code: ${title}`}
							</Typography>
							<Typography variant='body1'>{message}</Typography>
						</div>
						<div style={{ width: 'fit-content', maxWidth: '100%' }}>
							{/* Show Details Button */}
							{details && (
								<Button
									onClick={() => setShowDetails(!showDetails)}
									sx={{
										textTransform: 'none',
										fontSize: '14px',
										color: theme.colors.modals.errorDetailsBlue,
										mt: 1,
										padding: '0',
										minWidth: 'auto',
										'&:hover': {
											backgroundColor: 'transparent',
											textDecoration: 'underline',
											color: theme.colors.modals.errorDetailsBlue,
										},
									}}
								>
									{showDetails ? 'Hide Details' : 'Show Details'}
								</Button>
							)}

							{/* Details Box (Appears when "Show Details" is clicked) */}
							{showDetails && (
								<Box
									sx={{
										width: '100%',
										maxWidth: 'inherit',
										minWidth: '250px',
										maxHeight: '140px',
										overflowY: 'auto',
										backgroundColor: theme.colors.modals.errorDetailsBackground,
										padding: '10px',
										borderRadius: '4px',
										marginTop: '8px',
										fontSize: '14px',
										whiteSpace: 'pre-wrap',
										color: theme.colors.modals.errorDetailsText,
										wordBreak: 'break-word',
										alignSelf: 'center',
										justifySelf: 'center',
										flexShrink: 0,
									}}
								>
									{details}
								</Box>
							)}
						</div>
					</DialogContent>
				</div>

				{/* Sliding Button */}
				<div
					style={{
						position: 'absolute',
						bottom: animateButton ? '-48px' : '0px',
						left: '50%',
						transform: 'translateX(-50%)',
						width: '80%',
						transition: 'all 0.5s ease-in-out',
						display: 'flex',
						justifyContent: 'center',
						zIndex: 1300,
					}}
				>
					<Button
						variant='contained'
						onClick={onClose}
						sx={{
							width: '100%',
							borderRadius: '0 0 8px 8px',
							textTransform: 'none',
							fontSize: '16px',
							fontWeight: 'bold',
							padding: '12px 0',
							boxShadow: `0px 4px 6px ${boxShadowColor}`,
							backgroundColor: theme.colors.base.red400,
							color: theme.colors.modals.grey,
							'&:hover': {
								backgroundColor: theme.colors.base.red300,
								opacity: 1,
								color: theme.colors.modals.grey,
							},
						}}
					>
						OK
					</Button>
				</div>
			</div>
		</>
	)
}

export default ErrorModal
