import React, { CSSProperties, Dispatch, SetStateAction } from 'react'
import { styled } from 'styled-components'
import { Button, MenuItem, useTheme } from '@mui/material'
import { StyledMenuItem } from './Informative'
import { v4 as uuidv4 } from 'uuid'

export const UnborderedButton = ({
	onClick,
	style,
	icon,
	label,
	disabled,
	children,
	onMouseEnter,
	onMouseLeave,
	isActive,
	mandatory,
	interacted,
	disableForRestrictedUsage = false,
}: {
	onClick: any
	style?: React.CSSProperties
	icon?: any
	label?: string
	disabled?: boolean
	children?: React.ReactNode
	onMouseEnter?: any
	onMouseLeave?: any
	isActive?: any
	mandatory?: boolean
	interacted?: boolean
	disableForRestrictedUsage?: boolean
}) => {
	const theme = useTheme()
	const renderIconWithStyle = (iconElement: any) => {
		const iconStyles = {
			height: theme.customComponents.button.iconHeight,
			margin: theme.customComponents.button.iconMargin,
		}

		if (React.isValidElement(iconElement)) {
			// Clone the icon with default style + its own style.
			return React.cloneElement(iconElement, {
				style: { ...iconStyles, ...(iconElement as any).props.style },
			} as any)
		}
		return iconElement
	}

	const currentCursor = disableForRestrictedUsage ? 'not-allowed' : 'pointer'
	const currentOpacity = disableForRestrictedUsage ? 0.4 : 1

	return (
		<button
			style={{
				display: 'flex',
				color: theme.colors.text.titles,
				textTransform: 'none',
				border: 'none',
				opacity: currentOpacity,
				cursor: currentCursor,
				fontWeight: theme.font.weight.normal,
				textDecoration: 'underline',
				fontSize: theme.font.size.body,
				alignItems: 'center',
				justifyContent: 'center',
				...style,
			}}
			onClick={disableForRestrictedUsage ? undefined : onClick} // Prevent click if restricted
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			{/* {Icon && (
				<Icon style={{ height: '14px', marginTop: '3px', ...iconStyle }} />
			)} */}
			{icon && renderIconWithStyle(icon)}
			{label && (
				<span>
					{label}
					{mandatory && !interacted && (
						<span
							style={{
								color: `${theme.colors.base.red300}`,
								fontSize: '1rem',
								fontWeight: 'bold',
							}}
						>
							*
						</span>
					)}
				</span>
			)}
			{children && <span>{children}</span>}
		</button>
	)
}

export const StatusButton = ({
	activeFilterHandler,
	possibleCampaignFilter,
	filterStatus,
	setFilterStatus,
	title,
	titleStyle,
	optionsStyle,
}: {
	activeFilterHandler: Function
	possibleCampaignFilter: any
	filterStatus: any
	setFilterStatus: Dispatch<SetStateAction<any>>
	title?: string
	titleStyle?: any
	optionsStyle?: any
}) => {
	const theme = useTheme()
	return (
		<div
			style={{
				display: 'flex',
				gap: '8px',
				fontSize: theme.font.size.body,
				alignItems: 'center',
			}}
		>
			<span
				style={{
					color: theme.colors.text.titles,
					marginTop: '2px',
					...titleStyle,
				}}
			>
				{title ? title : 'Status:'}
			</span>
			{possibleCampaignFilter.map((el: any) => {
				return (
					<StatusButtonOption
						activeFilterHandler={activeFilterHandler}
						possibleCampaignFilter={possibleCampaignFilter}
						filterStatus={filterStatus}
						setFilterStatus={setFilterStatus}
						label={el}
						key={el}
						style={optionsStyle}
					/>
				)
			})}
		</div>
	)
}

const StatusButtonOption = ({
	activeFilterHandler,
	possibleCampaignFilter,
	filterStatus,
	setFilterStatus,
	label,
	style,
}: {
	activeFilterHandler: Function
	possibleCampaignFilter: any
	filterStatus: any
	setFilterStatus: Dispatch<SetStateAction<any>>
	label: string
	style?: any
}) => {
	const handleClick = () => {
		const index = possibleCampaignFilter.indexOf(label)
		setFilterStatus(index)
	}
	const selected = possibleCampaignFilter[filterStatus] === label
	const theme = useTheme()
	return (
		<button
			style={{
				border: 'none',
				color: selected ? theme.colors.text.colored : theme.colors.text.grey,
				cursor: 'pointer',
				fontWeight: theme.font.weight.normal,
				textDecoration: 'underline',
				fontSize: theme.font.size.body,
				textTransform: 'none',
				...style,
			}}
			onClick={() => handleClick()}
		>
			{label}
		</button>
	)
}

export const SubmitButton = styled('button')<any>(({ style, noBackground }) => {
	const theme = useTheme()
	return {
		cursor: 'pointer',
		flow: 'right',
		position: 'relative',
		margin: '10px',
		minWidth: 100,
		height: 30,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		background: noBackground ? 'transparent' : theme.colors.base.green200,
		border: '2px solid ' + theme.colors.base.green200,
		borderRadius: '18px',
		textTransform: 'none',
		fontSize: theme.font.size.body,
		color: noBackground ? theme.colors.chip.textActive : theme.colors.chip.fill,
		padding: '12px 20px 12px 20px',
		'&:hover': {
			background: noBackground
				? theme.colors.base.green20
				: theme.colors.base.green20,
			border: '2px solid ' + theme.colors.base.green200,
			color: 'rgba(36, 45, 50, 1)',
		},
		'&:disabled': {
			background: theme.colors.base.grey100,
			border: '2px solid ' + theme.colors.base.grey100,
			color: 'rgba(146, 146, 157, 1) !important',
			cursor: 'not-allowed',
		},
		'&.Mui-disabled': {
			background: theme.colors.base.grey100,
			border: '2px solid ' + theme.colors.base.grey100,
			color: 'rgba(36, 45, 50, 1)',
			cursor: 'not-allowed',
		},
		'&.Mui-focused': {
			background: theme.colors.base.green100,
		},
		...style,
	}
})

export const SimpleActionsButton = (props: {
	children?: any
	label?: string
	options?: any
	style?: any
	onClick?: any
	icon?: any
	// arrowColor?: string
	// id?: string
	// noArrow?: boolean
	// onMouseEnter?: any
	// onMouseLeave?: any
	// type?: string
}) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	// const [arrowColor, setArrowColor] = useState(props.arrowColor)
	const open = Boolean(anchorEl)

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}
	const MenuOptions = () => {
		if (props.options) {
			return props.options
		}
		return (
			<div>
				<MenuItem key={'menuption_edit'} onClick={handleClose}>
					Edit
				</MenuItem>
				<MenuItem key={'menuption_delete'} onClick={handleClose}>
					Delete
				</MenuItem>
			</div>
		)
	}
	// const handleHoverArrowColor = (hovered: boolean) => {
	// 	if (hovered) {
	// 		setArrowColor('inherit')
	// 	} else {
	// 		setArrowColor(props.arrowColor)
	// 	}
	// }
	// let style = {}
	// if (props.type === 'folder-icon') {
	// 	style = { ...props.style }
	// } else {
	// 	style = {
	// 		...props.style,
	// 		zIndex: 9,
	// 		background:
	// 			props.label === 'Selector' || props.label === 'settings'
	// 				? 'none'
	// 				: 'rgba(0, 0, 0, 0.02)',
	// 		boxShadow:
	// 			props.label === 'Selector' || props.label === 'settings'
	// 				? 'none'
	// 				: '1px 1px 1px 1px rgba(0,0,0,0.2)',
	// 		justifyContent: props.label === 'Selector' ? 'flex-start' : 'center',
	// 	}
	// }
	return (
		<div style={{ display: 'inherit' }}>
			<UnborderedButton
				onClick={(e: any) => {
					handleClick(e)
					//props.onClick()
				}}
				icon={props.icon ? props.icon : undefined}
				style={props.style}
			>
				{props.label}
			</UnborderedButton>
			<StyledMenuItem
				id='demo-positioned-menu'
				aria-labelledby='demo-positioned-button'
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				onClick={handleClose}
				anchorOrigin={{
					vertical: 'top',
					horizontal: props.label === 'Selector' ? 'right' : 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
			>
				<MenuOptions />
			</StyledMenuItem>
		</div>
	)
}

interface actionOption {
	label: string
	icon: any
	onClick: Function
	rule?: Boolean
	style?: CSSProperties
	key: string
}
export const ActionsButton = ({
	options,
	style,
}: {
	options: actionOption[]
	style?: CSSProperties
}) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'center',
				gap: '4px',
				...style,
			}}
		>
			{options.map((el: actionOption) => {
				if (el.rule === true) {
					return (
						<UnborderedButton
							onClick={el.onClick}
							icon={el.icon}
							label={el.label}
							key={el.key}
							style={{
								width: '20px',
								height: '20px',
								...el.style,
							}}
						/>
					)
				} else {
					return <React.Fragment key={el.key}></React.Fragment>
				}
			})}
		</div>
	)
}

export const PidButton = ({
	el,
	onClick,
	style,
}: {
	el: any
	onClick: any
	style?: CSSProperties
}) => {
	// const [hovered, setHovered] = useState(false)

	return (
		<UnborderedButton
			style={{
				marginLeft: '-4px',
				whiteSpace: 'normal', // Allows the text to wrap
				wordWrap: 'break-word', // Breaks long words if necessary
				textAlign: 'center', // Centers the text (optional)
				...style,
			}}
			// onMouseEnter={() => setHovered(true)}
			// onMouseLeave={() => setHovered(false)}
			onClick={onClick}
		>
			{el.pid}
		</UnborderedButton>
	)
}
