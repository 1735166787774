import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../state/reducers'
import { useErrorActions } from '../../hooks/useActions'
import ErrorModal from './ErrorModal'

const GlobalErrorHandler = () => {
	const { clearError } = useErrorActions()
	const dispatch = useDispatch()
	const { isError, errorDetails } = useSelector(
		(state: RootState) => state.errors,
	)

	const handleClose = () => {
		dispatch(clearError())
	}

	if (!isError) return null

	return (
		<ErrorModal
			open={isError}
			title={errorDetails?.errorCode || 'Error'}
			message={errorDetails?.message || 'An unexpected error occurred'}
			onClose={handleClose}
			details={errorDetails?.details}
		/>
	)
}

export default GlobalErrorHandler
