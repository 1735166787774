import React from 'react'
import ReportPage from '../common/ReportPage'

import subDays from 'date-fns/subDays'
import { getInvalidAction } from '../../../state/action-creators/reportActions'
import {
	getRefreshAction,
	saveAppsflyerAction,
} from '../../../state/action-creators'

import {
	ReportGrid,
	ReportItem,
	ReportItemHeading,
	StyledBox,
} from './ReportsStyled'

const InvalidReportsPage = (props: {
	children?: any
	setLoading?: any
	errorMessage: string | null
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	setIsWarningPromptOpened: any
	viewRecord: any
	setViewRecord: any
}) => {
	const initialReportFields = {
		from_date: subDays(new Date(), 1).toISOString(),
		to_date: subDays(new Date(), 1).toISOString(),
		breakdowns: [],
		statistics: [],
		filters: {
			app_id: [''],
			advertiser_name: [''],
			media_source_pid: [''],
			publisher_name: [''],
			email: [''],
			campaign_name: [''],
			campaign_id: [''],
			country: [''],
		},
	}

	// Filters to show in the FilterComponentList
	const filtersToShow = [
		'date_range',
		'advertiser_name',
		'publisher_name',
		'email',
		'app_id',
		'media_source_pid',
		'country',
	]

	// Breakdown options specific to the Invalid Reports
	const breakdownOptions = [
		'Date',
		'Month',
		'Day',
		'Day of the Week',
		'Country',
		'App ID',
		'Media Source Pid',
		'OS',
		'Advertiser Name',
	]

	// Statistic options specific to the Invalid Reports
	const statisticOptions = [
		'Impressions',
		'Clicks',
		'Installs',
		'Non-organic Revenue',
	]

	// The type of the report
	const reportType = 'invalid' as const

	// Selector function to get the specific reports for the Invalid Reports
	const reportSelectors = (reports: any, email: string) =>
		reports.reports[email]?.invalid || []

	return (
		<ReportPage
			reportType={reportType}
			initialReportFields={initialReportFields}
			filtersToShow={filtersToShow}
			breakdownOptions={breakdownOptions}
			statisticOptions={statisticOptions}
			getReportAction={getInvalidAction}
			saveAction={saveAppsflyerAction}
			refreshAction={getRefreshAction}
			reportSelectors={reportSelectors}
			ReportGrid={ReportGrid}
			ReportStyles={{ StyledBox, ReportItem, ReportItemHeading }}
			{...props}
		/>
	)
}

export default InvalidReportsPage
