import React from 'react'
import styled, { CSSProperties } from 'styled-components'
import { Box, TextField, useTheme } from '@mui/material'

export const DayContainer: React.FC<any> = ({
	iscurrentmonth,
	isselected,
	isinrange,
	isstartdate,
	isenddate,
	isdisabled,
	children,
	...props
}) => {
	const theme = useTheme()

	const baseStyle = {
		position: 'relative' as const,
		padding: '5px',
		textAlign: 'center' as const,
		backgroundColor: isselected
			? theme.colors.base.black
			: isinrange
			? theme.colors.base.grey300
			: 'transparent',
		color: isselected
			? theme.colors.base.white
			: isdisabled
			? theme.colors.base.grey300
			: iscurrentmonth
			? theme.colors.base.black
			: theme.colors.base.grey300,
		borderRadius: '4px',
		margin: '0.1rem',
		width: '25px',
		height: '30px',
		fontSize: '0.8rem',
		display: 'flex',
		justifyContent: 'center' as const,
		alignItems: 'center' as const,
		cursor: isdisabled ? 'not-allowed' : 'pointer',
		transition: 'background-color 0.3s, color 0.3s',
	}

	const startArrow = isstartdate ? startArrowStyle(theme) : {}
	const endArrow = isenddate ? endArrowStyle(theme) : {}

	return (
		<div style={{ ...baseStyle, ...startArrow, ...endArrow }} {...props}>
			{children}
		</div>
	)
}

export const DatePickerContainer: React.FC<
	React.HTMLAttributes<HTMLDivElement>
> = (props) => {
	const theme = useTheme()

	const style = {
		display: 'flex',
		flexDirection: 'column' as const,
		alignItems: 'center' as const,
		fontFamily: theme.font.fontFamily,
		position: 'relative' as const,
		marginTop: '-0.2rem',
	}

	return <div style={style} {...props} />
}

export const CalendarContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 1rem;
	width: 250px;
	margin: 1rem 2rem;
`

export const StartArrow: React.FC<any> = ({ children, ...props }) => {
	const theme = useTheme()

	const afterStyle = startArrowStyle(theme)

	return (
		<div style={{ position: 'relative', ...props.style }} {...props}>
			{children}
			<div style={afterStyle}></div>
		</div>
	)
}
const startArrowStyle = (theme: any) => ({
	content: '""',
	position: 'absolute' as const,
	right: '-10px',
	width: 0,
	height: 0,
	borderTop: '6px solid transparent',
	borderBottom: '6px solid transparent',
	borderLeft: `6px solid ${theme.colors.base.black}`,
	zIndex: 1000,
})

export const EndArrow: React.FC<any> = ({ children, ...props }) => {
	const theme = useTheme()

	const afterStyle = endArrowStyle(theme)

	return (
		<div style={{ position: 'relative', ...props.style }} {...props}>
			{children}
			<div style={afterStyle}></div>
		</div>
	)
}
const endArrowStyle = (theme: any) => ({
	content: '""',
	position: 'absolute' as const,
	left: '-10px',
	width: 0,
	height: 0,
	borderTop: '6px solid transparent',
	borderBottom: '6px solid transparent',
	borderRight: `6px solid ${theme.colors.base.black}`,
})

export const Header = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`

export const DaysRow = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`

export const WeekdayHeader = styled.div`
	padding: 5px;
	text-align: center;
	width: 28px;
	height: 30px;
	font-size: 0.8rem;
`

const createInputBoxStyles = (theme: any, extraStyles = {}) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	borderStyle: 'solid',
	borderColor: `${theme.colors.base.grey300}`,
	borderRadius: '5px',
	cursor: 'pointer',
	minHeight: '34px',
	color: `${theme.colors.base.grey400}`,
	...extraStyles,
})

export const DatePickerInputBoxContainer = styled(Box)(({ theme }) =>
	createInputBoxStyles(theme, {
		marginTop: '1.3rem',
		borderWidth: '1px 6px 1px 1px',
	}),
)

export const CustomDatePickerInputBoxContainer = styled(Box)(({ theme }) =>
	createInputBoxStyles(theme, {
		marginTop: '0.1rem',
		borderWidth: '1.5px 6px 1.5px 1.5px',
	}),
)

export const DefaultDatePickerInputBoxContainer = styled(Box)(({ theme }) =>
	createInputBoxStyles(theme),
)

export const StyledTextField = (props: any) => {
	const theme = useTheme()

	const StyledComponent = styled(TextField)(() => ({
		'& .MuiInputBase-root': {
			padding: '0 12px 0 0',
			height: '34px',
			fontSize: '12px',
			color: theme.colors.text.titles,
		},
		'& .MuiOutlinedInput-root': {
			borderRadius: '0px',
			color: theme.colors.text.titles,
		},
		'& .MuiInputLabel-root': {
			top: '-4px',
			color: theme.colors.text.titles,
		},
		'& .MuiOutlinedInput-input': {
			padding: '19px 10px',
			color: theme.colors.text.titles,
		},
		'& .MuiButtonBase-root': {
			color: theme.colors.text.titles,
		},
	}))

	return <StyledComponent {...props} />
}

export const containerStyle: CSSProperties = {
	position: 'relative',
	width: '600px',
}

export const dropdownWrapperStyle = (theme: any) => ({
	position: 'absolute',
	top: 60,
	left: '0',
	zIndex: 1000,
	backgroundColor: `${theme.colors.base.white}`,
	padding: '8px',
	border: `1px solid ${theme.colors.base.grey200}`,
	borderRadius: '8px',
	boxShadow: `0 15px 35px 0px ${theme.colors.base.grey500}`,
	width: '640px',
})

export const rangeSelectorStyle = (theme: any) => ({
	display: 'flex',
	justifyContent: 'space-between',
	marginBottom: '10px',
	border: `1px solid ${theme.colors.base.grey100}`,
	backgroundColor: `${theme.colors.base.grey300}`,
	height: '25px',
	padding: '0.5rem',
	width: '96%',
})

export const rangeButtonStyle = (theme: any) => ({
	flex: 1,
	padding: '10px',
	textTransform: 'none' as 'none',
	fontWeight: 'normal',
	border: 'none',
	fontSize: `${theme.font.size.caption}`,
	color: `${theme.colors.base.black}`,
})

export const activeRangeButtonStyle = (theme: any) => ({
	...rangeButtonStyle(theme),
	borderBottom: `2px solid ${theme.colors.base.black}`,
})

export const innerPlaceholderInputStyle = (theme: any) => ({
	opacity: 0.5,
	whiteSpace: 'no-wrap',
	fontSize: theme.font.size.body,
	color: theme.colors.text.titles,
})

export const CustomInnerPlaceholderInputStyle = (
	theme: any,
): CSSProperties => ({
	opacity: '0.4',
	fontSize: '12px',
	fontWeight: '400',
	whiteSpace: 'nowrap',
	color: theme.colors.text.titles,
	fontFamily: theme.typography.fontFamily,
	textAlign: 'right',
	marginRight: '6rem',
	display: 'block',
})
