export interface BaseReportFields {
	from_date: string
	to_date: string
	breakdowns: string[]
	statistics: string[]
}

export interface ReportFieldsInterface extends BaseReportFields {
	filters: any // For cases with a generic filter type
	user_email?: string
}

// Network Report Interfaces
export interface NetworkFieldsInterface extends BaseReportFields {
	filters: NetworkFilterInterface
}

export interface NetworkFilterInterface {
	app_id: string[]
	app_name: string[]
	media_source_pid: string[]
	platform: string[]
	agency: string[]
	pmd_af_prt: string[]
	username: string[]
}

// Invalid Report Interfaces
export interface InvalidReportFieldsInterface extends BaseReportFields {
	filters: InvalidFilterInterface
}

export interface InvalidFilterInterface {
	app_id: string[]
	advertiser_name: string[]
	media_source_pid: string[]
	publisher_name: string[]
	email: string[]
	campaign_name: string[]
	campaign_id: string[]
	country: string[]
}

// Aggregated Report Interfaces
export interface AggregatedReportFieldsInterface extends BaseReportFields {
	filters: AggregatedReportFilterInterface
}

export interface AggregatedReportFilterInterface {
	app_id: string[]
	advertiser_name: string[]
	media_source_pid: string[]
	publisher_name: string[]
	email: string[]
	campaign_name: string[]
	campaign_id: string[]
	country: string[]
	agencyAccounts: string[]
}

// General Report Type
export type ReportType = 'network' | 'invalid' | 'aggregated'

export interface DefaultReportFieldsInterface {
	from_date: Date
	to_date: Date
	breakdowns: string[]
	statistics: string[]
	filters: any
	user_email?: string
}

export const networkColumnOrder = [
	'date', // Date
	'month', // Month
	'day', // Day
	'day_of_the_week', // Day of the Week
	'app_id', // App ID
	'media_source_pid', // Media Source PID
	'platform', // Platform
	'agency', // Agency
	'username', // Username
	'impressions', // Impressions
	'clicks', // Clicks
	'installs', // Installs
	'cr', // CR (Conversion Rate)
]

export const aggregatedColumnOrder = [
	'date', // Date
	'month', // Month
	'day', // Day
	'day_of_the_week', // Day of the Week
	'country', // Country
	'app_id', // App ID
	'os', // OS
	'advertiser_name', // Advertiser Name
	'advertiser_owner', // Advertiser Owner
	'campaign_name', // Campaign Name
	'media_source_pid', // Media Source PID
	'publisher_name', // Publisher Name
	'agency_account', // Agency Account
	'impressions', // Impressions
	'clicks', // Clicks
	'installs', // Installs
	're_attributions', // Re-attributions
	're_engagements', // Re-engagements
	'total_revenue', // Total Revenue
	'level_2_event', // Level 2 Event
	'level_3_event', // Level 3 Event
	'level_4_event', // Level 4 Event
	'level_5_event', // Level 5 Event
	'revenue', // Revenue
	'cost', // Cost
]

export const invalidColumnOrder = [
	'date', // Date
	'month', // Month
	'day', // Day
	'day_of_the_week', // Day of the Week
	'country', // Country
	'app_id', // App ID
	'media_source_pid', // Media Source PID
	'os', // OS
	'advertiser_name', // Advertiser Name
	'impressions', // Impressions
	'clicks', // Clicks
	'installs', // Installs
	'total_revenue', // Total Revenue
]

export const columnDisplayNameMap: Record<string, string> = {
	date: 'Date',
	month: 'Month',
	day: 'Day',
	day_of_the_week: 'Day of the Week',
	app_id: 'App ID',
	media_source_pid: 'Media Source PID',
	platform: 'Platform',
	agency: 'Agency',
	username: 'Username',
	impressions: 'Impressions',
	clicks: 'Clicks',
	installs: 'Installs',
	cr: 'CR',
	country: 'Country',
	os: 'OS',
	advertiser_name: 'Advertiser Name',
	advertiser_owner: 'Advertiser Owner',
	campaign_name: 'Campaign Name',
	publisher_name: 'Publisher Name',
	agency_account: 'Agency Account',
	re_attributions: 'Re-attributions',
	re_engagements: 'Re-engagements',
	total_revenue: 'Total Revenue',
	level_2_event: 'Level 2 Event',
	level_3_event: 'Level 3 Event',
	level_4_event: 'Level 4 Event',
	level_5_event: 'Level 5 Event',
	revenue: 'Revenue',
	cost: 'Cost',
}

export const reportColumnMap: Record<string, string> = {
	totalRevenue: 'total_revenue',
	impressions: 'Impressions',
	clicks: 'Clicks',
	installs: 'Installs',
	reAttributions: 'Re-attributions',
	reEngagements: 'Re-engagements',
	cr: 'Cr',
	reason: 'reason',
	total: 'Total',
	revenue: 'revenue',
	profit: 'profit',
	cost: 'cost',
	level2Event: 'level_2_event',
	level3Event: 'level_3_event',
	level4Event: 'level_4_event',
	level5Event: 'level_5_event',
}

export const reportNonNumericColumns: string[] = [
	'date',
	'month',
	'day_of_the_week',
	'country',
	'app_id',
	'media_source_pid',
	'os',
	'advertiser_name',
	'advertiser_owner',
	'campaign_name',
	'publisher_name',
	'agency_accounts',
	'platform',
	'agency',
	'username',
	'reason',
]

export const networkColumnOrderNewVersion = [
	'date',
	'month',
	'day',
	'day_of_the_week',
	'app_id',
	'media_source_pid',
	'platform',
	'agency',
	'username',
	'impressions',
	'clicks',
	'installs',
	'cr',
]

export const aggregatedColumnOrderNewVersion = [
	'date',
	'month',
	'day',
	'day_of_the_week',
	'country',
	'app_id',
	'os',
	'advertiser_name',
	'advertiser_owner',
	'campaign_name',
	'media_source_pid',
	'publisher_name',
	'agency_account',
	'impressions',
	'clicks',
	'installs',
	're_attributions',
	're_engagements',
	'total_revenue',
	'level_2_event',
	'level_3_event',
	'level_4_event',
	'level_5_event',
	'revenue',
	'cost',
	'profit',
]

export const invalidColumnOrderNewVersion = [
	'date',
	'month',
	'day',
	'day_of_the_week',
	'country',
	'app_id',
	'media_source_pid',
	'os',
	'advertiser_name',
	'impressions',
	'clicks',
	'installs',
	'total_revenue',
]
