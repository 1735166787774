import React, { useRef, useState, useEffect } from 'react'
import {
	Box,
	Tabs,
	Tab,
	TextField,
	IconButton,
	useTheme,
	Tooltip,
	Typography,
	FormControlLabel,
	Checkbox,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import styled from 'styled-components'
import { SimpleActionsButton, SubmitButton, UnborderedButton } from './Buttons'
import { mergeRefs } from '../../utils/helpers/helperFuncs'
import { saveAs } from 'file-saver'
import { ReactComponent as DownloadIcon } from '../../assets/svg/download-icon.svg'
import { ReactComponent as DeleteIcon } from '../../assets/svg/delete-icon.svg'
import { ReactComponent as EditIcon } from '../../assets/svg/edit-icon.svg'
import { CheckBox } from '@mui/icons-material'
import CustomModal from './CustomModal'

interface ReportTabsProps {
	reportType: string
	filteredReports: { name: string; s3_file_url: string; uuid: string }[]
	selectedReportIndex: number
	setSelectedReportIndex: React.Dispatch<React.SetStateAction<number>>
	visibleStart: number
	setVisibleStart: React.Dispatch<React.SetStateAction<number>>
	visibleCount: number
	loadingTabs: number[]
	editIndex: number | null
	setEditIndex: React.Dispatch<React.SetStateAction<number | null>>
	editedReportName: string
	setEditedReportName: React.Dispatch<React.SetStateAction<string>>
	handleReportTabClick?: (
		event: React.SyntheticEvent | null,
		newValue: number,
	) => void
	handleEditIconClick: (index: number) => void
	handleReportNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	handleReportNameSave: () => Promise<void>
	handleCancelEdit: () => void
	handleDeleteReport: (index: number) => Promise<void>
	containerRef: React.RefObject<HTMLDivElement>
}

const StyledTab = styled(Tab)`
	&& {
		background-color: ${(props: any) =>
			props['aria-selected']
				? props.theme.colors.base.white
				: props.theme.colors.base.grey300};

		color: ${(props: any) =>
			props['aria-selected']
				? props.theme.colors.base.green300
				: props.theme.colors.text.titles};

		font-weight: ${(props: any) => (props['aria-selected'] ? 'bold' : 'normal')};
		font-size: ${(props: any) => (props['aria-selected'] ? '0.7rem' : '0.75rem')};
		border-right: 1px solid ${(props: any) => props.theme.colors.base.white};
		transition: background-color 0.3s, color 0.3s;
		position: relative;
		font-family: 'DM Sans', sans-serif;
		height: 1.5rem;
		text-transform: none;

		&:hover {
			background-color: ${(props: any) => props.theme.colors.base.grey600};
			color: ${(props: any) => props.theme.colors.base.green300};
		}

		/* Styles for edit and download icons */
		.edit-icon,
		.download-icon {
		  color: ${(props: any) => props.theme.colors.text.titles};
		  cursor: pointer;
		  scale: 0.9
		}


		.edit-icon:hover,
		.download-icon:hover {
		  color: ${(props: any) => props.theme.colors.base.green300};
		}

		.edit-icon:active,
		.download-icon:active {
		  color: ${(props: any) => props.theme.colors.base.green600};
		}

		.delete-icon {
		  color: ${(props: any) => props.theme.colors.text.titles};
		  cursor: pointer;
		  scale: 0.9
		}

		.delete-icon:hover {
			color: ${(props: any) => props.theme.colors.base.red300};
		}

		.delete-icon:active {
			color: ${(props: any) => props.theme.colors.base.red600};
		}
	  `

const TabsContainerWrapper = styled(Box)`
	display: flex;
	align-items: center;
	position: relative;
	width: 100%;
	padding: 0.5rem 1rem;
	background-color: ${(props: any) => props.theme.colors.base.grey300};
	border-radius: 12px 12px 0px 0px;

	&:hover .arrow-button {
		opacity: 1;
	}
`

const TabContainer = styled(Box)`
	display: flex;
	align-items: center;
	overflow-x: auto;
	white-space: nowrap;
	position: relative;
	background-color:red
	width: 100%;
	backgrpund-color:
	scrollbar-width: thin;
	scrollbar-color: ${(props: any) => props.theme.colors.text.titles};

	&::-webkit-scrollbar {
		height: 2px;
	}

	&::-webkit-scrollbar-track {
		background: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: ${(props: any) => props.theme.colors.text.titles};
		border-radius: 10px;
	}
`

// const ArrowButton = styled(IconButton)`
// 	color: ${(props: any) => props.theme.colors.text.titles};
// 	z-index: 1;
// 	opacity: 0; /* Hidden by default */
// 	transition: opacity 0.3s, transform 0.3s;

// 	&.left {
// 		position: absolute;
// 		left: 0;
// 		top: 50%;
// 		transform: translateY(-50%);
// 		border-right: 1px solid ${(props: any) => props.theme.colors.base.white};
// 	}

// 	&.right {
// 		position: absolute;
// 		right: 0;
// 		top: 50%;
// 		transform: translateY(-50%);
// 		border-left: 1px solid ${(props: any) => props.theme.colors.base.white};
// 	}

// 	&:hover {
// 		transform: translateY(-50%) scale(0.9); /* Maintain position and scale on hover */
// 		border-color: ${(props: any) =>
// 			props.theme.colors.base.grey900}; /* Differentiate on hover */
// 		background-color: ${(props: any) =>
// 			props.theme.colors.base.grey150}; /* Light background on hover */
// 	}
// `

const EditableTab = styled(Box)`
	display: flex;
	align-items: center;
`

const ReportTabs: React.FC<ReportTabsProps> = ({
	reportType,
	filteredReports,
	selectedReportIndex,
	setSelectedReportIndex,
	visibleStart,
	setVisibleStart,
	visibleCount,
	loadingTabs,
	editIndex,
	setEditIndex,
	editedReportName,
	setEditedReportName,
	handleReportTabClick,
	handleEditIconClick,
	handleReportNameChange,
	handleReportNameSave,
	handleCancelEdit,
	handleDeleteReport,
	containerRef,
}) => {
	const theme = useTheme()

	const [hoveredTabIndex, setHoveredTabIndex] = useState<number | null>(null)
	const scrollRef = useRef<HTMLDivElement>(null)
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
	const [dontShowDeleteModalAgain, setDontShowDeleteModalAgain] =
		useState<boolean>(() => {
			// Retrieve existing preference from local storage if it exists
			const storedPreference = localStorage.getItem('dontShowDeleteModalAgain')
			return storedPreference === 'true' // otherwise default to false
		})

	const [isHoveringDelete, setIsHoveringDelete] = useState(false)

	const [reportToDelete, setReportToDelete] = useState<{
		name: string
		index: number
	} | null>(null)

	const handleDeleteClick = (index: number) => {
		const report = filteredReports[index]
		setReportToDelete({ name: report.name, index })

		// If user already checked "Don't show again," confirm delete immediately
		if (dontShowDeleteModalAgain) {
			handleConfirmDeleteImmediate(index)
		} else {
			// Otherwise, open the confirmation modal
			setIsDeleteModalOpen(true)
		}
	}

	// This will perform the deletion without showing a modal
	const handleConfirmDeleteImmediate = async (index: number) => {
		await handleDeleteReport(index)
		setReportToDelete(null)
	}

	const handleConfirmDelete = async () => {
		if (reportToDelete) {
			await handleDeleteReport(reportToDelete.index)
			setIsDeleteModalOpen(false)
			setReportToDelete(null)
		}
	}

	const handleDownloadReport = (index: number) => {
		const report = filteredReports[index]
		if (report.s3_file_url) {
			saveAs(report.s3_file_url, `${report.name || 'report'}.csv`)
		} else {
			console.error('Download failed: No file URL available for this report.')
		}
	}

	useEffect(() => {
		if (scrollRef.current) {
			const selectedTab = scrollRef.current.querySelector(
				`[data-index="${selectedReportIndex}"]`,
			) as HTMLElement
			if (selectedTab) {
				const containerLeft = scrollRef.current.scrollLeft
				const containerRight = containerLeft + scrollRef.current.clientWidth
				const tabLeft = selectedTab.offsetLeft
				const tabRight = tabLeft + selectedTab.clientWidth

				if (tabLeft < containerLeft || tabRight > containerRight) {
					scrollRef.current.scrollTo({
						left: tabLeft - containerLeft,
						behavior: 'smooth',
					})
				}
			}
		}
	}, [selectedReportIndex])

	let visibleTabs: any[] = []
	try {
		visibleTabs = filteredReports.slice(
			visibleStart,
			visibleStart + visibleCount,
		)
	} catch (error) {
		console.error('Error slicing filteredReports:', error, filteredReports)
	}

	return (
		<>
			<TabsContainerWrapper ref={mergeRefs(containerRef)} theme={theme}>
				<TabContainer ref={scrollRef} theme={theme}>
					<Tabs
						value={selectedReportIndex}
						onChange={handleReportTabClick}
						aria-label='report tabs'
						sx={{
							'& .Mui-selected': {
								color: theme.colors.text.titles,
							},
							'& .MuiTabs-indicator': {
								backgroundColor: theme.colors.text.titles,
							},
							'& .MuiTabs-scroller': {
								overflowX: 'scroll !important',
							},
							width: '100%',
							overflowY: 'scroll',
						}}
					>
						{visibleTabs.map((report: any, index: any) => {
							const globalIndex = visibleStart + index

							// Remove 'Report ' from the beginning of the name
							const displayName = report.name.replace(/^Report\s+/i, '')

							return (
								<StyledTab
									theme={theme}
									key={globalIndex}
									label={
										loadingTabs.includes(globalIndex) ? (
											<Box
												sx={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
												}}
											></Box>
										) : editIndex === globalIndex ? (
											<EditableTab>
												<TextField
													value={editedReportName}
													onChange={handleReportNameChange}
													size='small'
													sx={{ marginRight: 1 }}
												/>
												<SubmitButton
													onClick={handleReportNameSave}
													style={{
														minWidth: '50px',
														height: '30px',
														marginRight: '5px',
													}}
												>
													Save
												</SubmitButton>
												<IconButton
													onClick={handleCancelEdit}
													size='small'
													sx={{ color: theme.colors.text.titles }}
												>
													<CloseIcon />
												</IconButton>
											</EditableTab>
										) : (
											<Box
												sx={{
													display: 'flex',
													alignItems: 'center',
													gap: 2,
													textTransform: 'none',
													width: '100%', // Ensures icons take up available space evenly
												}}
												onMouseEnter={() => setHoveredTabIndex(globalIndex)}
												onMouseLeave={() => setHoveredTabIndex(null)}
											>
												{`Report ${displayName.toLowerCase()}`}

												<div
													style={{
														display: 'flex',
														justifyContent: 'flex-start',
														gap: '2px',
													}}
												>
													<Tooltip title='Edit report name' arrow>
														<IconButton
															size='small'
															onClick={() => handleEditIconClick(globalIndex)}
															className='edit-icon'
														>
															<EditIcon className='edit-icon' />
														</IconButton>
													</Tooltip>

													<Tooltip title='Download this report' arrow>
														<IconButton
															size='small'
															onClick={() => handleDownloadReport(globalIndex)}
															className='download-icon'
														>
															<DownloadIcon className='download-icon' />
														</IconButton>
													</Tooltip>

													<Tooltip title='Delete this report' arrow>
														<IconButton
															size='small'
															onClick={() => handleDeleteClick(globalIndex)}
															className='delete-icon'
														>
															<DeleteIcon className='delete-icon' />
														</IconButton>
													</Tooltip>
												</div>
											</Box>
										)
									}
									aria-selected={globalIndex === selectedReportIndex}
									data-index={globalIndex}
								/>
							)
						})}
					</Tabs>
				</TabContainer>
			</TabsContainerWrapper>

			<CustomModal
				title='Confirm Delete'
				open={isDeleteModalOpen}
				onClose={() => setIsDeleteModalOpen(false)}
				actions={
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'flex-end',
							gap: 2,
						}}
					>
						<UnborderedButton onClick={() => setIsDeleteModalOpen(false)}>
							Cancel
						</UnborderedButton>
						<SubmitButton
							onClick={handleConfirmDelete}
							style={{
								backgroundColor: theme.colors.base.red300,
								border: `2px solid ${theme.colors.base.red300}`,
								color: isHoveringDelete
									? `${theme.colors.base.black}`
									: `${theme.colors.alerts.white}`,
								cursor: 'pointer',
							}}
							onMouseEnter={() => setIsHoveringDelete(true)} // Handle hover start
							onMouseLeave={() => setIsHoveringDelete(false)} // Handle hover end
						>
							Yes, Delete
						</SubmitButton>
					</Box>
				}
			>
				<Typography variant='body2' sx={{ color: theme.colors.text.titles }}>
					Are you sure you want to delete{' '}
					<strong>{reportToDelete?.name}</strong>?
				</Typography>
				<Box sx={{ marginTop: '1rem' }}>
					<FormControlLabel
						control={
							<Checkbox
								checked={dontShowDeleteModalAgain}
								onChange={(e) => {
									setDontShowDeleteModalAgain(e.target.checked)
									localStorage.setItem(
										'dontShowDeleteModalAgain',
										e.target.checked ? 'true' : 'false',
									)
								}}
								sx={{
									color: theme.colors.base.red300,
									'&.Mui-checked': {
										color: theme.colors.base.red300,
									},
								}}
							/>
						}
						label={
							<Typography variant='body2' sx={{ fontSize: '0.8rem' }}>
								Don’t show this message again in the current session
							</Typography>
						}
					/>
				</Box>
			</CustomModal>
		</>
	)
}

export default ReportTabs
