import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { checkInputStartsWithHttp } from '../../utils/helpers/tableHelper'
import { countryList } from '../components/countryList'
import {
	formatDisplayValue,
	getApplicableRules,
	stringIsValidName,
} from '../../utils/helpers/helperFuncs'
import moment from 'moment'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { InputFieldText } from '../components/Inputs'
import {
	CountryListSelector,
	DropList,
	OwnerSelect,
} from '../components/SelectableInputs'
import { ChipInputList } from '../components/ChipInput'
import { Grid } from '@mui/material'
import { useIsAuthorized } from '../../hooks/useIsAuthorized'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { SwitchComponent } from '../components/ToggleComponents'

//this component will render and allow to edit / save a new publisher. It's the main file for all the inputs.
export const PublisherCompanyInfoComponent = (props: {
	handlePastOwnerFinance?: any
	name: string | null
	setName: any
	legalName: string
	setLegalName: any
	address: string
	setAddress: any
	geo: string[]
	setGeo: any
	linkToIO?: string
	setLinkToIO?: any
	setOwnerEmail?: any
	ownerEmail?: string | null
	setSalesRep?: any
	salesRep?: string
	setStatus?: any
	status?: boolean
	setPIDs: any
	pids: string[]
	notes: string
	setNotes: any
	skypeGroup: string
	setSkypeGroup: any
	freeText?: any
	setFreeText?: any
	linkToPubFile?: any
	setLinkToPubFile?: any
	isValid: boolean
	settings?: any
	loginRole?: string
	externalId?: any
	setExternalId?: any
	externalIdInUse: boolean
	setMainHeightChange?: any
	linkToLinkedin: any
	setLinkToLinkedin: any
	linkToWebsite: any
	setLinkToWebsite: any
	publicSkype: boolean
	setPublicSkype: any
	pastOwnerFinance?: any
	setPastOwnerFinance?: any
	users?: any
	mainCategory: string
	setMainCategory: Dispatch<SetStateAction<string>>
	pauseReason?: string
	setPauseReason?: Dispatch<SetStateAction<string>>
	pauseExplanation?: string
	setPauseExplanation?: Dispatch<SetStateAction<string>>
	setHandShakeEditorOpen?: Dispatch<SetStateAction<boolean>>
	setEditablePid?: Dispatch<SetStateAction<string>>
	mandatoryFields?: any
	permissions: any
	agencyAccess?: any
	setAgencyAccess?: any
}) => {
	const { users, login, settings } = useTypedSelector((state) => state)
	//TODO: move permissions to parent
	const permissions = props.permissions
	const [filteredPIDs, setFilteredPIDs] = useState<any>([])
	const [dummiesPids, setDummiesPids] = useState<any>([])
	const [nameTouched, setNameTouched] = useState(false)

	//we want to hide the dummiesPIDs from the user, that's why we filter them.
	useEffect(() => {
		if (props.externalId && props.externalId !== '') {
			const arr = []
			const dummiesArr = []
			for (const pid of props.pids) {
				if (!pid.endsWith(props.externalId.toString())) {
					arr.push(pid)
				} else {
					dummiesArr.push(pid)
				}
			}
			setFilteredPIDs(arr)
			setDummiesPids(dummiesArr)
		}
	}, [props.externalId])
	useEffect(() => {
		if (props.externalId) {
			const newArr = [...filteredPIDs, ...dummiesPids]
			props.setPIDs(newArr)
		}
	}, [filteredPIDs])

	const pidUpdateDeal = (e: any) => {
		setFilteredPIDs(e)
		props.setPIDs(e)
	}
	//we use this in order to work with the style of the pids.
	const handlePIDWidth = (pidLength: number) => {
		if (pidLength > 10) {
			return 12
		} else if (pidLength > 6) {
			return 9
		} else if (pidLength > 2) {
			return 6
		} else return 3
	}
	return (
		<Grid
			container
			columnSpacing={6}
			rowSpacing={4}
			direction='row'
			style={{
				display: 'flex',
				width: '98%',
			}}
			columns={12}
		>
			<Grid item xs={3}>
				<InputFieldText
					label='Company Name'
					type='text'
					value={props.name}
					color={
						nameTouched && props.name && !stringIsValidName(props.name)
							? 'error'
							: 'primary'
					}
					onChange={(e: any) => {
						setNameTouched(true)
						props.setName(e)
					}}
					disabled={!permissions.edit}
					errormessage={
						nameTouched && props.name && !stringIsValidName(props.name)
							? "Company name must not start or end with whitespace and must not contain '/', '%', ',' or '.'"
							: undefined
					}
					mandatory={props.mandatoryFields.name}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Legal Name'
					type='text'
					value={props.legalName}
					onChange={props.setLegalName}
					disabled={!permissions.edit}
					mandatory={props.mandatoryFields.name}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Address'
					type='text'
					value={props.address}
					onChange={props.setAddress}
					disabled={!permissions.edit}
				/>
			</Grid>
			<Grid item xs={3}>
				<CountryListSelector
					label='Address Geo'
					options={countryList}
					value={props.geo}
					singular
					onChange={(e: any) => props.setGeo(e)}
					disabled={!permissions.edit}
				/>
			</Grid>
			{permissions.viewLinks && props.setLinkToIO && (
				<Grid item xs={3}>
					<InputFieldText
						label='Link to IO'
						type='text'
						value={formatDisplayValue(props?.linkToIO, true)}
						onChange={props.setLinkToIO}
						color={
							props.linkToIO &&
							!checkInputStartsWithHttp(props.linkToIO) &&
							props.isValid === false
								? 'error'
								: 'primary'
						}
						disabled={!permissions.edit}
						errormessage={
							props.linkToIO
								? !checkInputStartsWithHttp(props.linkToIO)
									? "Links must start with 'http://' or 'https://'"
									: undefined
								: undefined
						}
						showUrlIcon={true}
					/>
				</Grid>
			)}
			{props.setOwnerEmail && (
				<Grid item xs={3}>
					<OwnerSelect
						onChange={(e: any) => {
							if (props.handlePastOwnerFinance) {
								props.handlePastOwnerFinance(e)
							}
							props.setOwnerEmail(e)
						}}
						label={'Owner'}
						value={props.ownerEmail}
						disabled={!permissions.edit}
					/>
				</Grid>
			)}
			{props.setSalesRep && (
				<Grid item xs={3}>
					<DropList
						onChange={(e: any) => props.setSalesRep(e)}
						options={props.settings.settings.salesRepresentative}
						label={'Sales Representative'}
						value={formatDisplayValue(props?.salesRep)}
						disabled={!permissions.edit}
					/>
				</Grid>
			)}
			{props.setStatus && (
				<Grid item xs={3}>
					<DropList
						onChange={(e: any) =>
							props.setStatus(e === 'Active' ? true : false)
						}
						options={['Active', 'Disabled']}
						label={'Status'}
						value={props.status === true ? 'Active' : 'Disabled'}
						disabled={!permissions.edit}
					/>
				</Grid>
			)}
			{permissions.viewLinks && props.setLinkToPubFile && (
				<Grid item xs={3}>
					<InputFieldText
						label='Link to Publisher File'
						type='text'
						value={formatDisplayValue(props?.linkToPubFile, true)}
						onChange={props.setLinkToPubFile}
						color={
							props.linkToPubFile &&
							!checkInputStartsWithHttp(props.linkToPubFile) &&
							props.isValid === false
								? 'error'
								: 'primary'
						}
						disabled={!permissions.edit}
						errormessage={
							props.linkToPubFile
								? !checkInputStartsWithHttp(props.linkToPubFile)
									? "Links must start with 'http://' or 'https://'"
									: undefined
								: undefined
						}
						showUrlIcon={true}
					/>
				</Grid>
			)}
			{permissions.viewLinks && (
				<Grid item xs={3}>
					<InputFieldText
						label='Publisher website'
						type='text'
						value={props.linkToWebsite}
						onChange={props.setLinkToWebsite}
						color={
							props.linkToWebsite &&
							!checkInputStartsWithHttp(props.linkToWebsite) &&
							props.isValid === false
								? 'error'
								: 'primary'
						}
						disabled={!permissions.edit}
						errormessage={
							props.linkToWebsite
								? !checkInputStartsWithHttp(props.linkToWebsite)
									? "Links must start with 'http://' or 'https://'"
									: undefined
								: undefined
						}
						showUrlIcon={true}
					/>
				</Grid>
			)}
			{permissions.viewLinks && (
				<Grid item xs={3}>
					<InputFieldText
						label='Link to LinkedIn'
						type='text'
						value={props.linkToLinkedin}
						onChange={props.setLinkToLinkedin}
						color={
							props.linkToLinkedin &&
							!checkInputStartsWithHttp(props.linkToLinkedin) &&
							props.isValid === false
								? 'error'
								: 'primary'
						}
						disabled={!permissions.edit}
						errormessage={
							props.linkToLinkedin
								? !checkInputStartsWithHttp(props.linkToLinkedin)
									? "Links must start with 'http://' or 'https://'"
									: undefined
								: undefined
						}
						showUrlIcon={true}
					/>
				</Grid>
			)}
			{props.setExternalId && (
				<Grid item xs={3}>
					<InputFieldText
						disabled={!permissions.edit}
						label='External ID'
						type='number'
						value={props.externalId}
						onChange={props.setExternalId}
						errormessage={
							props.externalIdInUse ? 'This id is already in use' : undefined
						}
					/>
				</Grid>
			)}
			<Grid item xs={3}>
				<DropList
					onChange={(e: any) => props.setMainCategory(e)}
					options={[
						'Direct App/Website',
						'Social Platform',
						'DSP',
						'In App Self-Serve',
						'Incent Self-Serve',
						'Incent Offerwall',
						'Native Ads Platform',
						'Phone Operator',
						'Ad Network',
						'External Media Buying',
					]}
					label={'Main Category'}
					value={props.mainCategory}
					disabled={!permissions.edit}
				/>
			</Grid>
			{permissions.viewLinks && (
				<Grid item xs={6}>
					<InputFieldText
						label='Skype Group Link'
						type='text'
						value={
							props.publicSkype
								? props.skypeGroup
								: permissions.editSkype
								? props.skypeGroup
								: '******'
						}
						onChange={props.setSkypeGroup}
						disabled={
							!permissions.edit || props.publicSkype
								? false
								: permissions.editSkype
								? false
								: true
						}
					/>
				</Grid>
			)}
			<Grid item xs={3}>
				<DropList
					onChange={(e: any) =>
						props.setPublicSkype(e === 'Open' ? true : false)
					}
					options={['Open', 'Private']}
					label={'Skype Group'}
					value={props.publicSkype === true ? 'Open' : 'Private'}
					disabled={
						!permissions.edit || (permissions.edit && !permissions.editSkype)
					}
				/>
			</Grid>
			{props.agencyAccess !== null && (
				<Grid item xs={3}>
					<SwitchComponent
						label='Allow Agency Access'
						value={props.agencyAccess}
						onChange={(e: any) => {
							props.setAgencyAccess?.(e)
						}}
						disabled={false}
					></SwitchComponent>
				</Grid>
			)}
			{props.status !== undefined && props.status === false && (
				<>
					<Grid item xs={3}>
						<DropList
							onChange={props.setPauseReason}
							options={
								props.settings.settings.platforms
									? props.settings.settings.pauseReason.sort()
									: []
							}
							label={'Pause Reason'}
							value={props.pauseReason}
							multiple={false}
							disabled={!permissions.edit}
							noNull={true}
						/>
					</Grid>
					<Grid item xs={3}>
						<InputFieldText
							label='Explanation'
							type='text'
							value={props.pauseExplanation}
							onChange={props.setPauseExplanation}
							disabled={!permissions.edit}
						/>
					</Grid>
				</>
			)}
			{props.setHandShakeEditorOpen && (
				<Grid item xs={12}>
					<ChipInputList
						onChange={(e: any) => pidUpdateDeal(e)}
						label={'PIDs'}
						options={[]}
						value={filteredPIDs.length > 0 ? filteredPIDs : props.pids}
						disabled={!permissions.edit}
						forceTrim={true}
						setMainHeightChange={props.setMainHeightChange}
						clickableFunction={(option) => {
							if (props.setHandShakeEditorOpen && props.setEditablePid) {
								props.setHandShakeEditorOpen(true)
								props.setEditablePid(option.toLowerCase().trim())
							}
						}}
					/>
				</Grid>
			)}
		</Grid>
	)
}
