import WebSocketManager from '../ws/wsManager'
import DataServerWebSocketManager from '../ws/dataServerWsManager'
import { Dispatch } from 'redux'
import { ActionType } from '../state/action-types'
import { AlertStatus } from '../models/alert.interface'
import { Action } from '../state/actions'

export const initializeWebSocket = (
	token: string,
	dispatch: Dispatch,
	addAlert: (
		message: string,
		isError?: boolean,
		link?: string | undefined,
		reportUuid?: string | undefined,
		id?: string | undefined,
		status?: AlertStatus | undefined,
	) => Action,
): void => {
	const wsManager = WebSocketManager.getInstance()

	// Connect WebSocket
	wsManager.connect(token)

	// Listen for notifications
	wsManager.on('NEW_NOTIFICATION', (data) => {
		const notification = data.notification
		// console.log('new notification received: ', data)
		dispatch({
			type: ActionType.CREATE_NOTIFICATION,
			payload: data,
		})
		addAlert(
			notification.message,
			false,
			notification.action_url,
			undefined,
			notification.id,
		)
	})
}

export const initializeDataServerWebSocket = (
	email: string,
	company_id: number,
	dispatch: Dispatch,
	addAlert: (
		message: string,
		isError?: boolean,
		link?: string | undefined,
		reportUuid?: string | undefined,
		id?: string | undefined,
		status?: AlertStatus | undefined,
	) => Action,
) => {
	const wsManager = DataServerWebSocketManager.getInstance()

	// Connect WebSocket
	wsManager.setDispatch(dispatch) 
	wsManager.setAddAlert(addAlert)
	wsManager.connect(email, company_id)
}
