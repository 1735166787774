import { Dispatch } from 'redux'
import { ActionType } from '../action-types'
import { Action } from '../actions/index'
import AxiosCustom from '../../utils/Axios'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { endpoints } from '../../config'

export const getCreatorsAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.CREATOR) // TODO: MATAN -> no permissions for influencers / creators
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.GET_CREATOR,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			// if (
			// 	window.location.pathname !== '/notlogged' &&
			// 	window.location.pathname !== '/'
			// ) {
			// 	window.location.replace(window.location.origin + '/notlogged')
			// }
		}
	}
}

export const insertCreatorAction = (obj: any, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const creator = { data: obj }
			const { data } = await AxiosCustom.post(endpoints.CREATOR, creator)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return null // Return null in case of failure
			}

			// Dispatch the action to update the state
			dispatch({
				type: ActionType.INSERT_CREATOR,
				payload: data.payload,
			})

			dispatch({
				type: ActionType.SET_SUCCESS,
				payload: {
					title: 'Creator Added',
					message: 'The creator was added successfully.',
				},
			})

			return data.payload // Ensure this returns the full payload
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
			return null // Handle any errors by returning null
		}
	}
}

export const deleteCreator = (id: number, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.delete(endpoints.CREATOR, {
				data: { id },
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			dispatch({
				type: ActionType.SET_SUCCESS,
				payload: {
					title: 'Creator Deleted',
					message: 'The creator was deleted successfully.',
				},
			})
		} catch (err) {
			setErrorMessage(err)
		}
	}
}
export const insertCreatorsAction = (formData: FormData) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const updatedHeaders = {
				// ...headers,
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			}
			const { data } = await AxiosCustom.post(endpoints.CREATORS, formData, {
				headers: updatedHeaders,
			})
			if (!data.successful) {
				return data.payload
			}

			dispatch({
				type: ActionType.SET_SUCCESS,
				payload: {
					title: 'Creators Added',
					message: 'New creators were added successfully.',
				},
			})
			// dispatch({
			// 	type: ActionType.INSERT_CREATORS,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}

export const updateCreatorAction = (obj: any, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const creator = { data: obj }
			const { data } = await AxiosCustom.put(
				`${endpoints.CREATOR}/${obj.id}`,
				creator,
			)

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			dispatch({
				type: ActionType.UPDATE_CREATOR,
				payload: data.payload,
			})

			dispatch({
				type: ActionType.SET_SUCCESS,
				payload: {
					title: 'Creator Updated',
					message: 'The creator was updated successfully.',
				},
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
