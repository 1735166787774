import React, { FC } from 'react'
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import { useTheme } from '@mui/material'

const OFFICE_VIEWER_URL = 'https://view.officeapps.live.com/op/embed.aspx'

interface DocumentPreviewProps {
	doc: { uri: string }[] // Expecting an array with a single document object
}

// Helper function to determine file extension
const getFileExtension = (url: string): string => {
	const parts = url.split('?')[0].split('.')
	return parts[parts.length - 1].toLowerCase()
}

const isOfficeDocument = (extension: string) => {
	const supportedExtensions = ['docx', 'doc', 'xlsx', 'pptx']
	return supportedExtensions.includes(extension)
}

const DocumentPreview: FC<DocumentPreviewProps> = ({ doc }) => {
	const theme = useTheme()
	// Handle empty or invalid document
	if (!doc || doc.length === 0 || !doc[0]?.uri) {
		return (
			<div
				style={{
					width: '100%',
					height: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					fontSize: '16px',
					color: `${theme.colors.base.grey700}`,
				}}
			>
				No document available
			</div>
		)
	}

	const fileUrl = doc[0].uri
	const extension = getFileExtension(fileUrl)

	// Render Office Viewer for supported Office file types
	if (isOfficeDocument(extension)) {
		return (
			<iframe
				src={`${OFFICE_VIEWER_URL}?src=${encodeURIComponent(fileUrl)}`}
				style={{ width: '100%', height: '100%', border: 'none' }}
				title='Document Viewer'
			/>
		)
	}

	return (
		<div
			style={{
				width: '100%',
				height: '100%',
				backgroundColor: `${theme.colors.base.white}`,
				color: `${theme.colors.base.black}`,
				overflow: 'auto',
			}}
		>
			<DocViewer
				documents={doc}
				pluginRenderers={DocViewerRenderers}
				style={{
					width: '100%',
					height: '100%',
				}}
			/>
		</div>
	)
}

export default DocumentPreview
