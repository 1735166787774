import { Dispatch } from 'redux'
import { ActionType } from '../action-types'
import { Action } from '../actions/index'
import AxiosCustom from '../../utils/Axios'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { endpoints } from '../../config'
import { isUuid } from '../../pages/campaigns/CampaignComponents'


export const getCampaignAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.CAMPAIGN, {
				headers: {
					'x-permission-name': permissionNames.VIEW_CAMPAIGN,
				},
			})
			if (!data.successful) {
				return
			}
			// console.log('data from campaigns', data.payload)
			dispatch({
				type: ActionType.GET_CAMPAIGN,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			// if (
			// 	window.location.pathname !== '/notlogged' &&
			// 	window.location.pathname !== '/'
			// ) {
			// 	window.location.replace(window.location.origin + '/notlogged')
			// }
		}
	}
}

export const getCampaignsInternalReport = (
	setFileDownload: any,
	rows: any,
	// headers: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const report = { report: rows }
			const { data } = await AxiosCustom.post(
				endpoints.CAMPAIGN + '/internalreport',
				report,
				{
					headers: {
						'x-permission-name': permissionNames.DOWNLOAD_CAMPAIGN_REPORT,
					},
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			setFileDownload(data.payload)
		} catch (err) {
			console.error(err)
			setErrorMessage(err)
		}
	}
}
export const getCampaignsExternalWithCostReport = (
	setFileDownload: any,
	rows: any,
	// headers: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const report = { report: rows }
			// console.log(report)
			const { data } = await AxiosCustom.post(
				endpoints.CAMPAIGN + '/externalreportwithcost',
				report,
				{
					headers: {
						'x-permission-name': permissionNames.DOWNLOAD_CAMPAIGN_REPORT,
					},
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			setFileDownload(data.payload)
		} catch (err) {
			console.error(err)
			setErrorMessage(err)
		}
	}
}
export const getCampaignsExternalWithoutCostReport = (
	setFileDownload: any,
	rows: any,
	// headers: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const report = { report: rows }
			// console.log(report)
			const { data } = await AxiosCustom.post(
				endpoints.CAMPAIGN + '/externalreportwithoutcost',
				report,
				{
					headers: {
						'x-permission-name': permissionNames.DOWNLOAD_CAMPAIGN_REPORT,
					},
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			setFileDownload(data.payload)
		} catch (err) {
			console.error(err)
			setErrorMessage(err)
		}
	}
}

export const insertCampaignAction = (
	// headers: any,
	obj: any,
	ruleSet: any,
	setErrorMessage: any,
	setNewUuid: any,
	campaignObject: any,
) => {
	// console.log('ss')

	return async (dispatch: Dispatch<Action>) => {
		try {
			const campaign = { campaign: obj, rule_set: ruleSet }
			// console.log('>>')

			const { data } = await AxiosCustom.put('campaign', campaign, {
				headers: {
					'x-permission-name': permissionNames.ADD_NEW_CAMPAIGNS, // TODO: MATAN -> should reconsider if we need add or just add_edit permissions
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
			}
			if (Array.isArray(data.payload)) {
				for (const element of data.payload) {
					if (!isUuid(element.uuid)) {
						return data.payload
					} else {
						setNewUuid(data.payload)
						// if (campaignObject.uniques[element.role]) {
						// 	campaignObject.uniques[element.role].uuid = element.uuid
						// }
					}
				}
			} else if (isUuid(data.payload)) {
				setNewUuid(data.payload)
			}
			dispatch({
				type: ActionType.SET_SUCCESS,
				payload: {
					title: 'Campaign Insert',
					message: 'Campaign added successfully',
				},
			})

			return data.payload
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const saveCampaignHistoryAction = async (
	// headers: any,
	obj: any,
	setErrorMessage: any,
	setNewUuid: any,
	campaignObject: any,
) => {
	try {
		const campaign = { campaign: obj }
		const { data } = await AxiosCustom.put(
			endpoints.CAMPAIGNHISTORY,
			campaign,
			{
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_CAMPAIGN,
				},
			},
		)
		if (!data.successful) {
			setErrorMessage(data.payload)
		}

		return data.payload
	} catch (err) {
		setErrorMessage(err)
		console.error(err)
	}
}
export const duplicateCampaignAction = (
	// headers: any,
	obj: any,
	ruleSet: any,
	setErrorMessage: any,
	setNewUuid: any,
) => {
	// console.log('ss')

	return async (dispatch: Dispatch<Action>) => {
		try {
			const campaign = { campaign: obj, rule_set: ruleSet }
			// console.log('???????')

			const { data } = await AxiosCustom.put('campaign/duplicate', campaign, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_CAMPAIGN,
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
			}
			if (Array.isArray(data.payload)) {
				for (const element of data.payload) {
					if (!isUuid(element.uuid)) {
						return data.payload
					}
				}
				setNewUuid(data.payload)
			} else if (isUuid(data.payload)) {
				setNewUuid(data.payload)
			}
			dispatch({
				type: ActionType.SET_SUCCESS,
				payload: {
					title: 'Campaign Duplicate',
					message: 'Campaign duplicated successfully',
				},
			})

			return data.payload
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const getCampaignHistoryAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.CAMPAIGNHISTORY, {
				headers: {
					'x-permission-name': permissionNames.VIEW_CAMPAIGN, // TODO: MATAN -> we might need a separate permission for campaign history
				},
			})
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GET_CAMPAIGN_HISTORY,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			// if (
			// 	window.location.pathname !== '/notlogged' &&
			// 	window.location.pathname !== '/'
			// ) {
			// 	window.location.replace(window.location.origin + '/notlogged')
			// }
		}
	}
}
export const getCampaignHistoryByMainDtoAction = (
	// headers: any,
	dto: any,
	setHistoryLoading: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(
				endpoints.CAMPAIGNHISTORY,
				{
					campaign: dto,
				},
				{
					headers: {
						'x-permission-name': permissionNames.VIEW_CAMPAIGN, // TODO: MATAN -> we might need a separate permissions for this.
					},
				},
			)
			if (!data.successful) {
				return
			}
			setHistoryLoading(false)
			dispatch({
				type: ActionType.GET_CAMPAIGN_HISTORY,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			// if (
			// 	window.location.pathname !== '/notlogged' &&
			// 	window.location.pathname !== '/'
			// ) {
			// 	window.location.replace(window.location.origin + '/notlogged')
			// }
		}
	}
}
export const getCampaignPids = (
	// headers: any,
	obj: any,
	setPidList: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const payload = { app_id: obj }
			const { data } = await AxiosCustom.post(endpoints.CAMPAIGN, payload, {
				headers: {
					'x-permission-name': permissionNames.VIEW_CAMPAIGN,
				},
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			setPidList(data.payload)
			dispatch({
				type: ActionType.INSERT_CAMPAIGN,
				payload: data,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const deleteCampaign = (
	// headers: any,
	obj: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.delete(endpoints.CAMPAIGN, {
				data: {
					campaign: obj,
				},
				headers: {
					'x-permission-name': permissionNames.DELETE_CAMPAIGN,
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			dispatch({
				type: ActionType.DELETE_CAMPAIGN,
				payload: obj,
			})
			dispatch({
				type: ActionType.SET_SUCCESS,
				payload: {
					title: 'Campaign Delete',
					message: 'Campaign removed successfully',
				},
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}