import React, {
	useEffect,
	ReactNode,
	useState,
	useMemo,
	useCallback,
} from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import { RadioChipList } from '../../components/ChipInput'
import { LabeledListSection } from '../../components/Lists'
import { UnborderedButton, SubmitButton } from '../../components/Buttons'
import { convertToCSV } from '../../../utils/helpers/helperFuncs'
import { LoadingOverlay } from '../../../assets/svg/loading'
import { reportTypeMapping } from '../../../config'
import CustomModal from '../../components/CustomModal'
import { useAlertsActions, useReportActions } from '../../../hooks/useActions'
import { ReportType } from '../../../models/reports.interface'
import { useDispatch } from 'react-redux'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { keyframes } from '@mui/system'

// 1) Define keyframes
const flicker = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
`

// 2) Create a styled <span> for flickering
const FlickeringSpan = styled('span')(({ theme }) => ({
	animation: `${flicker} 3s infinite`,
	fontWeight: 'bold',
	color: theme.colors.base.yellow300,
}))

const DownloadButtonContainer = styled(Box)(({ theme }) => ({
	position: 'absolute',
	top: '3rem',
	right: '10rem',
	[theme.breakpoints.down('sm')]: {
		position: 'relative',
		top: 'auto',
		right: 'auto',
		textAlign: 'center',
	},
}))

const LoaderContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	position: 'fixed',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '100%',
	height: '100%',
	zIndex: 9999,
}))

const BlurOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(10, 2, 9, 0.1);
	backdrop-filter: blur(6px);
	z-index: 1200;
`

interface ReportsPageLayoutProps {
	filtersComponentList: ReactNode
	breakdownOptions: string[]
	statisticOptions: string[]
	reportFields: any
	setReportFields: React.Dispatch<React.SetStateAction<any>>
	getReportAction: any
	saveAction: any
	refreshAction?: any
	setLoading: any
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	setIsWarningPromptOpened: any
	viewRecord: any
	setViewRecord: any
	breakdownFields: string[]
	setBreakdownFields: React.Dispatch<React.SetStateAction<string[]>>
	statisticsFields: string[]
	setStatisticsFields: React.Dispatch<React.SetStateAction<string[]>>
	presetVisible: boolean
	setPresetVisible: React.Dispatch<React.SetStateAction<boolean>>
	setReport: React.Dispatch<React.SetStateAction<any>>
	isSaving: boolean
	setIsSaving: React.Dispatch<React.SetStateAction<boolean>>
	isReportOpen: boolean
	setIsReportOpen: React.Dispatch<React.SetStateAction<boolean>>
	ReportGrid: any
	ReportStyles: any
	setFileDownload: React.Dispatch<React.SetStateAction<any>>
	headers?: any
	login: any
	report?: any
	columns?: any
	loading?: boolean
	reportType: ReportType
	userEmail: string
	selectedReport?: any
	onRunReport?: () => Promise<void>
}

const ReportsPageLayout: React.FC<ReportsPageLayoutProps> = ({
	filtersComponentList,
	breakdownOptions,
	statisticOptions,
	reportFields,
	setReportFields,
	setLoading,
	setErrorMessage,
	breakdownFields,
	setBreakdownFields,
	statisticsFields,
	setStatisticsFields,
	setReport,
	setIsReportOpen,
	ReportGrid,
	ReportStyles,
	headers,
	report,
	loading,
	reportType,
	userEmail,
	selectedReport,
	onRunReport,
}) => {
	const theme = useTheme()
	const { StyledBox } = ReportStyles
	const [isRunEnabled, setIsRunEnabled] = useState(false)
	const [isRunModalOpen, setIsRunModalOpen] = useState(false)
	const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
	const [isCancelEnabled, setIsCancelEnabled] = useState(false)
	const { generateReportAction } = useReportActions()
	const dispatch = useDispatch()
	const alertActions = useAlertsActions()

	const memoizedBreakdownFields = useMemo(
		() => breakdownFields,
		[breakdownFields],
	)
	const memoizedStatisticsFields = useMemo(
		() => statisticsFields,
		[statisticsFields],
	)

	useEffect(() => {
		// Check if date range is selected and valid
		const isDateRangeSelected =
			reportFields.from_date !== '' && reportFields.to_date !== ''

		// Check if any filter other than the date range is selected
		const isOtherFilterSelected = reportFields.filters
			? Object.values(reportFields.filters).some(
					(filterArray: any) => filterArray.length > 0 && filterArray[0] !== '',
			  )
			: false

		const isBreakdownSelected = breakdownFields.length > 0
		const isStatisticSelected = statisticsFields.length > 0

		// Enable the Run button only if the date range is valid and other required fields are selected
		setIsRunEnabled(
			isDateRangeSelected && isBreakdownSelected && isStatisticSelected,
		)

		// Enable the Cancel button if any of the fields are filled
		setIsCancelEnabled(
			isDateRangeSelected ||
				isOtherFilterSelected ||
				isBreakdownSelected ||
				isStatisticSelected,
		)
	}, [reportFields, breakdownFields, statisticsFields])

	const trySubmit = async () => {
		setIsRunModalOpen(true)
	}

	const handleConfirmRun = async () => {
		setIsRunModalOpen(false)
		setLoading(true) // Show the loader

		const breakdowns = breakdownFields.length > 0 ? breakdownFields : ['date']
		const statistics =
			statisticsFields.length > 0 ? statisticsFields : ['impressions']

		const filledFilters = Object.fromEntries(
			Object.entries(reportFields.filters).map(([key, value]) => [
				key,
				(value as string[]).filter((v) => v !== ''), // filter out empty strings
			]),
		)
		const sanitizedStatics = statisticsFields.map((el: string) =>
			el.toLowerCase() === 'non-organic revenue' ? 'Total Revenue' : el,
		)
		const data = {
			...reportFields,
			breakdowns,
			statistics: sanitizedStatics,
			filters: filledFilters,
			from_date: reportFields.from_date,
			to_date: reportFields.to_date,
			user_email: userEmail,
		}

		setReportFields(data)

		try {
			// Dispatch the async action properly
			const generatedReport = await dispatch(
				generateReportAction(
					headers,
					data,
					reportTypeMapping[reportType],
					setErrorMessage,
					setLoading,
					setIsReportOpen,
					userEmail,
					alertActions,
				),
			)

			setReport(generatedReport)

			setLoading(false) // Set loading to false after report generation is complete
		} catch (error) {
			setLoading(false) // Set loading to false in case of error
		}
	}

	const handleCancel = () => {
		if (isCancelEnabled) {
			setIsCancelModalOpen(true)
		}
	}

	const handleConfirmCancel = () => {
		setIsCancelModalOpen(false)
		setReportFields({
			...reportFields,
			filters: Object.keys(reportFields.filters).reduce((acc, key) => {
				acc[key] = []
				return acc
			}, {} as any),
		})
		setBreakdownFields([])
		setStatisticsFields([])
		setErrorMessage(null)
		setIsReportOpen(false)
	}

	const handleCloseModal = () => {
		setIsRunModalOpen(false)
		setIsCancelModalOpen(false)
	}

	const handleBreakdownSelectToggle = () => {
		if (breakdownFields.length === breakdownOptions.length) {
			setBreakdownFields([])
		} else {
			setBreakdownFields([...breakdownOptions])
		}
	}

	const handleStatisticSelectToggle = () => {
		if (statisticsFields.length === statisticOptions.length) {
			setStatisticsFields([])
		} else {
			setStatisticsFields([...statisticOptions])
		}
	}

	return (
		<ThemeProvider theme={theme}>
			{loading && (
				<LoaderContainer>
					<LoadingOverlay loading={loading} />
				</LoaderContainer>
			)}
			{isRunModalOpen && <BlurOverlay />}
			<ReportGrid
				container
				spacing={3}
				style={{
					position: 'relative',
					marginBottom: reportFields ? '20px' : '90px',
				}}
			>
				<Grid
					container
					direction='row'
					justifyContent='space-between'
					alignItems='center'
				>
					<Grid item xs />
				</Grid>

				<Grid container direction='column' spacing={3}>
					<Grid item style={{ marginTop: '4rem' }}>
						<LabeledListSection title='Filters'>
							{filtersComponentList}
						</LabeledListSection>
					</Grid>

					<Grid item>
						<LabeledListSection
							title='Breakdown Fields'
							actionComponent={
								<UnborderedButton
									style={{ marginRight: '5rem' }}
									label={
										breakdownFields.length === breakdownOptions.length
											? 'Clear All'
											: 'Select All'
									}
									onClick={handleBreakdownSelectToggle}
								/>
							}
						>
							<RadioChipList
								options={breakdownOptions}
								selectedOptions={memoizedBreakdownFields}
								setSelectedOptions={setBreakdownFields}
								chipStyle={{ minWidth: '8rem', maxWidth: '12rem' }}
							/>
						</LabeledListSection>
					</Grid>

					<Grid item>
						<LabeledListSection
							title='Statistic Fields'
							actionComponent={
								<UnborderedButton
									style={{ marginRight: '5rem' }}
									label={
										statisticsFields.length === statisticOptions.length
											? 'Clear All'
											: 'Select All'
									}
									onClick={handleStatisticSelectToggle}
								/>
							}
						>
							<RadioChipList
								options={statisticOptions}
								selectedOptions={memoizedStatisticsFields}
								setSelectedOptions={setStatisticsFields}
								chipStyle={{ minWidth: '8rem', maxWidth: '12rem' }}
							/>
						</LabeledListSection>
					</Grid>
				</Grid>

				<Grid container justifyContent='center' marginTop='1rem' spacing={1}>
					<Grid item>
						<UnborderedButton
							onClick={handleCancel}
							label={'Cancel'}
							style={{
								marginRight: '2rem',
								marginTop: '0.7rem',
								opacity: isCancelEnabled ? 1 : 0.5,
								cursor: isCancelEnabled ? 'pointer' : 'not-allowed',
							}}
							disabled={!isCancelEnabled}
						/>
					</Grid>
					<Grid item>
						<SubmitButton
							onClick={trySubmit}
							variant='contained'
							color='primary'
							disabled={!isRunEnabled}
						>
							Run
						</SubmitButton>
					</Grid>
				</Grid>
			</ReportGrid>
			<CustomModal
				title='Report Generation Confirmation'
				open={isRunModalOpen}
				onClose={handleCloseModal}
				actions={
					<Box display='flex' justifyContent='flex-end' width='100%'>
						<SubmitButton
							onClick={handleConfirmRun}
							variant='contained'
							color='primary'
						>
							OK
						</SubmitButton>
					</Box>
				}
			>
				{/*
    'sx={{ px: 3 }}' applies horizontal (left & right) padding of 3 units
    (equivalent to 24px if default spacing = 8px).
  */}
				<Box display='flex' flexDirection='column' gap={2} sx={{ px: 3 }}>
					{/* First row: 24h info */}
					<Box display='flex' alignItems='center' gap={2}>
						<AccessTimeIcon
							style={{ color: theme.colors.base.yellow300, fontSize: '1.5rem' }}
						/>
						<Typography variant='body1'>
							This report will be available for{' '}
							<FlickeringSpan>24 hours</FlickeringSpan>, after which it will be
							automatically deleted.
						</Typography>
					</Box>

					{/* Second row: Keep browsing */}
					<Box display='flex' alignItems='center' gap={2}>
						<InfoOutlinedIcon
							style={{ color: theme.colors.base.grey500, fontSize: '1.5rem' }}
						/>
						<Typography variant='body1'>
							Keep browsing; we’ll notify you as soon as the report is ready for
							download.
						</Typography>
					</Box>
				</Box>
			</CustomModal>

			<CustomModal
				title='Report Generation Confirmation'
				open={isRunModalOpen}
				onClose={handleCloseModal}
				actions={
					<Box display='flex' justifyContent='flex-end' width='100%'>
						<SubmitButton
							onClick={handleConfirmRun}
							variant='contained'
							color='primary'
						>
							OK
						</SubmitButton>
					</Box>
				}
			>
				<Box
					display='flex'
					flexDirection='column'
					gap={3}
					sx={{ px: 3, py: 2 }}
				>
					{/* 24 hours notice */}
					<Box display='flex' alignItems='center' gap={1.5}>
						<AccessTimeIcon
							style={{
								color: theme.colors.base.yellow300,
								fontSize: '1.5rem',
							}}
						/>
						<Typography variant='body1'>
							Your report will be available for{' '}
							<Typography
								component='span'
								fontWeight='bold'
								color={`${theme.colors.base.yellow300}`}
							>
								24 hours
							</Typography>{' '}
							before it’s automatically deleted.
						</Typography>
					</Box>

					{/* Notification and browsing */}
					<Box display='flex' alignItems='center' gap={1.5}>
						<InfoOutlinedIcon style={{ fontSize: '1.5rem' }} />
						<Typography variant='body1'>
							We’ll notify you once it’s ready. Feel free to keep browsing!
						</Typography>
					</Box>
				</Box>
			</CustomModal>
		</ThemeProvider>
	)
}

export default ReportsPageLayout
