import {
	Checkbox,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	styled,
	Typography,
	useTheme,
} from '@mui/material'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import {
	useActions,
	useLogActions,
	useSuccessActions,
	useUserActions,
} from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import {
	UserInterface,
	UserPayloadInterface,
} from '../../models/model.interface'
import {
	shallowEqual,
} from '../../utils/helpers/tableHelper'
import { UserPopUpForm } from './UserPopUpComponents'
import { countryList } from '../components/countryList'
import { InputFieldText } from '../components/Inputs'
import { DropList } from '../components/SelectableInputs'
import { SubmitButton, UnborderedButton } from '../components/Buttons'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { useIsAuthorized } from '../../hooks/useIsAuthorized'

const country_codes = countryList.map((item) => item.slice(0, 2))

const UserPopUp = (props: {
	PopUpVisible: boolean
	setPopUpVisible: any
	handleClose: any
	setLoading: any
	row?: UserInterface
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
}): JSX.Element => {
	const theme = useTheme()
	const addingUser = props.row === undefined
	const { insertLog } = useLogActions()
	const { insertUser, editUser } = useUserActions()
	const { getLMSRoles } = useActions()
	const { setSuccess } = useSuccessActions()
	const { login, settings, users, roles } = useTypedSelector((state) => state)
	const permissions = {
		edit: useIsAuthorized(permissionNames.ADD_EDIT_USERS),
		blockUser: useIsAuthorized(permissionNames.BLOCK_USERS),
		addLmsUser: useIsAuthorized(permissionNames.ADD_EDIT_SKILLSHOP_USER),
	}
	const [name, setName] = useState<string | null>(null)
	const [email, setEmail] = useState<string | null>(null)
	const [password, setPassword] = useState<string | null>(null)
	const [role, setRole] = useState<string | null>(null)
	const [team, setTeam] = useState<string | null>(null)
	const [teamLeader, setTeamLeader] = useState<string | null>(null)
	const [location, setLocation] = useState<string | null>(null)
	const [position, setPosition] = useState<string | null>(null)
	const [department, setDepartment] = useState<string | null>(null)
	const [isValid, setIsValid] = useState<boolean>(false)
	const [isBlocked, setIsBlocked] = useState<boolean>(false)
	const [prevState, setPrevState] = useState<any>(null)
	const [isTestAccess, setIsTestAccess] = useState(false)
	const [financeControlAccess, setFinanceControlAccess] = useState(false)
	// const [userType, setUserType] = useState<string | null>(null)
	const [userPermissions, setUserPermissions] = useState<string | null>(null)
	const [lmsAccess, setLmsAccess] = useState<boolean>(
		!!login.company.lms_company_id,
	)
	const [addToLMS, setAddToLMS] = useState<boolean>(false)
	const [userType, setUserType] = useState('')
	const [selectedLmsRole, setSelectedLmsRole] = useState('')
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState('')
	const [lmsRoles, setLmsRoles] = useState<any>([])

	const isAgencyCompanyType = login.company.type === 'agency'

	const fillFields = (row: any) => {
		const teamLeaderUser = users.users.find((user: any) => {
			return user.email === row.team_leader
		})
		const teamLeaderName = teamLeaderUser ? teamLeaderUser.name : ''

		setRole(row!.role)
		// setUserType(row!.user_type)
		setEmail(row!.email)
		setName(row!.name)
		setIsBlocked(row!.blocked)
		setPosition(row!.position)
		setTeam(row!.team)
		setIsTestAccess(row!.testaccess)
		setLocation(row!.location)
		setDepartment(row!.department)
		setTeamLeader(teamLeaderName)
		setFinanceControlAccess(row!.finance_control_access)
		setUserPermissions(row!.permissions)
		setAddToLMS(!!row.lms_status)
		if (!!row.lms_status) {
			setUserType(row.lms_status.type)
			setSelectedLmsRole(row.lms_status.role)
			setStartDate(row.lms_status.startDate)
			setEndDate(row.lms_status.endDate)
		}
	}
	const preparePrevState = (prevState: any) => {
		const { ...result } = prevState
		return result
	}
	const prepareNewState = (newState: any) => {
		const { ...result } = newState
		return result
	}

	const nullifyFields = () => {
		setName(null)
		setEmail(null)
		setPassword(null)
		// setUserType(null)
		setRole(null)
		setIsBlocked(false)
		setPosition(null)
		setTeam(null)
		setTeamLeader(null)
		setFinanceControlAccess(false)
		setUserType('')
		setSelectedLmsRole('')
		setStartDate('')
		setEndDate('')
	}
	useEffect(() => {
		if (props.row) {
			const obj = _.cloneDeep(props.row)
			const prev = preparePrevState(obj)
			// if (!prevState) {
			setPrevState({ ...prev })
			// }
		}
	}, [props.row])

	useEffect(() => {
		const getRoles = async () => {
			const res = await getLMSRoles(login.company.lms_company_id, setLmsRoles)
		}
		getRoles()
	}, [login.company.lms_company_id])

	const trySubmit = async () => {
		if (isValid) {
			// const headers = {
			// 	Authorization: `Token ${login.user.token}`,
			// }
			const payload: UserPayloadInterface = {
				id: addingUser ? null : props.row?.email,
				name: name!,
				email: email!,
				role: role,
				position: position,
				password: addingUser ? password! : null,
				department: department,
				user_login: login.user.email,
				blocked: isBlocked,
				team: team,
				testaccess: isTestAccess,
				location: location,
				finance_control_access: financeControlAccess,
				team_leader: teamLeader,
				company_id: login.company.id,
				permissions: userPermissions,
			}

			// save team leader as the email instead of the name
			const teamLeaderUser = users.users.find((user: any) => {
				return user.name === teamLeader
			})
			if (teamLeaderUser) {
				payload.team_leader = teamLeaderUser.email
			}
			if (lmsAccess && addToLMS) {
				const role = lmsRoles.find((role: any) => {
					return role.title === selectedLmsRole
				})
				payload.lmsUser = {
					role: selectedLmsRole,
					role_id: role?.id,
					startDate: formatDateToLMSDate(startDate),
					endDate: formatDateToLMSDate(endDate),
					type: userType,
				}
			}
			console.log(payload)

			addingUser
				? await insertUser(payload, props.setErrorMessage)
				: await editUser(payload, props.setErrorMessage)
			props.setLoading(false)
			props.handleClose()
		}
	}
	useEffect(() => {
		if (!addingUser) {
			fillFields(props.row)
		}
	}, [props.PopUpVisible])

	const isFormValid = () => {
		if (
			!name ||
			!email ||
			!role ||
			!location ||
			!department ||
			!position ||
			(isAgencyCompanyType && !team)
		)
			return false
		if (addToLMS) {
			return !!userType && !!selectedLmsRole
		}
		return true
	}

	useEffect(() => {
		setIsValid(isFormValid())
	}, [
		name,
		email,
		role,
		location,
		department,
		position,
		team,
		addToLMS,
		userType,
		selectedLmsRole,
	])

	const formatDateToLMSDate = (dateStr: string) => {
		if (dateStr) {
			const [year, month, day] = dateStr.split('-')

			const formattedDate = `${day}-${month}-${year}`
			return formattedDate
		} else return ''
	}

	return (
		<UserPopUpForm
			open={props.PopUpVisible}
			style={{ zIndex: '20' }}
			sx={{
				width: 900,
				maxWidth: 900,
				mx: 'auto',
			}}
		>
			<DialogTitle
				style={{
					display: 'flex',
					justifyContent: 'row',
					placeContent: 'space-between',
					backgroundColor: theme.colors.base.white,
					// height: 10,
				}}
			>
				<span style={{ padding: '10px 40px', color: theme.colors.text.titles }}>
					{addingUser ? 'Add user' : 'Edit user'}
				</span>
				{isBlocked && (
					<span
						style={{
							fontSize: '16px',
							color: theme.colors.base.red300,
							marginTop: '4px',
						}}
					>
						User is blocked
					</span>
				)}
			</DialogTitle>
			<DialogContent
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '12px',
					padding: '0px 60px 60px 60px',
					backgroundColor: theme.colors.base.white,
				}}
			>
				<InputFieldText
					label='Name'
					type='text'
					value={name}
					onChange={setName}
				/>
				<InputFieldText
					label='Email Address'
					type='email'
					value={email}
					onChange={setEmail}
					disabled={!addingUser ? true : false}
				/>
				{/* <DropList
					label='Type'
					options={
						settings.settings.userTypes.map(
							(type: string) => type.charAt(0).toUpperCase() + type.slice(1),
						) || []
					}
					value={
						userType ? userType.charAt(0).toUpperCase() + userType.slice(1) : ''
					}
					onChange={(e: any) => setUserType(e.toLowerCase())}
				/> */}
				<DropList
					label={'Role'}
					options={
						roles
							? roles.roles.map(
									(role) =>
										role.name.charAt(0).toUpperCase() + role.name.slice(1),
							  )
							: []
					}
					value={role ? role : ''}
					onChange={(e: any) => setRole(e)}
				/>
				<DropList
					label={'Division'}
					options={[
						'Administration',
						'BizDev',
						'Creative',
						'Management',
						'Marketing',
						'Technology',
					]}
					value={department}
					onChange={(e: any) => setDepartment(e)}
				/>
				{isAgencyCompanyType && (
					<DropList
						label={'Department'}
						options={settings.settings.teams || []}
						value={team}
						onChange={(e: any) => setTeam(e)}
					/>
				)}
				{isAgencyCompanyType && (
					<DropList
						label={'Team Leaders'}
						options={settings.settings.teamLeaders || []}
						value={teamLeader}
						onChange={(e: any) => setTeamLeader(e)}
					/>
				)}
				<DropList
					label='Position'
					options={settings.settings.userPositions || []}
					value={position}
					onChange={(e: any) => setPosition(e)}
				/>
				<DropList
					label={'Location'}
					// options={
					// 	settings.settings.branches.map((l: string) => l + ' Branch') || []
					// }
					options={country_codes}
					value={location}
					onChange={(e: any) => setLocation(e)}
				/>
				{lmsAccess && permissions.addLmsUser && (
					<StyledCheckboxFormControl
						key={'LmsAccess'}
						control={
							<Checkbox
								onChange={() => {
									setAddToLMS(!addToLMS)
								}}
								checked={addToLMS}
								key={'add_to_lms'}
								style={{ marginLeft: '25px', color: theme.colors.base.primary }}
								disabled={!!props.row?.lms_status}
							/>
						}
						label={
							<Typography
								fontSize='13px'
								fontWeight={500}
								color={theme.colors.base.primary}
							>
								{'Add to The SkillShop'}
							</Typography>
						}
					/>
				)}
				{addToLMS && (
					<DropList
						label={'Type'}
						options={['Learner', 'Supervisor', 'Admin']}
						value={userType}
						onChange={(e: any) => setUserType(e)}
						disabled={!!props.row?.lms_status}
					/>
				)}
				{addToLMS && (
					<DropList
						label={'LMS Role'}
						options={lmsRoles.map((role: any) => {
							return role.title
						})}
						value={selectedLmsRole}
						onChange={(e: any) => setSelectedLmsRole(e)}
						disabled={!!props.row?.lms_status}
					/>
				)}
				{addToLMS && (
					<InputFieldText
						label='Start Date'
						type='date'
						value={startDate}
						onChange={setStartDate}
						placeholder='dd-mm-yyyy'
						disabled={!!props.row?.lms_status}
						// disabled={!addingUser ? true : false}
					/>
				)}
				{addToLMS && (
					<InputFieldText
						label='End Date'
						type='text'
						value={endDate}
						onChange={setEndDate}
						placeholder='dd-mm-yyyy'
						disabled={!!props.row?.lms_status}
						// disabled={!addingUser ? true : false}
					/>
				)}
			</DialogContent>
			<DialogActions
				style={{
					paddingBottom: '34px',
					height: 120,
					backgroundColor: theme.colors.base.white,
				}}
			>
				<UnborderedButton
					style={{ margin: 'auto' }}
					onClick={() => {
						nullifyFields()
						props.handleClose()
					}}
					label={'Cancel'}
				></UnborderedButton>
				{permissions.edit && (
					<SubmitButton
						onClick={() => trySubmit()}
						style={{ margin: 'auto' }}
						disabled={!isValid}
					>
						{addingUser ? 'Add' : 'Update'}
					</SubmitButton>
				)}
				{!addingUser && permissions.blockUser && (
					<SubmitButton
						onClick={() => setIsBlocked(!isBlocked)}
						style={{ margin: 'auto' }}
						disabled={!isValid}
						noBackground
					>
						{isBlocked ? 'Unblock' : 'Block'}
					</SubmitButton>
				)}
			</DialogActions>
		</UserPopUpForm>
	)
}
export default UserPopUp

export const StyledCheckboxFormControl = styled(FormControlLabel)(
	({ theme }) => ({
		color: theme.colors.gradient,
		fontSize: '13px',
		maxWidth: '280px',
		fontWeight: 600,
		'&.MuiTypography-root': {
			fontSize: '13px',
		},
	}),
)
