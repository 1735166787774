import React, { useState, useEffect, useMemo } from 'react'
import { useTheme, Theme } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import NotificationItem from './NotificationItem'
import { StatusButton, UnborderedButton } from '../components/Buttons'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { Notification } from '../../models/model.interface'
import { useNotificationActions } from '../../hooks/useActions'

enum NotificationFilterStatus {
	Seen = 0,
	All = 1,
	New = 2,
}

const NotificationsPage: React.FC = () => {
	const theme: Theme = useTheme()
	const { notifications } = useTypedSelector((state) => state)
	const { deleteNotifications, markAsRead, markAsUnread } =
		useNotificationActions()

	// Local state for notifications and filter
	const [currNotifications, setCurrNotifications] = useState<Notification[]>(
		notifications.notifications,
	)
	const [filterStatus, setFilterStatus] = useState<NotificationFilterStatus>(
		NotificationFilterStatus.All,
	) // Default to 'All'
	const [selectedNotifications, setSelectedNotifications] = useState<string[]>(
		[],
	)

	const filteredNotifications = currNotifications
		.filter((notification) => {
			if (filterStatus === NotificationFilterStatus.Seen)
				return notification.is_read
			if (filterStatus === NotificationFilterStatus.All) return true
			if (filterStatus === NotificationFilterStatus.New)
				return !notification.is_read
			return true
		})
		.sort(
			(a, b) =>
				new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
		)
	const totalUnreadNotifications = filteredNotifications.filter(
		(notification) => !notification.is_read,
	).length

	const handleDeleteNotification = (id: string) => {
		setCurrNotifications((prev) => prev.filter((notif) => notif.id !== id))
		deleteNotifications([id])
	}

	const handleBulkMarkAsRead = () => {
		const idsToMarkAsRead = selectedNotifications.filter(
			(id) => !currNotifications.find((notif) => notif.id === id)?.is_read,
		)
		if (idsToMarkAsRead.length === 0) return
		setCurrNotifications((prev) =>
			prev.map((notif) =>
				idsToMarkAsRead.includes(notif.id)
					? { ...notif, is_read: true }
					: notif,
			),
		)
		markAsRead(idsToMarkAsRead)
		setSelectedNotifications([])
	}

	const handleBulkMarkAsUnread = () => {
		const idsToMarkAsUnread = selectedNotifications.filter(
			(id) => currNotifications.find((notif) => notif.id === id)?.is_read,
		)
		if (idsToMarkAsUnread.length === 0) return
		setCurrNotifications((prev) =>
			prev.map((notif) =>
				idsToMarkAsUnread.includes(notif.id)
					? { ...notif, is_read: false }
					: notif,
			),
		)
		markAsUnread(idsToMarkAsUnread)
		setSelectedNotifications([])
	}

	const handleBulkDelete = () => {
		setCurrNotifications((prev) =>
			prev.filter((notif) => !selectedNotifications.includes(notif.id)),
		)
		deleteNotifications(selectedNotifications)
		setSelectedNotifications([])
	}

	const toggleNotificationRead = async (id: string) => {
		try {
			// Update the local state
			const updatedNotifications = currNotifications.map((notif) => {
				if (notif.id === id) {
					return { ...notif, is_read: !notif.is_read }
				}
				return notif
			})

			setCurrNotifications(updatedNotifications)

			const toggledNotification = updatedNotifications.find(
				(notif) => notif.id === id,
			)

			if (!toggledNotification) {
				throw new Error('Notification not found after updating the state.')
			}

			if (toggledNotification.is_read) {
				markAsRead([id])
			} else {
				markAsUnread([id])
			}
		} catch (error) {
			console.error('Error toggling notification read state:', error)
			setCurrNotifications(currNotifications)
		}
	}

	const activeFilterHandler = (status: number) => {
		setFilterStatus(status)
	}
	const toggleNotificationSelection = (id: string) => {
		setSelectedNotifications((prev) =>
			prev.includes(id)
				? prev.filter((notifId) => notifId !== id)
				: [...prev, id],
		)
	}
	const selectAllNotifications = () => {
		const allIds = filteredNotifications.map((notif) => notif.id)
		setSelectedNotifications((prev) =>
			prev.length === allIds.length ? [] : allIds,
		)
	}

	const possibleFilters = useMemo(() => ['Seen', 'All', 'New'], [])

	return (
		<Box
			sx={{
				padding: '32px',
				backgroundColor: theme.colors.base.white,
			}}
		>
			{/* Toolbar */}
			<Box
				sx={{
					marginBottom: '35px',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-start',
					gap: '8px',
					marginLeft: '15%',
					marginTop: '20px',
				}}
			>
				{/* Header */}
				<Typography
					variant='h6'
					sx={{
						fontWeight: theme.font.weight.bolder,
						color: theme.colors.text.general,
					}}
				>
					Notifications
				</Typography>

				{/* Status/Filter Button */}
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						gap: '16px',
					}}
				>
					<StatusButton
						activeFilterHandler={activeFilterHandler}
						possibleCampaignFilter={possibleFilters}
						filterStatus={filterStatus}
						setFilterStatus={setFilterStatus}
					/>
					{totalUnreadNotifications > 0 && (
						<Box
							sx={{
								backgroundColor: theme.colors.text.inactive,
								color: theme.colors.text.general,
								borderRadius: '50%',
								padding: '2px 6px',
								fontSize: theme.font.size.caption,
								fontWeight: theme.font.weight.bold,
								textAlign: 'center',
								marginLeft: '-13px',
							}}
						>
							{totalUnreadNotifications}
						</Box>
					)}
				</Box>

				{/* Bulk Actions */}
				<Box
					sx={{
						display: 'flex',
						gap: '8px',
						marginTop: '16px',
					}}
				>
					{filteredNotifications.length > 0 && (
						<UnborderedButton
							onClick={selectAllNotifications}
							style={{
								padding: '8px 16px',
								borderRadius: '4px',
								color: theme.colors.text.general,
								fontWeight: theme.font.weight.bold,
								border: 'none',
								cursor: 'pointer',
							}}
						>
							{selectedNotifications.length === filteredNotifications.length
								? 'Deselect All'
								: 'Select All'}
						</UnborderedButton>
					)}
					{selectedNotifications.length > 0 && (
						<UnborderedButton
							onClick={handleBulkMarkAsRead}
							disabled={selectedNotifications.length === 0}
							style={{
								padding: '8px 16px',
								borderRadius: '4px',
								color: theme.colors.text.general,
								fontWeight: theme.font.weight.bold,
								border: 'none',
								cursor: 'pointer',
							}}
						>
							Mark as Read
						</UnborderedButton>
					)}
					{selectedNotifications.length > 0 && (
						<UnborderedButton
							onClick={handleBulkMarkAsUnread}
							disabled={selectedNotifications.length === 0}
							style={{
								padding: '8px 16px',
								borderRadius: '4px',
								color: theme.colors.text.general,
								fontWeight: theme.font.weight.bold,
								border: 'none',
								cursor: 'pointer',
							}}
						>
							Mark as Unread
						</UnborderedButton>
					)}
					{selectedNotifications.length > 0 && (
						<UnborderedButton
							onClick={handleBulkDelete}
							disabled={selectedNotifications.length === 0}
							style={{
								padding: '8px 16px',
								borderRadius: '4px',
								color: theme.colors.text.general,
								fontWeight: theme.font.weight.bold,
								border: 'none',
								cursor: 'pointer',
							}}
						>
							Delete Selected
						</UnborderedButton>
					)}
				</Box>
			</Box>

			{/* Notifications List */}
			<Box
				sx={{
					maxWidth: '70%',
					margin: '0 auto',
				}}
			>
				{filteredNotifications.length > 0 ? (
					filteredNotifications.map((notification) => (
						<NotificationItem
							key={notification.id}
							id={notification.id}
							title={notification.title}
							message={notification.message}
							isRead={notification.is_read}
							createdAt={notification.created_at}
							onDelete={handleDeleteNotification}
							onRead={toggleNotificationRead}
							action_url={notification.action_url}
							isSelected={selectedNotifications.includes(notification.id)}
							onSelect={toggleNotificationSelection}
						/>
					))
				) : (
					<Typography
						variant='body1'
						sx={{
							color: theme.colors.text.inactive,
							textAlign: 'center',
							marginTop: '20px',
						}}
					>
						No notifications to display.
					</Typography>
				)}
			</Box>
		</Box>
	)
}

export default NotificationsPage
