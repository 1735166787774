import { Dispatch } from 'redux'
import { ActionType } from '../action-types'
import { Action } from '../actions/index'
import AxiosCustom from '../../utils/Axios'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { endpoints } from '../../config'

export const getAdvertiserAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.ADVERTISER, {
				headers: {
					'x-permission-name': permissionNames.SEE_ALL_ADVERTISERS,
				},
			})

			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.GET_ADVERTISER,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			// if (
			// 	window.location.pathname !== '/notlogged' &&
			// 	window.location.pathname !== '/'
			// ) {
			// 	window.location.replace(window.location.origin + '/notlogged')
			// }
		}
	}
}

export const getAdvertiserByCompanyId = (companyId: number) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(
				endpoints.ADVERTISER + '/company/' + companyId,
				{
					headers: {
						'x-permission-name': permissionNames.SEE_ALL_ADVERTISERS,
					},
				},
			)
			// console.log(data)
			return data
			// dispatch({
			//     type: ActionType.GET_ADVERTISER_BY_COMPANY_ID,
			//     payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}

export const getAdvertiserReport = (
	setDownloadFile: any,
	rows: any,
	// headers: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const report = { report: rows }
			const { data } = await AxiosCustom.post(
				// TODO: MATAN -> what is the permissions name for this?
				endpoints.ADVERTISER + '/report',
				report,
			)
			if (!data.successful) {
				return
			}
			// dispatch({
			// 	type: ActionType.GET_ADVERTISER_REPORT,
			// 	payload: data.payload,
			// })
			setDownloadFile(data.payload)
		} catch (err) {
			console.error(err)
		}
	}
}

export const getAdvertiserContacts = (setDownloadFile: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.ADVERTISER + '/contacts') // TODO: MATAN -> what is the permissions name for this?
			if (!data.successful) {
				return
			}
			// dispatch({
			// 	type: ActionType.GET_ADVERTISER_CONTACTS,
			// 	payload: data.payload,
			// })
			setDownloadFile(data.payload)
		} catch (err) {
			console.error(err)
		}
	}
}

export const insertAdvertiserAction = (
	obj: any,
	setErrorMessage: any,
	navigateToNewAdvertiser: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const advertiser = { advertiser: obj }
			const { data } = await AxiosCustom.post(
				endpoints.ADVERTISER,
				advertiser,
				{
					headers: {
						'x-permission-name': permissionNames.ADD_EDIT_ADVERTISER,
					},
				},
			)

			if (!data.successful) {
				setErrorMessage(data.payload)
				return { success: false, error: data.payload }
			}

			const newPayload = { ...obj, id: data.payload.advertiserId }

			// Dispatch action if the API call was successful
			dispatch({
				type: ActionType.INSERT_ADVERTISER,
				payload: newPayload,
			})
			dispatch({
				type: ActionType.SET_SUCCESS,
				payload: {
					title: 'Advertiser Insert',
					message: 'Advertiser added successfully',
				},
			})

			navigateToNewAdvertiser(data.payload.advertiserId)
		} catch (err) {
			setErrorMessage(err)
			return { success: false, error: err }
		}
	}
}

export const updateAdvertiserAction = (
	// headers: any,
	obj: any,
	ruleSet: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const advertiser = { advertiser: obj, rule_set: ruleSet }
			const { data } = await AxiosCustom.put(endpoints.ADVERTISER, advertiser, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_ADVERTISER,
				},
			})

			if (!data.successful) {
				setErrorMessage(data.payload.message)

				return data.payload.message
			}
			// const newObj = {
			// 	advertiser_name: obj.advertiser_name,
			// 	email: obj.ownerEmail,
			// 	status: obj.status,
			// 	platforms: obj.platform,
			// 	app: obj.campaigns,
			// }

			dispatch({
				type: ActionType.INSERT_ADVERTISER,
				payload: obj,
			})
			dispatch({
				type: ActionType.SET_SUCCESS,
				payload: {
					title: 'Advertiser Update',
					message: 'Advertiser updated successfully',
				},
			})
		} catch (err) {
			setErrorMessage(err)
		}
	}
}

export const updateAdvertiserMetaAction = (
	// headers: any,
	obj: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const advertiser = { advertiser: obj }
			const { data } = await AxiosCustom.put(
				endpoints.ADVERTISER_META,
				advertiser,
				{
					headers: {
						'x-permission-name': permissionNames.ADD_EDIT_ADVERTISER,
					},
				},
			)

			if (!data.successful) {
				setErrorMessage(data.payload.message)

				return data.payload.message
			}
			// const newObj = {
			// 	advertiser_name: obj.advertiser_name,
			// 	email: obj.ownerEmail,
			// 	status: obj.status,
			// 	platforms: obj.platform,
			// 	app: obj.campaigns,
			// }

			dispatch({
				type: ActionType.INSERT_ADVERTISER_META,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
		}
	}
}

export const getPasswordsByAdvertiserId = (
	id: number,
	setCurrentPasswords: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(
				endpoints.ADVERTISER + `/password/${id}`,
				{
					headers: {
						'x-permission-name': permissionNames.VIEW_ADVERTISER_PASSWORDS,
					},
				},
			)
			setCurrentPasswords(data)
			return data
		} catch (err) {
			console.error('err', err)
			setCurrentPasswords([])
			return []
		}
	}
}

export const deleteAdvertiser = (
	// headers: any,
	obj: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.delete(endpoints.ADVERTISER, {
				data: {
					advertiser: obj,
					headers: {
						'x-permission-name': permissionNames.DELETE_ADVERTISER,
					},
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			dispatch({
				type: ActionType.DELETE_ADVERTISER,
				payload: obj,
			})
			dispatch({
				type: ActionType.SET_SUCCESS,
				payload: {
					title: 'Advertiser Delete',
					message: 'Advertiser removed successfully',
				},
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
