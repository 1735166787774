// SupportStyled.tsx
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

// Outer container to center the content and provide spacing
export const OuterContainer = styled(Box)(({ theme }) => ({
	// minHeight: '100vh',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: theme.colors.base.grey50,
	// padding: '1.5rem 2rem',
}))

export const CenteredContainer = styled(Box)(({ theme }) => ({
	position: 'relative',
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '2rem',
	background: theme.colors.gradientLoginPage, // The gradient background

	'&::after': {
		content: '""',
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		zIndex: 1,

		background: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' preserveAspectRatio='none' viewBox='0 0 1440 320'><path fill='white' d='M0,0 C400,0 1040,320 1440,320 L1440,320 L0,320 Z' /></svg>") no-repeat center/cover`,
	},
}))
// Header section styled as a floating card
export const HeaderWrapper = styled(Box)(({ theme }) => ({
	position: 'relative',
	zIndex: 2,
	backgroundColor: theme.colors.base.cardBackground,
	backdropFilter: 'blur(5px)',
	borderRadius: '10px',
	boxShadow: theme.colors.base.cardShadow,
	padding: '0.5rem',
	textAlign: 'center',
	marginBottom: '1rem',
	width: '70%',
	// maxWidth: '100%',
}))

// Form section styled as a floating card
export const FormWrapper = styled(Box)(({ theme }) => ({
	position: 'relative',
	zIndex: 2,
	backgroundColor: theme.colors.base.cardBackgroundStrong,
	borderRadius: '10px',
	boxShadow: theme.colors.base.cardShadow,
	padding: '2rem',
	textAlign: 'center',
	maxWidth: '70%',
}))

export const SuccessContainer = styled(Box)(({ theme }) => ({
	minHeight: '80vh',
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	gap: '1.5rem',
	position: 'relative',
	padding: theme.spacing(4),
	backgroundColor: theme.colors.base.cardBackgroundStrong,
	borderRadius: '8px',
	boxShadow: theme.colors.base.cardShadow,
	textAlign: 'center',
}))

// Animated Ellipsis for Loading
export const AnimatedEllipsis = styled('span')(({ theme }) => ({
	display: 'inline-block',
	position: 'relative',
	width: '1.5rem',
	height: '1.5rem',
	marginLeft: theme.spacing(1),
	top: '0.4rem',

	'& span': {
		display: 'block',
		position: 'absolute',
		width: '0.5rem',
		height: '0.5rem',
		backgroundColor: theme.colors.base.green300,
		borderRadius: '50%',
		animation: 'ellipsis 1.4s infinite',
	},

	'& span:nth-of-type(1)': {
		left: '0rem',
		animationDelay: '0s',
	},
	'& span:nth-of-type(2)': {
		left: '0.5rem',
		animationDelay: '0.2s',
	},
	'& span:nth-of-type(3)': {
		left: '1rem',
		animationDelay: '0.4s',
	},

	'@keyframes ellipsis': {
		'0%, 80%, 100%': {
			transform: 'scale(0)',
		},
		'40%': {
			transform: 'scale(1)',
		},
	},
}))
