import { ThemeProvider } from '@emotion/react'
import '@inovua/reactdatagrid-community/index.css'
import ReportIcon from '@mui/icons-material/Report'
import { Chip, Tooltip, Typography, useTheme } from '@mui/material'
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro'
import { useEffect, useState } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import {
	capitalizeFirstLetter,
	findUsernameByEmail,
	generateRowsWithIds,
	sortFieldNames,
} from '../../../utils/helpers/tableHelper'

export const ReportsTable = (props: {
	report: any
	setIsReportOpen: any
	reportFields: any
	isReportOpen: boolean
	customReportFields: any
}) => {
	const theme = useTheme()
	let fieldNames: any[] = []
	const { users } = useTypedSelector((state) => state)
	if (props.report !== null) {
		fieldNames = props.report.length === 0 ? [] : Object.keys(props.report[0])
		if (fieldNames.length > 0) {
			props.reportFields.statistics.forEach((v: string) => {
				if (
					!fieldNames.includes(v) &&
					!(
						(props.reportFields.filters.campaign_id &&
							props.reportFields.filters.campaign_id.length === 1) ||
						(props.reportFields.filters.campaign_field &&
							props.reportFields.filters.campaign_field.length === 1)
					)
				) {
					fieldNames.push(v)
				}
			})
		}
	}
	const sortedfieldNames: string[] = sortFieldNames(fieldNames)
	const [pinnedRow, setPinnedRow] = useState({})

	const clearFieldNamesForTable = (allFieldNamesFromDB: string[]) => {
		let result: any = []
		return allFieldNamesFromDB
	}

	const rowCount = sortedfieldNames.length

	const [rowWidth, setRowWidth] = useState(
		Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
	)
	const columns: GridColDef[] = sortedfieldNames.map(
		(e: any, index: number): any => {
			let field = e
				.split('_')
				.map((a: string) => capitalizeFirstLetter(a))
				.join(' ')
			console.log(field)
			if (field.length < 4) {
				field = field.toUpperCase()
			} else {
				field = capitalizeFirstLetter(field)
			}
			if (
				field.toLowerCase() === 'revenue' ||
				field.toLowerCase() === 'cost' ||
				field.toLowerCase() === 'profit'
			) {
				return {
					field: e,
					headerName: field,
					width: rowWidth * 0.75,
					type: 'number',
					align: 'left',
					headerAlign: 'left',
					valueFormatter: ({ value }: { value: any }) =>
						new Intl.NumberFormat('en-US', {
							style: 'currency',
							currency: 'USD',
						}).format(value),
					renderCell: ({ value }: { value: any }) =>
						value === undefined ? (
							<>{<ReportIcon style={{ fill: 'red' }} />}</>
						) : (
							Number(value).toLocaleString() + ' $'
						),
				}
			} else if (field.toLowerCase() === 'advertiser owner') {
				return {
					field: e,
					headerName: field,
					width: rowWidth * 0.75,
					renderCell: ({ value }: { value: any }) =>
						value === undefined ? (
							<>{<ReportIcon style={{ fill: 'red' }} />}</>
						) : (
							findUsernameByEmail(value, users.users)
						),
				}
			} else if (field.toLowerCase() === 'cr') {
				return {
					field: e,
					headerName: field,
					width: rowWidth * 0.75,
					renderCell: ({ value }: { value: any }) =>
						parseFloat(value?.toString() || '0').toFixed(2) + ' %',
				}
			} else if (field.toLowerCase() === 'country') {
				return {
					field: e,
					headerName: field,
					width: rowWidth * 0.75,
					renderCell: ({ value }: { value: any }) =>
						value === undefined ? (
							<>{<ReportIcon style={{ fill: 'red' }} />}</>
						) : (
							<Chip
								icon={
									<ReactCountryFlag
										style={{
											width: '1em',
											height: '1em',
											borderRadius: '30px',
										}}
										countryCode={value === 'uk' ? 'GB' : value}
										svg
									/>
								}
								style={{ borderWidth: '0px' }}
								variant='outlined'
								label={value.toUpperCase()}
							/>
						),
				}
			} else if (
				field.toLowerCase() === 'impressions' ||
				field.toLowerCase() === 'installs' ||
				field.toLowerCase() === 'clicks' ||
				field.toLowerCase().includes('event') ||
				field.toLowerCase().includes('re-')
			) {
				return {
					field: e,
					headerName: field,
					width: rowWidth * 0.75,
					align: 'left',
					headerAlign: 'left',
					type: 'number',
					valueFormatter: ({ value }: { value: any }) =>
						new Intl.NumberFormat().format(value),
				}
			}
			return {
				field: e,
				headerName: field,
				width:
					field.toLowerCase() === 'campaign name' ? rowWidth * 1.8 : rowWidth,
				renderCell: ({ value }: { value: any }) =>
					value === undefined ? (
						<>{<ReportIcon style={{ fill: 'red' }} />}</>
					) : !isNaN(value) ? (
						<Tooltip title={value}>
							<Typography fontSize={12}>
								{Number(value).toLocaleString()}
							</Typography>
						</Tooltip>
					) : (
						<Tooltip title={value}>
							<Typography fontSize={12}>{value}</Typography>
						</Tooltip>
					),
			}
		},
	)

	const pageSize = 20

	useEffect(() => {
		setRowWidth(
			Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
		)
	}, [rowCount])

	useEffect(() => {
		if (props.report) {
			const sumRow = props.report.slice(props.report.length - 1)
			sumRow[0].id = props.report.length - 1
			const pinnedSumRow = { bottom: sumRow }
			setPinnedRow(pinnedSumRow)
		}
	}, [props.report])

	return (
		<ThemeProvider theme={theme}>
			<DataGridPro
				style={{
					fontSize: '0.75rem',
				}}
				columns={columns}
				rowHeight={23}
				rows={generateRowsWithIds(props.report)}
				pageSizeOptions={[15, 25, 50]}
				showColumnVerticalBorder={true}
				pinnedRows={pinnedRow}
			></DataGridPro>
		</ThemeProvider>
	)
}
