export enum ActionType {
	LOGIN = 'login',
	SIGNUP = 'signup',
	RESET_USER_PASSWORD = 'reset_user_password',
	GET_USERS = 'get_users',
	LOGOUT = 'logout',

	GET_NEWS = 'get_news',
	POST_NEWS = 'update_news',
	POST_IMAGE = 'update_image',

	INSERT_LOG = 'update_log',
	GET_LOGS = 'get_logs',

	GET_SETTINGS = 'get_settings',
	UPDATE_SETTINGS = 'update_settings',

	INSERT_USER = 'update_user',
	RESET_PASSWORD = 'reset_password',
	DELETE_USER = 'delete_user',
	EDIT_USER = 'update_user',

	GET_ADVERTISER = 'get_advertiser',
	INSERT_ADVERTISER = 'update_advertiser',
	INSERT_ADVERTISER_META = 'update_advertiser_meta',
	DELETE_ADVERTISER = 'delete_advertiser',
	GET_ADVERTISER_REPORT = 'get_advertiser_report',
	GET_ADVERTISER_CONTACTS = 'get_advertiser_contacts',
	UPDATE_ADVERTISER_ICON = 'update_advertiser_icon',
	DELETE_ADVERTISER_ICON = 'delete_advertiser_icon',

	GET_PUBLISER = 'get_publisher',
	INSERT_PUBLIHSER = 'update_publisher',
	INSERT_PUBLIHSER_MASTER = 'update_publisher_master',
	INSERT_PUBLIHSER_META = 'update_publisher_meta',
	DELETE_PUBLISHER = 'delete_publisher',
	GET_PUBLISHER_REPORT = 'get_publisher_report',
	GET_PUBLISHER_CONTACTS = 'get_publisher_contacts',
	UPDATE_PUBLISHER_ICON = 'update_publisher_icon',
	DELETE_PUBLISHER_ICON = 'delete_publisher_icon',

	GET_CAMPAIGN = 'get_campaign',
	INSERT_CAMPAIGN = 'update_campaign',
	DELETE_CAMPAIGN = 'delete_campaign',

	GET_APPSFLYER = 'get_appsflyer',
	UPDATE_APPSFLYER = 'update_appsflyer',
	SAVE_APPSFLYER = 'upload_report',
	SEND_APPSFLYER = 'send_appsflyer',

	GET_APP = 'get_app',
	GET_ICONS = 'get_icons',
	ADD_APP = 'update_app',
	INSERT_P360_RULE = 'update_p360_rule',
	REMOVE_P360_RULE = 'delete_p360_rule',
	UPDATE_APP_P360 = 'update_app_p360',
	REMOVE_APP_P360 = 'delete_app_p360',
	UPDATE_APP_INFO = 'update_app_info',
	UPDATE_APP = 'update_app',

	NAVIGATION = 'navigation',

	PRESEARCH = 'presearch',

	REEVALUATE = 'reevaluate',
	PRESET = 'preset',

	POST_NETWORK = 'update_network',
	GET_NETWORK = 'get_network',
	UPDATE_NETWORK = 'update_network',

	GET_RAWDATA = 'get_rawdata',
	POST_RAWDATA = 'update_rawdata',

	UPDATE = 'update',

	GETPASSWORDS = 'get_passwords',
	//appsflyer accounts:
	GETACCOUNTS = 'get_appsflyer_accounts',
	//publisher wishlist:
	GETWISHLIST = 'get_publisher_wishlist',
	ADDWISHLIST = 'add_publisher_wishlist',
	UPDATEWISHLIST = 'edit_publisher_wishlist',
	DELETEWISHLIST = 'delete_publisher_wishlist',
	//pid handshake:
	GETHANDSHAKE = 'get_pid_handshake',
	//learning:
	GET_DOCUMENTS = 'get_documents',
	ADD_DOCUMENTS = 'update_documents',
	REMOVE_DOCUMENTS = 'delete_documents',
	//finance
	GET_FINANCE = 'get_finance',
	EMPTY_FINANCE = 'empty_finance',
	UPLOAD_FINANCE = 'upload_finance',
	DELETE_FINANCE = 'delete_finance',
	UPLOAD_FINANCE_ROLLOVER = 'upload_finance_rollover',
	UPLOAD_FINANCE_DEMAND = 'upload_finance_demand',
	UPLOAD_FINANCE_PUBLISHER = 'upload_finance_publisher',
	UPLOAD_FINANCE_BALANCEIN = 'upload_finance_balancein',
	UPLOAD_FINANCE_BALANCEOUT = 'upload_finance_balanceout',
	UPLOAD_INSTRUCTIONS_FINANCE = 'upload_instructions_finance',
	UPLOAD_PROOF_FINANCE = 'upload_proof_finance',
	DELETE_PROOF_FINANCE = 'delete_proof_finance',
	SEND_EMAIL_FINANCE = 'send_email_finance',
	SEND_EMAIL_FINANCE_ADVERTISER = 'send_email_finance_advertiser',
	//finance creators
	CREATE_CREATOR_CAMPAIGN = 'create_creator_campaign',

	GETDASHBOARD = 'get_dashboard',
	GET_CAMPAIGN_HISTORY = 'get_campaign_history',

	GETAUTOSWITCH = 'get_autoswitch',
	PUTAUTOSWITCH = 'update_autoswitch',
	DELETEAUTOSWITCH = 'delete_autoswitch',
	AVATAR = 'avatar',

	GETVERSION = 'getversion',
	P360AGGREGATED = 'get_p360aggregated',

	//sales plan
	GET_SALES_PLAN_APPS = 'get_sales_plan_apps',

	//creators
	GET_CREATOR = 'get_creator',
	INSERT_CREATOR = 'insert_creator',
	UPDATE_CREATOR_ICON = 'update_creator_icon',
	DELETE_CREATOR_ICON = 'delete_creator_icon',
	INSERT_CREATORS = 'insert_creators',
	UPDATE_CREATOR = 'update_creator',

	//reports
	GENERATE_REPORT_START = 'generate_report_start',
	GENERATE_REPORT_SUCCESS = 'generate_report_success',
	GENERATE_REPORT_FAILURE = 'generate_report_failure',
	LOAD_REPORT_SUCCESS = 'load_report_success',
	PERSIST_REPORT = 'persist_report',
	LOAD_ALL_REPORTS_START = 'load_all_reports_start',
	LOAD_ALL_REPORTS_SUCCESS = 'load_all_reports_success',
	LOAD_ALL_REPORTS_FAILURE = 'load_all_reports_failure',
	UPDATE_REPORT_NAME_START = 'update_report_name_start',
	UPDATE_REPORT_NAME_SUCCESS = 'update_report_name_success',
	UPDATE_REPORT_NAME_FAILURE = 'update_report_name_failure',
	DELETE_REPORT_START = 'delete_report_start',
	DELETE_REPORT_SUCCESS = 'delete_report_success',
	DELETE_REPORT_FAILURE = 'delete_report_failure',
	SET_TAB_INDEX = 'set_tab_index',

	//alerts
	ADD_ALERT = 'add_alert',
	REMOVE_ALERT = 'remove_alert',
	MARK_ALERTS_AS_SEEN = 'mark_alerts_as_seen',
	OPEN = 'open_alert',
	CLOSE = 'close_alert',
	UPDATE_ALERT = 'update_alert',
	MINIMIZE_ALERT = 'minimize_alert',
	RESTORE_ALL_ALERTS = 'restore_all_alerts',

	// permissions
	GETPERMISSIONS = 'getpermissions',
	SETPERMISSIONS = 'setpermissions',

	//Support
	CREATE_SUPPORT_TICKET_REQUEST = 'create_support_ticket_request',
	CREATE_SUPPORT_TICKET_SUCCESS = 'create_support_ticket_success',
	CREATE_SUPPORT_TICKET_ERROR = 'create_support_ticket_error',
	CLEAR_SUPPORT_ERROR = 'clear_support_error',

	// roles
	FETCH_ROLES = 'fetch_roles',
	CREATE_ROLE = 'create_role',
	UPDATE_ROLE = 'update_role',
	DELETE_ROLE = 'delete_role',
	FETCH_ROLES_ERROR = 'fetch_roles_error',

	// company settings
	GET_COMPANY_SETTINGS = 'get_company_settings',
	UPDATE_COMPANY_SETTINGS = 'update_company_settings',

	//company
	GET_ALL_COMPANIES = 'get_all_companies',
	UPDATE_COMPANY = 'update_company',

	//notifications
	GET_ALL_NOTIFICATIONS = 'get_all_notifications',
	CREATE_NOTIFICATION = 'create_notification',
	UPDATE_NOTIFICATION = 'update_notification',
	DELETE_NOTIFICATION = 'delete_notification',
	GET_USER_NOTIFICATIONS = 'get_user_notifications',

	//errors
	SET_ERROR = 'set_error',
	CLEAR_ERROR = 'clear_error',

	//success
	SET_SUCCESS = 'set_success',
	CLEAR_SUCCESS = 'clear_success',
}
