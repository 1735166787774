// AggregatedReportPage.tsx
import React from 'react'
import ReportPage from '../common/ReportPage'
import { getFiltersAction } from '../../../state/action-creators/reportActions'
import {
	getRefreshAction,
	saveAppsflyerAction,
} from '../../../state/action-creators'

import {
	ReportGrid,
	ReportItem,
	ReportItemHeading,
	StyledBox,
} from './ReportsStyled'
import { useTypedSelector } from '../../../hooks/useTypedSelector'

const AggregatedReportPage = (props: {
	children?: any
	setLoading?: any
	errorMessage: string | null
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	setIsWarningPromptOpened: any
	viewRecord: any
	setViewRecord: any
}) => {
	const { login } = useTypedSelector((state) => state)
	const companyType = login?.company.type || 'agency'

	const initialReportFields = {
		from_date: '',
		to_date: '',
		breakdowns: [],
		statistics: [],
		filters: {
			app_id: [''],
			advertiser_name: [''],
			media_source_pid: [''],
			publisher_name: [''],
			email: [''],
			campaign_name: [''],
			campaign_id: [''],
			country: [''],
			agencyAccounts: [''],
		},
	}

	// Filters to show in the FilterComponentList
	const filtersToShow = [
		'date_range',
		'advertiser_name',
		'publisher_name',
		'email',
		'app_id',
		'media_source_pid',
		'campaign_name',
		'country',
		'agencyAccounts',
	]

	// Breakdown options specific to the Aggregated Reports
	const breakdownOptionsBase = [
		'Date',
		'Month',
		'Day',
		'Day of the Week',
		'Country',
		'App ID',
		'OS',
		'Advertiser Name',
		'Campaign Name',
		'Media Source Pid',
		'Publisher Name',
		'Agency Account',
	]

	// Conditionally include 'Advertiser Owner' based on companyType
	const breakdownOptions =
		companyType === 'agency'
			? [
					...breakdownOptionsBase.slice(0, 7),
					'Advertiser Owner',
					...breakdownOptionsBase.slice(7),
			  ]
			: breakdownOptionsBase

	// Statistic options specific to the Aggregated Reports
	const statisticOptions = [
		'Impressions',
		'Clicks',
		'Installs',
		'Re-attributions',
		'Re-engagements',
		'Non-organic Revenue',
		'Level 2 Event',
		'Level 3 Event',
		'Level 4 Event',
		'Level 5 Event',
		'Revenue',
		'Cost',
		'Profit',
	]

	// The type of the report
	const reportType = 'aggregated' as const

	// Selector function to get the specific reports for the Aggregated Reports
	const reportSelectors = (reports: any, email: string) =>
		reports.reports[email]?.aggregated || []

	return (
		<ReportPage
			reportType={reportType}
			initialReportFields={initialReportFields}
			filtersToShow={filtersToShow}
			breakdownOptions={breakdownOptions}
			statisticOptions={statisticOptions}
			getReportAction={getFiltersAction}
			saveAction={saveAppsflyerAction}
			refreshAction={getRefreshAction}
			reportSelectors={reportSelectors}
			ReportGrid={ReportGrid}
			ReportStyles={{ StyledBox, ReportItem, ReportItemHeading }}
			{...props}
		/>
	)
}

export default AggregatedReportPage
