import { ReactComponent as DeleteIcon } from '../../assets/svg/delete-icon.svg'
import { ReactComponent as DuplicateIcon } from '../../assets/svg/duplicate-icon.svg'
import { Grid, useTheme } from '@mui/material'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { countryList } from '../components/countryList'
import { EventForm } from './CampaignComponents'
import { UnborderedButton } from '../components/Buttons'
import { CountryListSelector } from '../components/SelectableInputs'
import { ChipInputList } from '../components/ChipInput'
import { SwitchComponent } from '../components/ToggleComponents'
import { UniqueCards } from '../components/CustomContainers'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { useIsAuthorized } from '../../hooks/useIsAuthorized'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import {
	useActions,
	useCampaignsActions,
	useLogActions,
} from '../../hooks/useActions'
import { Unique } from '../../models/campaign.interface'

export const CampaignUnit = (props: {
	// uniqueIsEnabled: any
	trackedEventChanged: string[]
	setTrackedEventChanged: any
	eventList: string[]
	role: any
	pointer: any
	uniqueTabState: any
	setUniques: any
	setUniqueTabState: any
	setDeleteUnique: any
	// setInnerGroup: any
	// handleChangeField: any
	mspidList: any
	// setIsEditing: any
	setTrackedEvent: any
	trackedEvent: any
	campaignObject: any
	// setUniques: any
	eventNamesChosen: any
	setEventNamesChosen: any
	externalFunction: any
	listOfHooks: any
	setGeoArray: any
	duplicateUniqueTab: any
	uniques: any
	unique: Unique
	companyId: number
	setErrorMessage?: any
	updateSubCampaignGeo: (role: string, geos: string[]) => void
}) => {
	const theme = useTheme()

	const { getEventsByAppsAction, getAccountsAction } = useActions()
	const { insertLog } = useLogActions()
	const {
		getCampaignAction,
		getCampaignPids,
		getCampaignHistoryByMainDtoAction,
		insertCampaignAction,
		duplicateCampaignAction,
	} = useCampaignsActions()
	const { login } = useTypedSelector((state) => state)
	const [fixPrice, setFixPrice] = useState('')
	const [mediaSourcePid, setMediaSourcePid] = useState(['all'])

	const [uniqueState, setUniqueState] = useState(
		props.campaignObject.uniques[props.role],
	)

	const [isExcludedCountry, setIsExcludedCountry] = useState<boolean>(
		props.campaignObject.uniques[props.role]?.country_excluded || false,
	)

	const [country, setCountry] = useState<any[]>([])
	const [isRevenueShare, setIsRevenueShare] = useState(false)
	const [costFromDate, setCostFromDate] = useState(false)
	const [eventFormState, setEventFormState] = useState<any>(null)

	const [fixPriceSelected, setFixPriceSelected] = useState(false)
	const [trackedEvent, setTrackedEvent] = useState<any>([])

	const countryCodes = countryList.map((item) => item.slice(0, 2).toLowerCase())

	const getDisplayCountries = () => {
		// console.log(
		// 	'isExcludedCountry',
		// 	isExcludedCountry,
		// 	props.campaignObject.uniques[props.role],
		// )
		let displayCountries = []
		const includedCountries =
			props.campaignObject['uniques'][props.role]['country']

		if (
			isExcludedCountry ||
			props.campaignObject.uniques[props.role]?.country_excluded
		) {
			const excludedCountries = countryCodes.filter(
				(country: string) => !includedCountries.includes(country),
			)
			displayCountries = excludedCountries
		} else {
			displayCountries = includedCountries
		}
		// console.log('return value', displayCountries)
		return displayCountries
	}
	const [displayCountries, setDisplayCountries] = useState<string[]>([])

	useEffect(() => {
		const geosForDisplay = getDisplayCountries()
		setDisplayCountries(geosForDisplay)

		const initialGeos =
			props.campaignObject['uniques'][props.role]['country'] || []
		props.updateSubCampaignGeo(props.role, initialGeos)
	}, [])

	const fillUniqueStates = () => {
		let statesObj = {}
		// setMediaSourcePid(props.campaignObject.uniques[props.role].media_source_pid)
		// setCountry(props.campaignObject.uniques[props.role].country)
		// setTrackedEvent(props.campaignObject.uniques[props.role].tracked_event)
		// setIsRevenueShare(props.campaignObject.uniques[props.role].is_revenue_share)
		// setCostFromDate(props.campaignObject.uniques[props.role].cost_from_data)
		// console.log(
		//  'props used',
		//  props.campaignObject,
		//  props.role,
		//  props.campaignObject.uniques[props.role],
		// )
		if (props.campaignObject.uniques[props.role]) {
			if (!props.campaignObject.uniques[props.role].tracked_event) {
				helperMakeFirstEvent(statesObj)
			}
			setUniqueState(
				Object.assign(
					{ ...statesObj },
					props.campaignObject.uniques[props.role],
				),
			)
		}
	}

	const helperMakeFirstEvent = (obj: any) => {
		const new_event = {
			eventName: 'installs',
			defaultRevenue: 0,
			defaultCost: 0,
			order: 1,
		}
		// setTrackedEvent([new_event])
		obj.tracked_event = [new_event]
		return obj
	}

	const removeUniqueTab = () => {
		// Prevent deletion if there's only one sub-campaign
		if (Object.keys(props.campaignObject.uniques).length === 1) {
			if (props.setErrorMessage) {
				props.setErrorMessage('Cannot delete the last remaining sub-campaign.')
			}
			return
		}

		// Create a deep clone to ensure state immutability and reactivity
		const updatedUniques = { ...props.campaignObject.uniques }

		// Delete the specified role
		props.setDeleteUnique((prev: any) =>
			prev.length > 0
				? [...prev, updatedUniques[props.role].uuid]
				: [updatedUniques[props.role].uuid],
		)
		delete updatedUniques[props.role]

		// Reorder roles to ensure correct numbering
		const remainingRoles = Object.keys(updatedUniques).sort((a, b) => {
			const getOrder = (role: string) =>
				role === 'M' ? 0 : parseInt(role.slice(1)) + 1
			return getOrder(a) - getOrder(b)
		})

		const reorderedUniques: { [key: string]: any } = {}
		remainingRoles.forEach((role, index) => {
			const newRole = index === 0 ? 'M' : `Q${index}`
			reorderedUniques[newRole] = updatedUniques[role]
		})

		// Update the parent with the new unique state, ensuring reactivity
		props.setUniques({ ...reorderedUniques })

		props.updateSubCampaignGeo(props.role, [])
	}

	const handleEventAddition = () => {
		const new_event = {
			eventName: '',
			defaultRevenue: 0,
			defaultCost: 0,
			order: props.campaignObject.uniques[props.role].tracked_event.length + 1,
		}
		Object.values(props.campaignObject.uniques[props.role].tracked_event).map(
			(e: any, i, a) => [...a, new_event],
		)
		// for (let role of Object.keys(props.campaignObject.uniques)) {
		props.campaignObject.uniques[props.role].tracked_event = [
			...props.campaignObject.uniques[props.role].tracked_event,
			new_event,
		]
		fillUniqueStates()
		// }
	}

	// useEffect(() => {
	// 	console.log('is excluded: ', isExcludedCountry)
	// 	console.log('countries to display: ', getDisplayCountries())
	// }, [])

	const handleGeoChange = (e: any) => {
		setDisplayCountries(e)
		const normalizedData =
			e.length === 0 ? [''] : e.filter((el: string) => el !== 'GLOBAL')
		let updatedData: any = []
		if (isExcludedCountry) {
			updatedData = countryCodes.filter(
				(country) => !normalizedData.includes(country),
			)
		} else {
			updatedData = normalizedData
		}
		props.campaignObject['uniques'][props.role]['country'] = updatedData

		// Update the parent with the new geos for this sub-campaign
		props.updateSubCampaignGeo(props.role, updatedData)
	}

	const handlePidChange = (e: any) => {
		let normalizedData = ['all']
		if (e.length === 1) {
			normalizedData = e
		} else if (e.length > 1) {
			normalizedData = e.filter((el: string) => el !== 'all')
		}
		props.campaignObject['uniques'][props.role]['media_source_pid'] =
			normalizedData
		fillUniqueStates()
	}

	useEffect(() => {
		// handleGeoChange(props.campaignObject['uniques'][props.role]['country'])
		handlePidChange(
			props.campaignObject['uniques'][props.role]['media_source_pid'],
		)
	}, [])
	useEffect(() => {
		fillUniqueStates()
	}, [props.eventNamesChosen])

	useEffect(() => {
		let GeoArray: any = []
		for (let role of Object.keys(props.campaignObject.uniques)) {
			GeoArray.push(props.campaignObject.uniques[role].country)
		}
		const geoSet = [
			...new Set(GeoArray.flat().map((e: string) => e.toUpperCase())),
		]

		if (geoSet.includes('')) {
			// console.log('GLOBAL')
			props.setGeoArray(['GLOBAL'])
		} else {
			// console.log('NOT GLOBAL')
			props.setGeoArray(geoSet.filter((e) => e !== '' && e !== 'GLOBAL').sort())
		}
		fillUniqueStates()
	}, [country, props.uniques])

	useEffect(() => {
		setEventFormState(
			<EventForm
				value={uniqueState.tracked_event}
				options={props.eventList}
				onChange={fillUniqueStates}
				pointer={props.pointer}
				isRevenueShare={isRevenueShare}
				handleEventAddition={handleEventAddition}
				setTrackedEventChanged={props.setTrackedEventChanged}
				trackedEventChanged={props.trackedEventChanged}
				available={true}
				// innerGroup={props.innerGroup}
				key={'evenet' + props.role}
				// role={props.role}
				loginRole={login.user.role}
				campaignObject={props.campaignObject}
				role={props.role}
				eventNamesChosen={props.eventNamesChosen}
				setEventNamesChosen={props.setEventNamesChosen}
				externalFunction={props.externalFunction}
				listOfHooks={props.listOfHooks}
				setUniques={props.setUniques}
				isCostFromDate={costFromDate}
				companyId={props.companyId}
			/>,
		)
	}, [uniqueState])
	const sameCompanyId = props?.companyId === login?.user?.company_id
	const permissions = {
		edit: useIsAuthorized(permissionNames.ADD_EDIT_CAMPAIGN) && sameCompanyId,
		preview: useIsAuthorized(permissionNames.PREVIEW_CAMPAIGNS),
		editOtherParams:
			useIsAuthorized(permissionNames.EDIT_OTHER_PARAMETERS) && sameCompanyId,
		editMainParams:
			useIsAuthorized(permissionNames.EDIT_MAIN_PARAMETERS) && sameCompanyId,
	}
	const [componentInitialRender, setComponentInitialRender] =
		useState<boolean>(false)

	useEffect(() => {
		if (componentInitialRender) {
			let updatedData = props.campaignObject['uniques'][props.role]['country']
			if (isExcludedCountry) {
				updatedData = countryCodes.filter(
					(country) =>
						!props.campaignObject['uniques'][props.role]['country'].includes(
							country,
						),
				)
			} else {
				updatedData = countryCodes.filter(
					(country) => !updatedData.includes(country),
				)
			}
			props.campaignObject['uniques'][props.role]['country'] = updatedData
		} else {
			setComponentInitialRender(true)
			return
		}
	}, [isExcludedCountry])

	const handleCountryExcluded = () => {
		const newValue = !isExcludedCountry
		props.campaignObject.uniques[props.role].country_excluded = newValue
		setIsExcludedCountry(newValue)
	}

	return (
		<>
			<UniqueCards
				style={{
					// width: '73vw',
					width: '92%',
					// border: '1px solid rgba(0, 0, 0, 0.3)',
					flexWrap: 'nowrap',
					flexDirection: 'column',
					display: 'flex',
				}}
			>
				<hr
					style={{
						width: '102%',
						background: theme.colors.base.grey300,
						height: '1px',
						border: 'none',
						marginLeft: '-3%',
					}}
				></hr>
				{permissions.editMainParams && (
					<div
						style={{
							zIndex: '15',
							width: '100%',
							height: 'auto',
							display: 'flex',
							position: 'relative',
							marginTop: 42,
						}}
					>
						<UnborderedButton
							style={{
								// transform: 'translateX(34vw) translateY(-1vh)',
								position: 'absolute',
								top: 0,
								right: '2%',
								color: theme.colors.text.titles,
							}}
							icon={
								<DeleteIcon
									// style={{
									// 	border: '1px solid ' + theme.colors.text.titles,
									// 	borderRadius: '100px',
									// 	width: '14px',
									// 	height: '14px',
									// }}
									style={{ color: theme.colors.text.titles, scale: '0.8' }}
								/>
							}
							// disabled={props.role === 'M'}
							onClick={() => {
								removeUniqueTab()
							}}
							label={'Delete'}
						></UnborderedButton>
						<UnborderedButton
							onClick={() => {
								props.duplicateUniqueTab(props.role)
							}}
							style={{
								// transform: 'translateX(28vw) translateY(-1vh)',
								top: 0,
								right: '10%',
								position: 'absolute',
								color: theme.colors.text.titles,
							}}
							icon={
								<DuplicateIcon
									// style={{
									// 	border: '1px solid ' + theme.colors.text.titles,
									// 	borderRadius: '100px',
									// 	width: '14px',
									// 	height: '14px',
									// }}
									style={{ color: theme.colors.text.titles, scale: '0.8' }}
								/>
							}
						>
							Duplicate
						</UnborderedButton>
					</div>
				)}
				<Grid
					container
					columns={12}
					direction={'row'}
					columnSpacing={8}
					rowSpacing={4}
				>
					<Grid item xs={12} style={{ marginBottom: 16 }}>
						<span style={{ color: theme.colors.base.grey900 }}>
							Sub-Campaign
						</span>
					</Grid>
					<Grid item xs={5}>
						<ChipInputList
							label='Media Source PID (Unique)'
							options={props.mspidList}
							value={uniqueState.media_source_pid}
							onChange={(e: any) => {
								handlePidChange(e)
							}}
							hint='press ENTER after each PID'
							openWithClick
							disabled={!permissions.editMainParams}
						/>
					</Grid>
					<Grid item xs={5}>
						<CountryListSelector
							label={isExcludedCountry ? 'Excluded Country' : 'Country'}
							options={countryList}
							value={displayCountries}
							onChange={(e: any) => {
								handleGeoChange(e)
							}}
							chipStyle={{
								opacity: isExcludedCountry ? 0.38 : 1,
								color: theme.colors.text.titles,
							}}
							disabled={!permissions.editMainParams}
						/>
					</Grid>
					<Grid item xs={2} style={{ marginTop: 6 }}>
						<SwitchComponent
							onChange={handleCountryExcluded}
							value={isExcludedCountry}
							label={'Excluded Country'}
							disabled={!permissions.editMainParams}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						container
						columns={12}
						direction={'row'}
						columnSpacing={0}
						rowSpacing={2}
					>
						<Grid item xs={2}>
							{permissions.editMainParams && (
								<SwitchComponent
									onChange={(e) => {
										props.campaignObject['uniques'][
											props.role
										].is_revenue_share =
											!props.campaignObject['uniques'][props.role]
												.is_revenue_share
										setIsRevenueShare(!isRevenueShare)
									}}
									value={
										props.campaignObject['uniques'][props.role].is_revenue_share
									}
									label={'Revenue Share'}
									disabled={!permissions.editMainParams}
								/>
							)}
						</Grid>
						<Grid item xs={2}>
							{permissions.editMainParams && (
								<SwitchComponent
									onChange={(e) => {
										props.campaignObject['uniques'][props.role].cost_from_data =
											!props.campaignObject['uniques'][props.role]
												.cost_from_data
										setCostFromDate(!costFromDate)
									}}
									value={
										props.campaignObject['uniques'][props.role].cost_from_data
									}
									label={'Cost From Appsflyer'}
									disabled={!permissions.editMainParams}
								/>
							)}
						</Grid>
					</Grid>
				</Grid>

				<Grid
					item
					xs={4}
					style={{ height: '10%', width: '95%', marginTop: 34 }}
				>
					{eventFormState}
				</Grid>
			</UniqueCards>

			<>
				{
					<div
						style={{
							width: '95%',
							justifyContent: 'center',
						}}
					></div>
				}
			</>
		</>
	)
}
