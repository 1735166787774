import { Dispatch } from 'redux'
import { ActionType } from '../action-types'
import { Action } from '../actions/index'
import AxiosCustom from '../../utils/Axios'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { endpoints } from '../../config'
import { UserInterface } from '../../models/model.interface'
import { capitalizeFirstLetter } from '../../utils/helpers/tableHelper'

export interface loginParams {
	email: string
	password: string
	maintenance: boolean
}

export interface signUpParams {
	appWebCampaign: string
	clientType: string
	companyAddress: string
	contactEmail: string
	geo: string[]
	legalEntity: string
	mmp: string[]
	contactName: string
	os: string
	preferredChannel: string[]
	serviceInterest: string[]
	text: string
	vertical: string[]
	fraudTools: string[]
	selfServiceAccount: boolean
	cityTargetedCampaigns: boolean
	rebrokingCampaigns: boolean
	// password: string
}

export interface newPasswordParams {
	email: string
	newPassword: string
}

export const loginAction = (
	user: loginParams,
	setErrorMessage: any,
	navigate: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data, status, statusText } = await AxiosCustom.post(
				endpoints.LOGIN,
				{
					user,
				},
			)
			if (status !== 200) {
				console.log(status, statusText)
				setErrorMessage(statusText)
				return statusText
			}
			localStorage.setItem('userToken', btoa(data.payload))
			const isProd = !window.location.hostname.includes('localhost')
			const isStage = window.location.hostname.includes('stage.varys.io')
			window.location.href = `http${isProd ? 's' : ''}://${
				data.payload.subdomain
			}.${
				isProd ? (isStage ? 'stage.varys.io' : 'varys.io') : 'localhost:3000'
			}/auth?token=${btoa(JSON.stringify(data.payload))}`
			return true
		} catch (err: any) {
			if (err.response?.status === 503) {
				navigate('/maintenance')
			}
			setErrorMessage(err)
			return err
		}
	}
}

export const signUpAction = (
	user: Partial<signUpParams>,
	setIsFinished: any,
) => {
	return async () => {
		try {
			let { data } = await AxiosCustom.post(
				endpoints.SIGNUP,
				{ user },
				{
					headers: {
						'x-permission-name': permissionNames.ADD_EDIT_USERS,
					},
				},
			)

			if (data.successful) {
				setIsFinished(true)
				return
			}
			return { success: true }
		} catch (error) {
			console.error(error)
		}
	}
}

export const getSignUpData = () => {
	return async () => {
		try {
			let { data } = await AxiosCustom.get(endpoints.SIGNUPDATA)

			return data || []
		} catch (error) {
			console.error(error)
		}
	}
}

export const resetPasswordAction = (email: string, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			let { data } = await AxiosCustom.post(
				endpoints.RESET_PASSWORD,
				{ email },
				{
					headers: {
						'x-permission-name': permissionNames.CHANGE_USER_PASSWORD,
					},
				},
			)

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.successful
			}
		} catch (error) {
			console.error(error)
			return error
		}
	}
}

export const resetPasswordWithToken = (
	email: string,
	password: string,
	token: string,
	setErrorMessage: any,
) => {
	return async () => {
		try {
			let { data } = await AxiosCustom.post(
				endpoints.CHANGE_PASSWORD_WITH_TOKEN,
				{ email, password, token },
				{
					headers: {
						'x-permission-name': permissionNames.CHANGE_USER_PASSWORD,
					},
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.successful
			}
		} catch (err) {
			console.error(err)
		}
	}
}

export const logOutAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			// let { data } = await AxiosCustom.post(endpoints.LOGOUT)
			// localStorage.clear()
			sessionStorage.clear()
			const theme = localStorage.getItem('theme')
			localStorage.clear()
			if (theme) {
				localStorage.setItem('theme', theme)
			}
			// console.log('Clearing sessionStorage and redirecting...')
			const isProd = window.location.hostname.includes('varys.io')
			const isStage = window.location.hostname.includes('stage.varys.io')
			window.location.href = `http${isProd ? 's' : ''}://${
				isProd ? (isStage ? 'stage.varys.io' : 'varys.io') : 'localhost:3000'
			}`
			setTimeout(() => {
				dispatch({
					type: ActionType.LOGOUT,
					payload: undefined,
				})
			}, 500)
		} catch (err) {
			console.error('Error during logout:', err)
		}
	}
}

export const usersAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			let { data } = await AxiosCustom.get(endpoints.USER, {
				headers: {
					'x-permission-name': permissionNames.VIEW_USERS,
				},
			})
			if (!data.successful) {
				return
			}

			data.payload.forEach((user: UserInterface) => {
				user.name = capitalizeFirstLetter(user.name)
			})
			dispatch({
				type: ActionType.GET_USERS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const forgotPasswordAction = (email: string, setCodeHash: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const emailobj = { mail: email }
			let { data } = await AxiosCustom.post(
				endpoints.FORGOTPASSWORD,
				emailobj,
				{
					headers: {
						'x-permission-name': permissionNames.CHANGE_USER_PASSWORD,
					},
				},
			)
			if (!data.successful) {
				return data.payload
			}
			setCodeHash(data.payload)
			// dispatch({
			// 	type: ActionType.GET_USERS,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}

export const showPasswordAction = (
	// headers: any,
	email: string,
	setCodeHash: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const emailobj = { mail: email }
			let { data } = await AxiosCustom.post(endpoints.SHOW_PASSWORD, emailobj, {
				headers: {
					'x-permission-name': permissionNames.VIEW_PASSWORDS,
				},
			})
			if (!data.successful) {
				return
			}
			setCodeHash(data.payload)
			// dispatch({
			// 	type: ActionType.GET_USERS,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}

export const insertUser = (obj: any, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const user = { user: obj }
			const { data } = await AxiosCustom.post(endpoints.REGISTER, user, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_USERS,
				},
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			dispatch({
				type: ActionType.INSERT_USER,
				payload: obj,
			})
			dispatch({
				type: ActionType.SET_SUCCESS,
				payload: { title: 'User Insert', message: 'User added successfully' },
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const editUser = (obj: any, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const user = { user: obj }
			const { data } = await AxiosCustom.put(endpoints.USER, user, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_USERS,
				},
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			dispatch({
				type: ActionType.SET_SUCCESS,
				payload: { title: 'User Update', message: 'User updated successfully' },
			})
			// dispatch({
			// 	type: ActionType.EDIT_USER,
			// 	payload: obj,
			// })
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const deleteUser = (obj: any, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.delete(endpoints.USER, {
				data: {
					user: obj,
				},
				headers: {
					'x-permission-name': permissionNames.DELETE_USERS,
				},
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			dispatch({
				type: ActionType.DELETE_USER,
				payload: obj,
			})

			dispatch({
				type: ActionType.SET_SUCCESS,
				payload: { title: 'User Delete', message: 'User deleted successfully' },
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const resetPassword = (
	// headers: any,
	obj: newPasswordParams,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const user = { user: obj }
			const { data } = await AxiosCustom.post(endpoints.PASSWORD, user, {
				headers: {
					'x-permission-name': permissionNames.CHANGE_USER_PASSWORD,
				},
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			dispatch({
				type: ActionType.RESET_PASSWORD,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const getLoggedInUser = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.LOGGED_IN_USER, {
				headers: {
					'x-permission-name': permissionNames.VIEW_USERS,
				},
			})
			// console.log('after getting logged in user', data)
			// if (!data.successful) {
			//     return
			// }
			dispatch({
				type: ActionType.LOGIN,
				payload: data.payload,
			})
			return data
		} catch (err) {
			console.error(err)
		}
	}
}

export const updateUsersLmsStatus = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			await AxiosCustom.get(endpoints.UPDATE_USERS_LMS_STATUS, {
				headers: {
					'x-permission-name': permissionNames.VIEW_USERS,
				},
			})
		} catch (err) {
			console.error(err)
		}
	}
}
