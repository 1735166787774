import { Dispatch } from 'redux'
import { ActionType } from '../action-types'
import { Action } from '../actions'
import AxiosCustom from '../../utils/Axios'
import { endpoints } from '../../config'

interface CreateSupportTicketParams {
	subject: string
	section: string
	description: string
	ccEmails?: string
	attachments: File[]
	emailHtml?: string
	userConfirmationHtml?: string
}

export const createSupportTicket = (params: CreateSupportTicketParams) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			dispatch({ type: ActionType.CREATE_SUPPORT_TICKET_REQUEST })

			// Construct form data for multipart request
			const formData = new FormData()
			formData.append('subject', params.subject)
			formData.append('section', params.section)
			formData.append('description', params.description)

			if (params.ccEmails) {
				const ccEmailsArray = params.ccEmails
					.split(',')
					.map((email) => email.trim())
					.filter((email) => email !== '')

				const formattedCcEmails = ccEmailsArray.map((email) => ({ email }))
				formData.append('ccEmails', JSON.stringify(formattedCcEmails))
			}

			// Append files to the form data
			params.attachments.forEach((file) => {
				formData.append('attachments', file)
			})

			const response = await AxiosCustom.post(
				`${endpoints.SUPPORT}`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				},
			)

			dispatch({
				type: ActionType.CREATE_SUPPORT_TICKET_SUCCESS,
				payload: response.data,
			})
		} catch (error: any) {
			dispatch({
				type: ActionType.CREATE_SUPPORT_TICKET_ERROR,
				payload:
					error?.response?.data?.message || 'Failed to submit support ticket',
			})
		}
	}
}

export const clearSupportError = () => {
	return async (dispatch: Dispatch<Action>) => {
		dispatch({ type: ActionType.CLEAR_SUPPORT_ERROR })
	}
}
