import React, { useEffect, useState } from 'react'
import { Alert } from '../../../models/alert.interface'
import { useTheme, IconButton } from '@mui/material'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import CloseIcon from '@mui/icons-material/Close'
import MinimizeIcon from '@mui/icons-material/Minimize'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Tooltip from '@mui/material/Tooltip'
import { SmallLoading } from '../../../assets/svg/loading'
import { reportRoutes } from '../../../config'
import { useAlertsActions } from '../../../hooks/useActions'

const AlertComponent = ({
	alert,
	removeAlert,
	onLinkClick,
}: {
	alert: Alert
	removeAlert: (id: string) => void
	onLinkClick: (link: string) => void
}) => {
	const theme = useTheme()
	const [dots, setDots] = useState('')
	const { minimizeAlert } = useAlertsActions()

	const backgroundColor = theme.colors.alerts.background
	const boxShadowColor = theme.colors.alerts.shadow
	const textColor = theme.colors.text.general
	const notificationTitleColor = theme.colors.alerts.grey300
	const messageColor = textColor
	const linkColor = theme.colors.alerts.green

	// Use the theme-defined colors for errors/success
	const errorBackgroundColor = theme.colors.alerts.red300
	const successBackgroundColor = theme.colors.status.green

	// ----  AUTO-DISMISS TIMER ----
	useEffect(() => {
		if (alert.status !== 'pending') {
			const timer = setTimeout(() => {
				minimizeAlert(alert.id)
			}, 8000)

			// Cleanup the timer if the component unmounts
			return () => clearTimeout(timer)
		}
	}, [alert.id, alert.status, removeAlert])

	const handleLinkClick = () => {
		let url = alert.link as string
		const isReportType = Object.values(reportRoutes).includes(
			alert.link as string,
		)
		if (alert.extra_data && isReportType) {
			url = `${alert.link}?report=${alert.extra_data}`
		}
		onLinkClick(url)
	}

	useEffect(() => {
		if (alert.status === 'pending') {
			const interval = setInterval(() => {
				setDots((prevDots) => (prevDots.length < 3 ? prevDots + '.' : ''))
			}, 500)
			return () => clearInterval(interval)
		} else {
			setDots('')
		}
	}, [alert.status])

	const handleMinimize = () => {
		minimizeAlert(alert.id)
	}

	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				width: '20vw',
				background: backgroundColor,
				boxShadow: boxShadowColor,
				borderRadius: '30px',
				color: textColor,
				padding: '1rem',
				position: 'relative',
				whiteSpace: 'normal',
				wordWrap: 'break-word',
				transition: 'background 0.3s, color 0.3s',
			}}
		>
			{alert.status === 'pending' ? (
				<div
					style={{
						width: '25px',
						height: '25px',
						position: 'relative', // Make the parent container relative
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						marginRight: '1rem',
					}}
				/>
			) : alert.isError ? (
				<div
					style={{
						width: '25px',
						height: '25px',
						background: errorBackgroundColor,
						display: 'flex',
						borderRadius: '50%',
						alignItems: 'center',
						justifyContent: 'center',
						marginRight: '1rem',
					}}
				>
					<CloseIcon style={{ color: theme.colors.status.red }} />
				</div>
			) : (
				<div
					style={{
						width: '25px',
						height: '25px',
						background: successBackgroundColor,
						display: 'flex',
						borderRadius: '50%',
						alignItems: 'center',
						justifyContent: 'center',
						marginRight: '1rem',
					}}
				>
					<CheckOutlinedIcon
						style={{
							scale: '0.9',
							color: backgroundColor,
						}}
					/>
				</div>
			)}

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '2px',
					flexGrow: 1,
				}}
			>
				<span
					style={{
						color: notificationTitleColor,
						fontSize: theme.font.size.caption,
					}}
				>
					Notification
				</span>
				<span
					style={{
						color: messageColor,
						fontSize: theme.font.size.body,
						display: 'flex',
						alignItems: 'center',
						position: 'relative',
					}}
				>
					{alert.message}
					{alert.status === 'pending' && (
						<>
							<span>{dots}</span>
							<SmallLoading
								style={{
									position: 'absolute',
									top: '50%',
									left: '80%',
									transform: 'translate(-80%, -80%)',
								}}
							/>
						</>
					)}

					{alert.link && alert.status !== 'pending' && (
						<span
							style={{
								textDecoration: 'underline',
								color: linkColor,
								cursor: 'pointer',
								marginLeft: '5px',
							}}
							onClick={handleLinkClick}
						>
							View
						</span>
					)}
					{!alert.isError && alert.link && alert.status !== 'pending' && (
						<Tooltip title='The report name is the last 5 digits of the CSV file name.'>
							<InfoOutlinedIcon
								style={{
									marginLeft: '5px',
									fontSize: '1rem',
									cursor: 'pointer',
									marginBottom: '-0.2rem',
									color: messageColor,
								}}
							/>
						</Tooltip>
					)}
				</span>
			</div>

			<IconButton
				sx={{
					position: 'absolute',
					top: '1px',
					right: '35px',
					padding: '2px',
					'&:hover': {
						color: textColor,
					},
				}}
				onClick={handleMinimize}
				size='small'
			>
				<MinimizeIcon fontSize='small' sx={{ color: notificationTitleColor }} />
			</IconButton>

			<IconButton
				sx={{
					position: 'absolute',
					top: '5px',
					right: '6px',
					padding: '4px',
					'&:hover': {
						color: textColor,
					},
				}}
				onClick={() => removeAlert(alert.id)}
				size='small'
			>
				<CloseIcon fontSize='small' sx={{ color: notificationTitleColor }} />
			</IconButton>
		</div>
	)
}

export default AlertComponent
