// SupportForm.tsx
import React, { FormEvent } from 'react'
import {
	Grid,
	Box,
	Collapse,
	IconButton,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	Typography,
	Tooltip,
} from '@mui/material'
import {
	ExpandMore,
	ExpandLess,
	Delete,
	InfoOutlined,
} from '@mui/icons-material'
import { SubmitButton } from '../components/Buttons'
import { ChipInputList } from '../components/ChipInput'
import { DropList } from '../components/SelectableInputs'
import RichTextEditorField, { InputFieldText } from '../components/Inputs'
import { StyledLabel } from '../components/Informative'
import CustomFileUploader from '../components/CustomFileUploader'

interface SupportFormData {
	subject: string
	section: string
	description: string
	ccEmails: string[]
	attachments: File[]
}

interface SupportFormProps {
	formData: SupportFormData
	formErrors: Partial<Record<keyof SupportFormData, string>>
	onSubjectChange: (val: string) => void
	onSectionChange: (val: string) => void
	onDescriptionChange: (val: string) => void
	onCCEmailsChange: (val: string[]) => void
	onFileUpload: (files: File[]) => void
	onRemoveFile: (index: number) => void
	onToggleUploader: () => void
	onSubmit: (e: FormEvent) => void
	showUploader: boolean
	canSubmit: boolean
	loading: boolean
	requestError: string | null
}

const SupportForm: React.FC<SupportFormProps> = ({
	formData,
	formErrors,
	onSubjectChange,
	onSectionChange,
	onDescriptionChange,
	onCCEmailsChange,
	onFileUpload,
	onRemoveFile,
	onToggleUploader,
	onSubmit,
	showUploader,
	canSubmit,
	loading,
	requestError,
}) => {
	const iconButtonStyle: React.CSSProperties = {
		transform: 'scale(0.8)',
	}

	const labelStyle: React.CSSProperties = {
		marginBottom: '8px',
		display: 'flex',
		alignItems: 'center',
		marginLeft: '0px',
		gap: '0.5rem',
	}

	return (
		<>
			<form
				onSubmit={(e) => {
					e.preventDefault()
					onSubmit(e)
				}}
				noValidate
			>
				<Grid container spacing={2}>
					{/* Subject (larger) and Section (smaller) on the same row */}
					<Grid item xs={12} sm={8}>
						<InputFieldText
							label='Subject'
							type='text'
							value={formData.subject}
							onChange={onSubjectChange}
							errormessage={formErrors.subject}
							fullWidth
							mandatory
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<DropList
							onChange={onSectionChange}
							options={[
								'Technical Issue',
								'Finance Issue',
								'Account Support',
								'Feature Request',
								'Other',
							]}
							label='Section'
							value={formData.section}
							errored={!!formErrors.section}
							errormessage={formErrors.section}
							mandatory
						/>
					</Grid>

					{/* Description Row */}
					<Grid item xs={12}>
						<RichTextEditorField
							label='Description'
							value={formData.description}
							onChange={onDescriptionChange}
							errorMessage={formErrors.description}
							mandatory
						/>
					</Grid>

					<Grid item xs={12} style={{ position: 'relative' }}>
						<div style={{ position: 'relative', width: '100%' }}>
							<ChipInputList
								label='CC Emails (optional)'
								value={formData.ccEmails}
								onChange={onCCEmailsChange}
								placeholder='Type email and press enter'
								errormessage={formErrors.ccEmails}
								inputStyle={{ width: '100%' }}
							/>
							<Tooltip title='Type the email and press Enter to add it to the list.'>
								<InfoOutlined
									sx={{
										fontSize: '18px',
										color: 'grey',
										cursor: 'pointer',
										position: 'absolute',
										left: '7.8rem',
										top: '17%', // Center vertically relative to the input
										transform: 'translateY(-50%)', // Perfect vertical alignment
									}}
								/>
							</Tooltip>
						</div>
					</Grid>

					{/* Attachments Row */}
					<Grid item xs={12}>
						<div style={labelStyle}>
							<StyledLabel
								focused={showUploader}
								error={!!formErrors.attachments}
							>
								Attach Files (optional)
							</StyledLabel>
							<IconButton
								onClick={onToggleUploader}
								aria-label='toggle uploader visibility'
								size='small'
								style={iconButtonStyle}
							>
								{showUploader ? <ExpandLess /> : <ExpandMore />}
							</IconButton>
						</div>
						<Collapse in={showUploader}>
							<Box sx={{ mt: 1 }}>
								<CustomFileUploader onSetFiles={onFileUpload} />
							</Box>
						</Collapse>

						{formData.attachments.length > 0 && (
							<List sx={{ maxHeight: 100, overflow: 'auto', mt: 1 }}>
								{formData.attachments.map((file, index) => (
									<ListItem key={index} divider>
										<ListItemText primary={file.name} />
										<ListItemSecondaryAction>
											<IconButton
												edge='end'
												onClick={() => onRemoveFile(index)}
											>
												<Delete color='error' />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								))}
							</List>
						)}
					</Grid>

					<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
						<SubmitButton
							type='submit'
							style={{ width: '140px', height: '40px' }}
							disabled={!canSubmit}
						>
							Submit
						</SubmitButton>
					</Grid>
				</Grid>
			</form>
		</>
	)
}

export default SupportForm
