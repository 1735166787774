import React, { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { PageWrapper, StyledNameField } from '../components/reusableComponents'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { DropList } from '../components/SelectableInputs'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { useActions, useLearningActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { fetchData } from '../../utils/helpers/navigationHelper'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { Box, useTheme } from '@mui/material'
import { InputFieldText } from '../components/Inputs'
import { SubmitButton, UnborderedButton } from '../components/Buttons'

export const LearningAdd = (props: {
	row: any
	PopUpVisible: boolean
	setPopUpVisible: any
	setLoading: any
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	categoryOptions: string[]
}) => {
	const theme = useTheme()
	const [file, setFile] = useState<File>()
	const navigate = useNavigate()
	const params = useParams()
	const { getLearningAction, addLearningRow, updateLearningRow } =
		useLearningActions()
	const { login, learning } = useTypedSelector((state) => state)
	const [fileTitle, setFileTitle] = useState('')
	const [fileName, setFileName] = useState('')
	const [fileCategory, setFileCategory] = useState('')
	const [fileOpenLink, setFileOpenLink] = useState('')
	const [fileLink, setFileLink] = useState('')
	const [createdBy, setCreatedBy] = useState('')
	const [fileType, setFileType] = useState('')

	// const headers = {
	// 	Authorization: `Token ${login.user.token}`,
	// }
	const fillFields = (row: any) => {
		setFileTitle(row.file_title)
		setFileOpenLink(row.file_open_link)
		setFileLink(row.file_link)
		setFileName(row.file_name || '')
		setCreatedBy(row.created_by)
		setFileCategory(row.category || '')
	}
	useEffect(() => {
		if (props.row) {
			fillFields(props.row)
		}
	}, [])

	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (fileOpenLink) {
			setFileOpenLink('')
		}
		if (e.target.files) {
			setFile(e.target.files[0])
		}
	}
	const reader = new FileReader()
	const toBase64 = async (file: any) => {
		return new Promise((resolve, reject) => {
			reader.readAsBinaryString(file)
			reader.onload = () => resolve(reader.result)
			reader.onerror = (error) => reject(error)
		})
	}
	const handleUploadClick = async () => {
		// if (!file) {
		// 	return
		// }
		props.setLoading(true)
		let dataToSave = {}
		if (file) {
			const newFile = await toBase64(file)
			dataToSave = {
				file_title: fileTitle,
				file_name: file.name,
				file_open_link: fileOpenLink || newFile,
				file_link: fileLink,
				file_type: file.type,
				created_by: createdBy || login.user.email,
				category: fileCategory || '',
			}
		} else {
			dataToSave = {
				file_title: fileTitle,
				file_name: '',
				file_open_link: '',
				file_link: fileLink,
				file_type: '',
				created_by: createdBy || login.user.email,
				category: fileCategory || '',
			}
		}
		addLearningRow(dataToSave, props.setErrorMessage, props.setLoading)
	}
	const handleUpdateClick = async () => {
		let dataToSave: any
		props.setLoading(true)
		if (!file) {
			dataToSave = {
				id: params.id,
				file_title: fileTitle,
				file_name: fileName,
				file_open_link: fileOpenLink,
				file_link: fileLink,
				file_type: fileType,
				created_by: createdBy || login.user.email,
				category: fileCategory,
			}
		} else {
			const newFile = await toBase64(file)
			dataToSave = {
				id: params.id,
				file_title: fileTitle,
				file_name: file.name,
				file_open_link: newFile,
				file_link: fileLink,
				file_type: file.type,
				created_by: createdBy || login.user.email,
				category: fileCategory,
			}
		}
		updateLearningRow(dataToSave, props.setErrorMessage, props.setLoading)
	}
	const closeDetailedView = async () => {
		nullifyFields()
		await fetchData(getLearningAction, learning.learning)
		navigate('/documents')
		props.setPopUpVisible(false)
	}
	const nullifyFields = () => {
		setFile(undefined)
		setFileTitle('')
		setFileOpenLink('')
		setFileLink('')
		setCreatedBy('')
		setFileCategory('')
	}

	return (
		<PageWrapper
			style={{
				width: '80%',
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'column',
				marginTop: '20px',
			}}
		>
			<UnborderedButton
				style={{
					margin: 'auto',
					width: '80px',
					display: 'flex',
					position: 'absolute',
					left: '4%',
				}}
				icon={
					<ArrowBackIosIcon style={{ height: '16px', marginRight: '0px' }} />
				}
				onClick={closeDetailedView}
			>
				Back
			</UnborderedButton>
			<Box
				sx={{ borderBottom: 1, borderColor: 'divider' }}
				style={{
					width: '60%',
					paddingBottom: '12px',
					marginLeft: '10%',
					marginTop: 60,
					display: 'flex',
					gap: '18px',
					flexDirection: 'column',
				}}
			>
				<InputFieldText
					label='File title'
					type='text'
					value={fileTitle}
					onChange={setFileTitle}
				/>
				<InputFieldText
					label='File drive link'
					type='text'
					value={fileLink}
					onChange={setFileLink}
				/>
				<DropList
					onChange={setFileCategory}
					label={'Category'}
					value={fileCategory}
					options={props.categoryOptions}
					fontSize='13px'
				></DropList>
				<input
					type='file'
					onChange={handleFileChange}
					style={{ display: 'none' }}
					id='fileUploader'
				/>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						width: '60%',
						marginLeft: 0,
					}}
				>
					<UnborderedButton
						style={{
							marginTop: '12px',
							display: 'flex',
							cursor: 'pointer',
							// width: '120px',
							gap: 4,
							padding: 0,
						}}
						onClick={() => document.getElementById('fileUploader')?.click()}
						icon={
							<UploadFileIcon
								style={{ color: 'inherit', scale: '1.2' }}
							></UploadFileIcon>
						}
					>
						Add file
					</UnborderedButton>
					<span
						style={{
							color: theme.colors.black,
							width: '60%',
							marginLeft: '22px',
						}}
					>
						{fileOpenLink ? fileName : file && file.name}
					</span>
				</div>
				{params.id ? (
					<SubmitButton
						style={{
							marginTop: '12px',
							width: 60,
							marginLeft: 'auto',
							marginRight: 'auto',
						}}
						onClick={handleUpdateClick}
						disabled={!fileTitle || !fileLink || !fileCategory}
					>
						Update
					</SubmitButton>
				) : (
					<SubmitButton
						style={{ marginTop: '12px' }}
						onClick={handleUploadClick}
						disabled={!fileTitle || !fileLink || !fileCategory}
					>
						Save
					</SubmitButton>
				)}
			</Box>
		</PageWrapper>
	)
}
