import { ActionType } from '../action-types/index'
import { Alert, AlertStatus } from '../../models/alert.interface'
import { ReportsByUser } from '../reducers/reportReducer'
import { Notification } from '../../models/model.interface'
export interface Role {
	id?: number
	name: string
	description: string
	default_permission: string
}

export interface Company {
	name: string
	id: number
	type: string
	agency_access: boolean
}

interface GetSalesPlanAppsReducer {
	type: ActionType.GET_SALES_PLAN_APPS
	payload: any
}
interface LoginInterfacrReducer {
	type: ActionType.LOGIN
	payload: any
}

interface GetUsersInterfaceReducer {
	type: ActionType.GET_USERS
	payload: any
}

interface InsertUserInterfaceReducer {
	type: ActionType.INSERT_USER
	payload: any
}

interface DeleteUserInterfaceReducer {
	type: ActionType.DELETE_USER
	payload: any
}

interface ResetPasswordInterfaceReducer {
	type: ActionType.RESET_PASSWORD
	payload: any
}
interface NewsReducerInterface {
	type: ActionType.GET_NEWS
	payload: any
}

interface PostNewsReducerInterface {
	type: ActionType.POST_NEWS
	payload: any
}

interface PostNewsImageReducerInterface {
	type: ActionType.POST_IMAGE
	payload: any
}

interface GetAdvertiserInterfaceReducer {
	type: ActionType.GET_ADVERTISER
	payload: any
}
interface GetAdvertiserReportInterfaceReducer {
	type: ActionType.GET_ADVERTISER_REPORT
	payload: any
}
interface GetAdvertiserContactsInterfaceReducer {
	type: ActionType.GET_ADVERTISER_CONTACTS
	payload: any
}

interface InsertAdvertiserInterfaceReducer {
	type: ActionType.INSERT_ADVERTISER
	payload: any
}
interface InsertAdvertiserMetaInterfaceReducer {
	type: ActionType.INSERT_ADVERTISER_META
	payload: any
}
interface DeleteAdvertiserInterfaceReducer {
	type: ActionType.DELETE_ADVERTISER
	payload: any
}

interface DeleteAdvertiserIconAction {
	type: ActionType.DELETE_ADVERTISER_ICON
	payload: {
		name: string
		iconUrl: string | null
	}
}

interface UpdateAdvertiserIconAction {
	type: ActionType.UPDATE_ADVERTISER_ICON
	payload: { name: string; iconUrl: string }
}

interface UpdatePublisherIconAction {
	type: ActionType.UPDATE_PUBLISHER_ICON
	payload: { name: string; iconUrl: string }
}

interface DeletePublisherIconAction {
	type: ActionType.DELETE_PUBLISHER_ICON
	payload: {
		name: string
		iconUrl: string | null
	}
}

interface UpdateCreatorIconAction {
	type: ActionType.UPDATE_CREATOR_ICON
	payload: { name: string; iconUrl: string }
}

interface DeleteCreatorIconAction {
	type: ActionType.DELETE_CREATOR_ICON
	payload: {
		name: string
		iconUrl: string | null
	}
}

interface updateCreatorAction {
	type: ActionType.UPDATE_CREATOR
	payload: any
}

interface LogoutInterfaceReducer {
	type: ActionType.LOGOUT
	payload: any
}

interface GetPublisherInterfaceReducer {
	type: ActionType.GET_PUBLISER
	payload: any
}

interface GetPublisherReportInterfaceReducer {
	type: ActionType.GET_PUBLISHER_REPORT
	payload: any
}
interface GetPublisherContactsInterfaceReducer {
	type: ActionType.GET_PUBLISHER_CONTACTS
	payload: any
}

interface InsertPublisherInterfaceReducer {
	type: ActionType.INSERT_PUBLIHSER
	payload: any
}
interface InsertPublisherMasterInterfaceReducer {
	type: ActionType.INSERT_PUBLIHSER_MASTER
	payload: any
}
interface InsertPublisherMetaInterfaceReducer {
	type: ActionType.INSERT_PUBLIHSER_META
	payload: any
}

interface GetCampaignInterfaceReducer {
	type: ActionType.GET_CAMPAIGN
	payload: any
}

interface GetFiltersInterfaceReducer {
	type: ActionType.SEND_APPSFLYER
	payload: any
}

interface InsertCampaignInterfaceReducer {
	type: ActionType.INSERT_CAMPAIGN
	payload: any
}
interface DeleteCampaignInterfaceReducer {
	type: ActionType.DELETE_CAMPAIGN
	payload: any
}
interface GetAppInterfaceReducer {
	type: ActionType.GET_APP
	payload: any
}
interface InsertP360RuleInterfaceReducer {
	type: ActionType.INSERT_P360_RULE
	payload: any
}
interface RemoveP360RuleInterfaceReducer {
	type: ActionType.REMOVE_P360_RULE
	payload: any
}
interface UpdateAppP360nterfaceReducer {
	type: ActionType.UPDATE_APP_P360
	payload: any
}
interface RemoveAppP360nterfaceReducer {
	type: ActionType.REMOVE_APP_P360
	payload: any
}
interface GetIconsInterfaceReducer {
	type: ActionType.GET_ICONS
	payload: any
}
interface UpdateAppReducerInterface {
	type: ActionType.UPDATE_APP
	payload: any
}
interface GetAppsflyerfaceReducer {
	type: ActionType.GET_APPSFLYER
	payload: any
}
interface AddAppReducer {
	type: ActionType.ADD_APP
	payload: any
}
interface UpdateAppInfoReducer {
	type: ActionType.UPDATE_APP_INFO
	payload: any
}

interface SaveAppsflyerReducer {
	type: ActionType.SAVE_APPSFLYER
	payload: any
}

interface NavigationReducer {
	type: ActionType.NAVIGATION
	payload: any
}

interface ReevaluateReducer {
	type: ActionType.REEVALUATE
	payload: any
}

interface PresearchReducer {
	type: ActionType.PRESEARCH
	payload: any
}
interface InsertLogReducer {
	type: ActionType.INSERT_LOG
	payload: any
}
interface GetLogsReducer {
	type: ActionType.GET_LOGS
	payload: any
}
interface GetSettingsReducer {
	type: ActionType.GET_SETTINGS
	payload: any
}
interface UpdateSettingsReducer {
	type: ActionType.UPDATE_SETTINGS
	payload: any
}

interface PresetReducer {
	type: ActionType.PRESET
	payload: any
}

interface GetNetworkReducer {
	type: ActionType.GET_NETWORK
	payload: any
}
interface PostNetworkReducer {
	type: ActionType.POST_NETWORK
	payload: any
}
interface UpdateNetworkReducer {
	type: ActionType.UPDATE_NETWORK
	payload: any
}
interface RefreshAppsflyerReducer {
	type: ActionType.UPDATE_APPSFLYER
	payload: any
}
interface PostRawReducer {
	type: ActionType.POST_RAWDATA
	payload: any
}
interface GetRawReducer {
	type: ActionType.GET_RAWDATA
	payload: any
}
interface UpdatesReducer {
	type: ActionType.UPDATE
	payload: any
}
interface GetPasswordsReducer {
	type: ActionType.GETPASSWORDS
	payload: any
}
interface GetAppsflyerAccountsReducer {
	type: ActionType.GETACCOUNTS
	payload: any
}
interface GetLearningReducer {
	type: ActionType.GET_DOCUMENTS
	payload: any
}
interface AddLearningReducer {
	type: ActionType.ADD_DOCUMENTS
	payload: any
}
interface RemoveLearningReducer {
	type: ActionType.REMOVE_DOCUMENTS
	payload: any
}
interface GetDashboardReducer {
	type: ActionType.GETDASHBOARD
	payload: any
}
interface GetCampiagnHistoryReducer {
	type: ActionType.GET_CAMPAIGN_HISTORY
	payload: any
}
interface GetFinanceReducer {
	index?: number
	payload: any
	type: ActionType.GET_FINANCE
}
interface EmptyFinanceReducer {
	payload: any
	type: ActionType.EMPTY_FINANCE
}
interface UploadFinanceReducer {
	payload: any
	type: ActionType.UPLOAD_FINANCE
}
interface DeleteFinanceReducer {
	payload: any
	type: ActionType.DELETE_FINANCE
}
interface UploadFinanceRollOverReducer {
	payload: any
	type: ActionType.UPLOAD_FINANCE_ROLLOVER
}
interface UploadFinanceDemandReducer {
	payload: any
	type: ActionType.UPLOAD_FINANCE_DEMAND
}
interface UploadFinancePublisherReducer {
	payload: any
	type: ActionType.UPLOAD_FINANCE_PUBLISHER
}
interface UploadFinanceBalanceInReducer {
	payload: any
	type: ActionType.UPLOAD_FINANCE_BALANCEIN
}
interface UploadFinanceBalanceOutReducer {
	payload: any
	type: ActionType.UPLOAD_FINANCE_BALANCEOUT
}
interface UploadInstructionsFinanceReducer {
	payload: any
	type: ActionType.UPLOAD_INSTRUCTIONS_FINANCE
}
interface UploadProofFinanceReducer {
	payload: any
	type: ActionType.UPLOAD_PROOF_FINANCE
}
interface DeleteProofFinanceReducer {
	payload: any
	type: ActionType.DELETE_PROOF_FINANCE
}
interface SendEmailFinanceReducer {
	payload: any
	type: ActionType.SEND_EMAIL_FINANCE
}
interface SendEmailAdvertiserFinanceReducer {
	payload: any
	type: ActionType.SEND_EMAIL_FINANCE_ADVERTISER
}
interface CreateCreatorCampaignFinanceReducer {
	payload: any
	type: ActionType.CREATE_CREATOR_CAMPAIGN
}
interface GetAutoSwitch {
	type: ActionType.GETAUTOSWITCH
	payload: any
}
interface PutAutoSwitch {
	type: ActionType.PUTAUTOSWITCH
	payload: any
}

interface DeleteAutoSwitch {
	type: ActionType.DELETEAUTOSWITCH
	payload: any
}

interface AvatarsReducer {
	type: ActionType.AVATAR
	payload: any
}

interface VersionReducer {
	type: ActionType.GETVERSION
	payload: any
}

interface P360Reducer {
	type: ActionType.P360AGGREGATED
	payload: any
}

interface GetPublisherWishListReducer {
	type: ActionType.GETWISHLIST
	payload: any
}
interface AddPublisherWishListReducer {
	type: ActionType.ADDWISHLIST
	payload: any
}
interface UpdatePublisherWishListReducer {
	type: ActionType.UPDATEWISHLIST
	payload: any
}
interface DeletePublisherWishListReducer {
	type: ActionType.DELETEWISHLIST
	payload: any
}
interface GetCreatortReducer {
	type: ActionType.GET_CREATOR
	payload: any
}
interface GetPidHandshakeReducer {
	type: ActionType.GETHANDSHAKE
	payload: any
}
interface InsertCreatorReducer {
	type: ActionType.INSERT_CREATOR | ActionType.INSERT_CREATORS
	payload: any
}

interface GenerateReportStartAction {
	type: ActionType.GENERATE_REPORT_START
}

interface GenerateReportSuccessAction {
	type: ActionType.GENERATE_REPORT_SUCCESS
	payload: {
		userEmail: string
		reportType: string
		report: any
	}
}

interface GenerateReportFailureAction {
	type: ActionType.GENERATE_REPORT_FAILURE
	payload: string // error message
}

interface LoadReportSuccessAction {
	type: ActionType.LOAD_REPORT_SUCCESS
	payload: {
		userEmail: string
		reportType: string
		report: any
	}
}

interface PersistReportAction {
	type: ActionType.PERSIST_REPORT
	payload: any
}

interface GenerateReportStartAction {
	type: ActionType.GENERATE_REPORT_START
}

interface GenerateReportStartAction {
	type: ActionType.GENERATE_REPORT_START
}

interface GenerateReportSuccessAction {
	type: ActionType.GENERATE_REPORT_SUCCESS
	payload: {
		userEmail: string
		reportType: string
		report: any
	}
}

interface GenerateReportFailureAction {
	type: ActionType.GENERATE_REPORT_FAILURE
	payload: string // error message
}

interface LoadReportSuccessAction {
	type: ActionType.LOAD_REPORT_SUCCESS
	payload: {
		userEmail: string
		reportType: string
		report: any
	}
}

interface PersistReportAction {
	type: ActionType.PERSIST_REPORT
	payload: any
}

interface LoadAllReportsStartAction {
	type: ActionType.LOAD_ALL_REPORTS_START
}

interface LoadAllReportsSuccessAction {
	type: ActionType.LOAD_ALL_REPORTS_SUCCESS
	payload: {
		userEmail: string
		reports: {
			network?: any[]
			invalid?: any[]
			aggregated?: any[]
		}
	}
}

interface LoadAllReportsFailureAction {
	type: ActionType.LOAD_ALL_REPORTS_FAILURE
	payload: string
}

interface UpdateReportNameStartAction {
	type: ActionType.UPDATE_REPORT_NAME_START
}

interface UpdateReportNameSuccessAction {
	type: ActionType.UPDATE_REPORT_NAME_SUCCESS
	payload: {
		reportId: number
		newName: string
	}
}

interface DeleteReportFailureAction {
	type: ActionType.DELETE_REPORT_FAILURE
	payload: string
}

interface DeleteReportStartAction {
	type: ActionType.DELETE_REPORT_START
}

interface DeleteReportSuccessAction {
	type: ActionType.DELETE_REPORT_SUCCESS
	payload: {
		reportId: number
	}
}

interface UpdateReportNameFailureAction {
	type: ActionType.UPDATE_REPORT_NAME_FAILURE
	payload: string
}

interface AddAlertAction {
	type: ActionType.ADD_ALERT
	payload: {
		id: string
		message: string
		isError: boolean
		seen: boolean
		link?: string
		extra_data?: string | undefined
		status?: AlertStatus
	}
}

interface RemoveAlertAction {
	type: ActionType.REMOVE_ALERT
	payload: string
}

interface RestoreAlertAction {
	type: ActionType.RESTORE_ALL_ALERTS
}

interface MarkAlertsAsSeenAction {
	type: ActionType.MARK_ALERTS_AS_SEEN
	payload: Alert[]
}

interface UpdateAlertAction {
	type: ActionType.UPDATE_ALERT
	payload: {
		id: string
		message?: string
		isError?: boolean
		status?: AlertStatus
		link?: string
		extra_data?: string | undefined
	}
}

interface OpenAlertAction {
	type: ActionType.OPEN
	payload: Alert[]
}
interface CloseAlertAction {
	type: ActionType.CLOSE
	payload: Alert[]
}
interface GetPermissionsAction {
	type: ActionType.GETPERMISSIONS
	payload: any
}

interface MinimizeAlertAction {
	type: ActionType.MINIMIZE_ALERT
	payload: string
}

interface SetPermissionsAction {
	type: ActionType.SETPERMISSIONS
	payload: any
}

interface FetchRolesAction {
	type: ActionType.FETCH_ROLES
	payload: Role[]
}

interface CreateRoleAction {
	type: ActionType.CREATE_ROLE
	payload: Role
}

interface UpdateRoleAction {
	type: ActionType.UPDATE_ROLE
	payload: Role
}

interface DeleteRoleAction {
	type: ActionType.DELETE_ROLE
	payload: number
}

interface FetchRolesErrorAction {
	type: ActionType.FETCH_ROLES_ERROR
	payload: string
}

interface GetCompanySettingsAction {
	type: ActionType.GET_COMPANY_SETTINGS
	payload: any
}

interface UpdateCompanySettingsAction {
	type: ActionType.UPDATE_COMPANY_SETTINGS
	payload: any
}

interface SetTabIndexAction {
	type: ActionType.SET_TAB_INDEX
	payload: {
		userEmail: string
		reportType: keyof ReportsByUser
		tabIndex: number
	}
}

interface GetAllCompanies {
	type: ActionType.GET_ALL_COMPANIES
	payload: Company[]
}

interface UpdateCompany {
	type: ActionType.UPDATE_COMPANY
	payload: Company
}

interface CreateSupportTicketRequestAction {
	type: ActionType.CREATE_SUPPORT_TICKET_REQUEST
}

interface CreateSupportTicketSuccessAction {
	type: ActionType.CREATE_SUPPORT_TICKET_SUCCESS
	payload: any
}

interface CreateSupportTicketErrorAction {
	type: ActionType.CREATE_SUPPORT_TICKET_ERROR
	payload: string
}

interface ClearSupportTicketErrorAction {
	type: ActionType.CLEAR_SUPPORT_ERROR
}

interface GetAllNotifications {
	type: ActionType.GET_ALL_NOTIFICATIONS
	payload: Notification[]
}

interface CreateNotification {
	type: ActionType.CREATE_NOTIFICATION
	payload: { notification: Notification; unread: number }
}

interface UpdateNotification {
	type: ActionType.UPDATE_NOTIFICATION
	payload: Notification
}

interface DeleteNotification {
	type: ActionType.DELETE_NOTIFICATION
	payload: any
}

interface GetUserNotifications {
	type: ActionType.GET_USER_NOTIFICATIONS
	payload: { notifications: Notification[]; unread: number }
}

interface SetError {
	type: ActionType.SET_ERROR
	payload: {
		statusCode: number
		errorCode: string
		message: string
	}
}

interface ClearError {
	type: ActionType.CLEAR_ERROR
}

interface SetSuccess {
	type: ActionType.SET_SUCCESS
	payload: {
		title: string
		message: string
	}
}

interface ClearSuccess {
	type: ActionType.CLEAR_SUCCESS
}

export type Action =
	| LoginInterfacrReducer
	| GetUsersInterfaceReducer
	| GetAdvertiserInterfaceReducer
	| GetAdvertiserReportInterfaceReducer
	| GetAdvertiserContactsInterfaceReducer
	| UpdateAdvertiserIconAction
	| DeleteAdvertiserIconAction
	| LogoutInterfaceReducer
	| GetPublisherInterfaceReducer
	| InsertAdvertiserInterfaceReducer
	| InsertAdvertiserMetaInterfaceReducer
	| DeleteAdvertiserInterfaceReducer
	| InsertPublisherInterfaceReducer
	| InsertPublisherMasterInterfaceReducer
	| InsertPublisherMetaInterfaceReducer
	| UpdatePublisherIconAction
	| DeletePublisherIconAction
	| GetCampaignInterfaceReducer
	| InsertCampaignInterfaceReducer
	| DeleteCampaignInterfaceReducer
	| GetAppsflyerfaceReducer
	| InsertUserInterfaceReducer
	| GetAppInterfaceReducer
	| GetIconsInterfaceReducer
	| ResetPasswordInterfaceReducer
	| GetFiltersInterfaceReducer
	| UpdateAppReducerInterface
	| InsertP360RuleInterfaceReducer
	| RemoveP360RuleInterfaceReducer
	| UpdateAppP360nterfaceReducer
	| RemoveAppP360nterfaceReducer
	| DeleteUserInterfaceReducer
	| NewsReducerInterface
	| AddAppReducer
	| UpdateAppInfoReducer
	| SaveAppsflyerReducer
	| NavigationReducer
	| ReevaluateReducer
	| PresearchReducer
	| InsertLogReducer
	| GetLogsReducer
	| GetSettingsReducer
	| UpdateSettingsReducer
	| PresetReducer
	| PostNewsReducerInterface
	| PostNewsImageReducerInterface
	| GetNetworkReducer
	| PostNetworkReducer
	| RefreshAppsflyerReducer
	| UpdateNetworkReducer
	| PostRawReducer
	| GetRawReducer
	| UpdatesReducer
	| GetPublisherReportInterfaceReducer
	| GetPublisherContactsInterfaceReducer
	| GetPasswordsReducer
	| GetLearningReducer
	| AddLearningReducer
	| RemoveLearningReducer
	| GetDashboardReducer
	| GetAutoSwitch
	| PutAutoSwitch
	| DeleteAutoSwitch
	| GetAppsflyerAccountsReducer
	| GetCampiagnHistoryReducer
	| GetFinanceReducer
	| EmptyFinanceReducer
	| AvatarsReducer
	| UploadFinanceReducer
	| DeleteFinanceReducer
	| UploadFinanceRollOverReducer
	| UploadFinanceDemandReducer
	| UploadFinancePublisherReducer
	| UploadFinanceBalanceInReducer
	| UploadFinanceBalanceOutReducer
	| SendEmailFinanceReducer
	| SendEmailAdvertiserFinanceReducer
	| UploadInstructionsFinanceReducer
	| UploadProofFinanceReducer
	| DeleteProofFinanceReducer
	| CreateCreatorCampaignFinanceReducer
	| VersionReducer
	| P360Reducer
	| GetSalesPlanAppsReducer
	| GetPublisherWishListReducer
	| UpdatePublisherWishListReducer
	| AddPublisherWishListReducer
	| DeletePublisherWishListReducer
	| GetPidHandshakeReducer
	| GetCreatortReducer
	| InsertCreatorReducer
	| UpdateCreatorIconAction
	| DeleteCreatorIconAction
	| GenerateReportStartAction
	| GenerateReportSuccessAction
	| GenerateReportFailureAction
	| LoadReportSuccessAction
	| PersistReportAction
	| LoadAllReportsStartAction
	| LoadAllReportsSuccessAction
	| LoadAllReportsFailureAction
	| UpdateReportNameStartAction
	| UpdateReportNameSuccessAction
	| UpdateReportNameFailureAction
	| DeleteReportFailureAction
	| DeleteReportStartAction
	| DeleteReportSuccessAction
	| AddAlertAction
	| RemoveAlertAction
	| RestoreAlertAction
	| MarkAlertsAsSeenAction
	| MinimizeAlertAction
	| UpdateAlertAction
	| OpenAlertAction
	| CloseAlertAction
	| GetPermissionsAction
	| SetPermissionsAction
	| FetchRolesAction
	| CreateRoleAction
	| UpdateRoleAction
	| DeleteRoleAction
	| FetchRolesErrorAction
	| GetCompanySettingsAction
	| UpdateCompanySettingsAction
	| SetTabIndexAction
	| updateCreatorAction
	| GetAllCompanies
	| UpdateCompany
	| CreateSupportTicketRequestAction
	| CreateSupportTicketSuccessAction
	| CreateSupportTicketErrorAction
	| ClearSupportTicketErrorAction
	| GetAllNotifications
	| CreateNotification
	| UpdateNotification
	| DeleteNotification
	| GetUserNotifications
	| SetError
	| ClearError
	| SetSuccess
	| ClearSuccess

export const emptyFinance = () => {
	return {
		type: ActionType.EMPTY_FINANCE,
		payload: {},
	}
}

export const loadReportSuccess = (data: any) => {
	return {
		type: ActionType.LOAD_REPORT_SUCCESS,
		payload: data,
	}
}

export const persistReport = (data: any) => {
	return {
		type: ActionType.PERSIST_REPORT,
		payload: data,
	}
}
