import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../state/reducers'
import SuccessModal from './SuccessModal'
import { useSuccessActions } from '../../hooks/useActions'

const GlobalSuccessHandler = () => {
	const dispatch = useDispatch()
	const { clearSuccess } = useSuccessActions()
	const { isSuccess, successDetails } = useSelector(
		(state: RootState) => state.success,
	)

	const handleClose = () => {
		dispatch(clearSuccess())
	}

	if (!isSuccess) return null

	return (
		<SuccessModal
			open={isSuccess}
			title={successDetails?.title || 'Success'}
			message={successDetails?.message || 'Operation was successful.'}
			onClose={handleClose}
		/>
	)
}

export default GlobalSuccessHandler
