import { ActionType } from '../action-types'
import { Action } from '../actions'

interface SuccessState {
	isSuccess: boolean
	successDetails: {
		title: string
		message: string
	} | null
}

const initialState: SuccessState = {
	isSuccess: false,
	successDetails: null,
}

const successReducer = (
	state: SuccessState = initialState,
	action: Action,
): SuccessState => {
	switch (action.type) {
		case ActionType.SET_SUCCESS:
			return {
				...state,
				isSuccess: true,
				successDetails: action.payload,
			}
		case ActionType.CLEAR_SUCCESS:
			return {
				...state,
				isSuccess: false,
				successDetails: null,
			}
		default:
			return state
	}
}

export default successReducer
