import { Dispatch } from 'redux'
import { ActionType } from '../state/action-types'
import { Action } from '../state/actions'
import { AlertStatus } from '../models/alert.interface'

class DataServerWebSocketManager {
	private static instance: DataServerWebSocketManager
	private socket: WebSocket | null = null
	private dispatch: Dispatch<Action> | null = null
	private addAlert:
		| ((
				message: string,
				isError?: boolean,
				link?: string | undefined,
				reportUuid?: string | undefined,
				id?: string | undefined,
				status?: AlertStatus | undefined,
		  ) => void)
		| null = null

	private constructor() {}

	static getInstance(): DataServerWebSocketManager {
		if (!DataServerWebSocketManager.instance) {
			DataServerWebSocketManager.instance = new DataServerWebSocketManager()
		}
		return DataServerWebSocketManager.instance
	}

	setDispatch(dispatch: Dispatch<Action>): void {
		this.dispatch = dispatch
	}

	setAddAlert(
		addAlert: (
			message: string,
			isError?: boolean,
			link?: string | undefined,
			reportUuid?: string | undefined,
			id?: string | undefined,
			status?: AlertStatus | undefined,
		) => void,
	): void {
		this.addAlert = addAlert
	}

	connect(email: string, company_id: number): void {
		if (this.socket) {
			// console.warn('DataServer WebSocket is already connected')
			return
		}

		try {
			const isProd = window.location.href.includes('varys')
			const isStage = window.location.href.includes('stage')
			// Determine the WebSocket protocol dynamically
			const wsProtocol = isProd || isStage ? 'wss' : 'ws'

			// Construct the WebSocket URL
			const wsHost = isProd
				? 'task-executor.varys.io'
				: isStage
				? 'stage.varys.io'
				: 'localhost:8000'

			const wsUrl = `${wsProtocol}://${wsHost}/ws?email=${encodeURIComponent(
				email,
			)}&company_id=${company_id}`

			// Initialize the WebSocket
			this.socket = new WebSocket(wsUrl)

			this.socket.onopen = () => {
				// console.log('DataServer WebSocket connected')
				const roomName = `${company_id}:${email}`
				// console.log(`Connected to room: ${roomName}`)
			}

			this.socket.onmessage = (event) => {
				try {
					const data = JSON.parse(event.data)
					if (data.event === 'NOTIFICATION') {
						// console.log('New notification:', data.payload)
						const payload =
							typeof data.payload === 'string'
								? JSON.parse(data.payload)
								: data.payload

						// Dispatch Redux action
						if (this.dispatch) {
							this.dispatch({
								type: ActionType.CREATE_NOTIFICATION,
								payload: {
									notification: payload,
									unread: payload.unread_count,
								},
							})
						}

						// Trigger addAlert if available
						if (this.addAlert) {
							this.addAlert(
								payload.message,
								false,
								payload.action_url,
								undefined,
								payload.id,
							)
						}
					}
				} catch (error) {
					console.error('Error handling WebSocket message:', error)
				}
			}

			this.socket.onerror = (error) => {
				console.error('DataServer WebSocket error:', error)
			}

			this.socket.onclose = (event) => {
				// console.log('DataServer WebSocket disconnected:', event.reason)
				this.socket = null
			}
		} catch (error) {
			console.error('Error establishing WebSocket connection:', error)
		}
	}

	emit(event: string, data: any): void {
		if (!this.socket) {
			console.error('DataServer WebSocket is not connected')
			return
		}
		try {
			const message = JSON.stringify({ event, data })
			this.socket.send(message)
		} catch (error) {
			console.error(`Error sending message: ${error}`)
		}
	}

	disconnect(): void {
		if (this.socket) {
			try {
				this.socket.close()
				this.socket = null
				// console.log('DataServer WebSocket disconnected')
			} catch (error) {
				console.error('Error disconnecting WebSocket:', error)
			}
		}
	}
}

export default DataServerWebSocketManager
