import React, { useState, useEffect } from 'react'
import { Typography, useTheme, IconButton } from '@mui/material'
import ReplayIcon from '@mui/icons-material/Replay'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { useSupportActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import SupportForm from './SupportForm'

import {
	OuterContainer,
	CenteredContainer,
	HeaderWrapper,
	FormWrapper,
	SuccessContainer,
	AnimatedEllipsis,
} from './SupportStyled'

interface SupportFormData {
	subject: string
	section: string
	description: string
	ccEmails: string[]
	attachments: File[]
	emailHtml?: string
}

const SupportPage: React.FC = () => {
	const theme = useTheme()
	const { createSupportTicket, clearSupportError } = useSupportActions()
	const {
		loading,
		error: requestError,
		success,
	} = useTypedSelector((state) => state.support)
	const { login } = useTypedSelector((state) => state)

	const [formData, setFormData] = useState<SupportFormData>({
		subject: '',
		section: '',
		description: '',
		ccEmails: [],
		attachments: [],
	})
	const [formErrors, setFormErrors] = useState<
		Partial<Record<keyof SupportFormData, string>>
	>({})
	const [showUploader, setShowUploader] = useState(false)
	const [submittedOnce, setSubmittedOnce] = useState(false)
	const [showSuccessSpinner, setShowSuccessSpinner] = useState(false)
	const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false)

	useEffect(() => {
		if (submittedOnce && success) {
			setDisplaySuccessMessage(true)
			setShowSuccessSpinner(true)
		} else {
			setDisplaySuccessMessage(false)
		}
	}, [success, submittedOnce])

	useEffect(() => {
		if (displaySuccessMessage) {
			const spinnerTimer = setTimeout(() => {
				setShowSuccessSpinner(false)
			}, 1000)

			const revertTimer = setTimeout(() => {
				handleUndo()
			}, 5000)

			return () => {
				clearTimeout(spinnerTimer)
				clearTimeout(revertTimer)
			}
		}
	}, [displaySuccessMessage])

	const handleUndo = () => {
		setDisplaySuccessMessage(false)
		setShowSuccessSpinner(false)
		setFormData({
			subject: '',
			section: '',
			description: '',
			ccEmails: [],
			attachments: [],
		})
		setFormErrors({})
		setSubmittedOnce(false)
	}

	const validateForm = () => {
		const errors: Partial<Record<keyof SupportFormData, string>> = {}
		const plainDescription = formData.description.replace(/<[^>]+>/g, '').trim()

		if (!formData.subject.trim()) {
			errors.subject = 'Subject is required.'
		} else if (formData.subject.trim().length < 3) {
			errors.subject = 'Subject must be at least 3 characters.'
		}

		if (!formData.section.trim()) {
			errors.section = 'Section is required.'
		}

		if (!plainDescription) {
			errors.description = 'Description is required.'
		} else if (plainDescription.length < 2) {
			errors.description = 'Description must be at least 2s characters.'
		}

		if (formData.ccEmails.length > 0) {
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
			const invalidEmails = formData.ccEmails.filter(
				(email) => !emailRegex.test(email.trim()),
			)
			if (invalidEmails.length > 0) {
				errors.ccEmails = `Invalid email(s): ${invalidEmails.join(', ')}`
			}
		}
		setFormErrors(errors)
		return Object.keys(errors).length === 0
	}

	const [canSubmit, setCanSubmit] = useState(false)
	useEffect(() => {
		const plainDescription = formData.description
			?.replace(/<[^>]+>/g, '')
			.trim()
		const subjectOk = formData.subject?.trim().length >= 3
		const sectionOk = formData.section?.trim().length > 0
		const descOk = plainDescription?.length >= 2
		setCanSubmit(subjectOk && sectionOk && descOk && !loading)
	}, [formData, loading])

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault()
		if (validateForm()) {
			setSubmittedOnce(true)

			// Generate placeholders
			const attachmentLinks = formData.attachments.map(
				(_, i) => `{{ATTACHMENT_URL_${i}}}`,
			)
			const attachmentNames = formData.attachments.map(
				(file, i) => `{{ATTACHMENT_NAME_${i}}}`,
			)

			const { subject, section, description, ccEmails, attachments } = formData

			createSupportTicket({
				subject,
				section,
				description,
				ccEmails: ccEmails.join(','),
				attachments,
			})
		}
	}

	const handleSubjectChange = (val: string) =>
		setFormData((prev) => ({ ...prev, subject: val }))
	const handleSectionChange = (val: string) =>
		setFormData((prev) => ({ ...prev, section: val }))
	const handleDescriptionChange = (val: string) =>
		setFormData((prev) => ({ ...prev, description: val }))
	const handleCCEmailsChange = (val: string[]) =>
		setFormData((prev) => ({ ...prev, ccEmails: val }))
	const handleFileUpload = (files: File[]) =>
		setFormData((prev) => ({
			...prev,
			attachments: [...prev.attachments, ...files],
		}))
	const removeFile = (index: number) => {
		const updated = formData.attachments.filter((_, i) => i !== index)
		setFormData((prev) => ({ ...prev, attachments: updated }))
	}
	const handleToggleUploader = () => setShowUploader(!showUploader)

	if (displaySuccessMessage) {
		return (
			<OuterContainer>
				<SuccessContainer>
					{/* Undo button in the top-right corner */}
					<IconButton
						onClick={handleUndo}
						sx={{
							position: 'absolute',
							top: 16,
							right: 16,
						}}
					>
						<ReplayIcon />
					</IconButton>

					{showSuccessSpinner ? (
						<>
							<Typography
								variant='h5'
								sx={{
									fontWeight: '500',
									display: 'flex',
									alignItems: 'center',
								}}
							>
								Submitting your ticket
								<AnimatedEllipsis>
									<span />
									<span />
									<span />
								</AnimatedEllipsis>
							</Typography>
						</>
					) : (
						<>
							{/* Success icon and messages */}
							<CheckCircleOutlineIcon
								sx={{
									fontSize: '4rem',
									color: theme.colors.base.green300,
								}}
							/>
							<Typography variant='h5' sx={{ fontWeight: 'medium' }}>
								Your support request has been submitted successfully!
							</Typography>
							<Typography
								variant='body1'
								sx={{
									maxWidth: '500px',
									color: 'text.secondary',
								}}
							>
								Our team will review your request and get back to you as soon as
								possible.
							</Typography>
						</>
					)}
				</SuccessContainer>
			</OuterContainer>
		)
	}

	return (
		<OuterContainer>
			<CenteredContainer>
				{/* Header */}
				<HeaderWrapper>
					<Typography
						variant='h3'
						sx={{
							fontWeight: theme.font.weight.bolder,
							fontSize: theme.font.size.mainTitles,
							color: theme.colors.text.titles,
							textAlign: 'center',
						}}
					>
						How can we help you?
					</Typography>
					<Typography
						variant='body1'
						sx={{
							fontSize: theme.font.size.body,
							color: theme.colors.text.general,
							lineHeight: 1.5,
							marginTop: theme.spacing(2),
							textAlign: 'center',
							maxWidth: '500px',
							margin: '0 auto',
						}}
					>
						Reach out to us with your queries, feedback, or concerns. Our
						support team is here to assist you every step of the way.
					</Typography>
				</HeaderWrapper>

				{/* Form */}
				<FormWrapper>
					<Typography
						variant='h4'
						sx={{
							fontWeight: theme.font.weight.mediumBold,
							marginBottom: '1rem',
							color: theme.colors.text.titles,
							textAlign: 'center',
						}}
					>
						Support Ticket
					</Typography>

					<SupportForm
						formData={formData}
						formErrors={formErrors}
						onSubjectChange={handleSubjectChange}
						onSectionChange={handleSectionChange}
						onDescriptionChange={handleDescriptionChange}
						onCCEmailsChange={handleCCEmailsChange}
						onFileUpload={handleFileUpload}
						onRemoveFile={removeFile}
						onToggleUploader={handleToggleUploader}
						onSubmit={handleSubmit}
						showUploader={showUploader}
						canSubmit={canSubmit}
						loading={loading}
						requestError={requestError}
					/>
				</FormWrapper>
			</CenteredContainer>
		</OuterContainer>
	)
}
export default SupportPage
