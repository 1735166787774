export const compareRevenuesByLayers = (
	revenueSaved: number,
	revenueInvoiced: number,
) => {
	const layers = [
		{ range: 10, value: -1 },
		{ range: 100, value: 5 },
		{ range: 500, value: [10, '2%'] },
		{ range: 1000, value: '1.5%' },
		{ range: 10000, value: '0.75%' },
		{ range: 50000, value: '0.5%' },
		{ range: 100000, value: '0.25%' },
		{ range: 500000, value: '0.1%' },
		{ range: Infinity, value: '0.05%' },
	]

	const absValue = Math.abs(revenueSaved - revenueInvoiced)

	for (const layer of layers) {
		if (revenueInvoiced <= layer.range) {
			let limit = layer.value
			if (Array.isArray(layer.value)) {
				let biggestLimit = 0
				for (const val of layer.value) {
					if (typeof val === 'string') {
						const percentage = Number(layer.value.slice(0, -1))
						const limitValue = (percentage * revenueInvoiced) / 100
						if (biggestLimit < limitValue) {
							biggestLimit = limitValue
						}
					} else {
						if (biggestLimit < val) {
							biggestLimit = val
						}
					}
				}
				limit = biggestLimit
			} else {
				if (typeof layer.value === 'string') {
					const percentage = Number(layer.value.slice(0, -1))
					limit = (percentage * revenueInvoiced) / 100
				}
			}
			if (limit === -1) {
				return false
			}
			return absValue >= (limit as number)
		}
	}
}
