import { Divider, Grid, MenuItem, Typography, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { PlusButton } from '../campaigns/CampaignStyled'
import { countryList } from './countryList'
import AddIcon from '@mui/icons-material/Add'
import { v4 as uuidv4 } from 'uuid'
import { useActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import React from 'react'
import {
	decryptIfNeeded,
	isEncrypted,
	isValidEmail,
} from '../../utils/helpers/helperFuncs'
import { InputFieldText } from './Inputs'
import { CountryListSelector, DropList } from './SelectableInputs'
import { SimpleActionsButton, UnborderedButton } from './Buttons'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
export interface ContactInterface {
	name: string
	role: string
	email: string
	phone: string
	skype: string
	geo: string[]
	type: string
	uuid: string
}
export const ContactsComponent = (props: {
	contacts: ContactInterface[]
	setContacts: any
	handleNewContact: any
	loginRole: string
	publisherName: any
	setErrorMessage: any
	setDeletedContactList?: any
	p360active: boolean
	owner: any
	ruleSet?: any
}) => {
	const theme = useTheme()
	const { login, settings } = useTypedSelector((state) => state)

	const mainContacts: any[] = []
	const financeContacts: any[] = []
	// const extraContacts: any[] = []
	props.contacts &&
		props.contacts.forEach((contact) => {
			if (contact.type === 'main' || contact.type === 'extra')
				mainContacts.push(contact)
			else if (contact.type === 'finance') financeContacts.push(contact)
		})

	// const headers = {
	// 	Authorization: `Token ${login.user.token}`,
	// }
	const ContactList =
		mainContacts !== undefined && mainContacts !== null ? (
			mainContacts.map((c, index) => (
				<ContactComponent
					key={c.uuid + index}
					contactNumber={index}
					allContacts={props.contacts}
					contact={c}
					setAllContacts={props.setContacts}
					type='main'
					loginRole={props.loginRole}
					setErrorMessage={props.setErrorMessage}
					publisherName={props.publisherName}
					setDeletedContactList={props.setDeletedContactList}
					p360active={props.p360active}
					settings={settings.settings}
					owner={props.owner}
					login={login.user.email}
					ruleSet={props.ruleSet}
					companyType={login.company.type}
				></ContactComponent>
			))
		) : (
			<React.Fragment key={'fragment-' + uuidv4()}></React.Fragment>
		)

	const FinanceContactList =
		financeContacts !== undefined && financeContacts !== null ? (
			financeContacts.map((c, index) => (
				<ContactComponent
					key={c.uuid + index}
					contactNumber={index}
					allContacts={props.contacts}
					contact={c}
					setAllContacts={props.setContacts}
					type='finance'
					loginRole={props.loginRole}
					setErrorMessage={props.setErrorMessage}
					publisherName={props.publisherName}
					setDeletedContactList={props.setDeletedContactList}
					p360active={false}
					settings={settings.settings}
					owner={props.owner}
					login={login.user.email}
					ruleSet={props.ruleSet}
					companyType={login.company.type}
				></ContactComponent>
			))
		) : (
			<React.Fragment key={'fragment-' + uuidv4()}></React.Fragment>
		)

	return (
		<Grid
			container
			columnSpacing={2}
			direction='column'
			style={{
				display: 'flex',
				columnGap: '3.75rem',
				rowGap: '1.2rem',
				marginTop: '2rem',
				marginLeft: 0,
			}}
		>
			<span
				style={{
					color: theme.colors.text.titles,
					fontSize: theme.font.size.buttons,
					marginInlineStart: '0',
				}}
			>
				Contact Details
			</span>

			{ContactList}

			{props.loginRole !== 'view' && (
				<div style={{ display: 'flex', justifyContent: 'flex-start' }}>
					{/* <PlusButton
						style={{ marginTop: '20px' }}
						onClick={() => props.handleNewContact('main')}
					>
						+
					</PlusButton>
					<Typography
						fontSize='11px'
						fontWeight='bold'
						style={{
							color: theme.colors.green,
							position: 'absolute',
							marginTop: '-20px',
							marginLeft: '60px',
						}}
					>
						Add Contact
					</Typography> */}
					<UnborderedButton
						onClick={() => props.handleNewContact('main')}
						icon={
							<AddIcon
								style={{
									border: '1px solid ' + theme.colors.base.grey900,
									borderRadius: '100px',
									width: '14px',
									height: '14px',
								}}
							/>
						}
						style={{
							color: theme.colors.text.titles,
							fontSize: theme.font.size.caption,
						}}
					>
						Add Contact
					</UnborderedButton>
				</div>
			)}
			<span
				style={{
					color: theme.colors.text.titles,
					fontSize: theme.font.size.buttons,
				}}
			>
				Finance
			</span>
			{/* <Divider
				style={{
					background:
						'linear-gradient(270deg, rgba(238,29,66,1) 0%, rgba(64,19,129,1) 100%)',
					height: '2px',
					width: '300px',
					marginBottom: '24px',
				}}
			/> */}
			{FinanceContactList}
			{props.loginRole !== 'view' && (
				<div style={{ display: 'flex', justifyContent: 'flex-start' }}>
					{/* <PlusButton
						style={{ marginTop: '20px' }}
						onClick={() => props.handleNewContact('finance')}
					>
						+
					</PlusButton>
					<Typography
						fontSize='11px'
						fontWeight='bold'
						style={{
							color: theme.colors.green,
							position: 'absolute',
							marginTop: '-20px',
							marginLeft: '60px',
						}}
					>
						Add Contact
					</Typography> */}
					<UnborderedButton
						onClick={() => props.handleNewContact('finance')}
						icon={
							<AddIcon
								style={{
									border: '1px solid ' + theme.colors.base.grey900,
									borderRadius: '100px',
									width: '14px',
									height: '14px',
								}}
							/>
						}
						style={{
							color: theme.colors.text.titles,
							fontSize: theme.font.size.caption,
						}}
					>
						Add Contact
					</UnborderedButton>
				</div>
			)}
		</Grid>
	)
}

export const ContactComponent = (props: {
	contactNumber: number
	contact: ContactInterface
	allContacts: ContactInterface[]
	setAllContacts: any
	type: string
	loginRole: string
	setErrorMessage: any
	publisherName: any
	setDeletedContactList?: any
	p360active: boolean
	settings: any
	login: any
	owner: any
	ruleSet: any
	companyType: string
}) => {
	const { updateAppP360, removeAppP360 } = useActions()
	const { contactNumber, contact, setAllContacts, allContacts, type } = props
	const [name, setName] = useState<string>(contact.name)
	const [role, setRole] = useState<string>(contact.role || '')
	const [phone, setPhone] = useState<string>(contact.phone)
	const [email, setEmail] = useState<string>(contact.email || '')
	const [skype, setSkype] = useState<string>(contact.skype)
	const [geo, setGeo] = useState<string[]>(contact.geo || [])
	const [num, setNum] = useState<number>(
		allContacts.findIndex((f: any) => f.uuid === contact.uuid),
	)

	useEffect(() => {
		allContacts.forEach((el: any) => {
			if (!el.uuid) {
				el.uuid = uuidv4()
			}
		})
		setNum(allContacts.findIndex((f: any) => f.uuid === contact.uuid))
	}, [allContacts])

	const trySubmit = () => {
		const update = {
			name,
			email,
			skype,
			geo,
			type,
			uuid: contact.uuid,
			role,
			phone,
		}
		// Create a new array with the updated contact
		const updatedContacts = allContacts.map((c, index) =>
			index === num ? { ...c, ...update } : c,
		)
		setAllContacts(updatedContacts)
	}
	useEffect(() => {
		trySubmit()
	}, [name, phone, skype, geo, role, email])

	// useEffect(() => {
	// 	console.log('geo in contact: ', geo)
	// 	const update = {
	// 		name,
	// 		email,
	// 		skype,
	// 		geo,
	// 		type,
	// 		uuid: contact.uuid,
	// 		role,
	// 		phone,
	// 	}
	// 	allContacts[num] = update
	// 	const updatedContacts = [...allContacts]
	// 	setAllContacts(updatedContacts)
	// }, [name, email, skype, geo, role, phone])

	const actionOptions = () => {
		return (
			<div
				style={{
					position: 'relative',
					top: '14px',
					fontSize: '12px',
					height: props.p360active ? '100px' : '60px',
				}}
			>
				{props.p360active && (
					<MenuItem
						onClick={() =>
							updateAppP360(
								{
									email,
									publisher: props.publisherName,
									userRole: props.loginRole,
								},
								props.setErrorMessage,
							)
						}
					>
						Add to P360
					</MenuItem>
				)}
				{props.p360active && (
					<MenuItem
						onClick={() =>
							removeAppP360(
								{
									email,
									publisher: props.publisherName,
									userRole: props.loginRole,
									direct: true,
								},
								props.setErrorMessage,
							)
						}
					>
						Remove from P360
					</MenuItem>
				)}

				<MenuItem onClick={() => handleDelete()}>Remove</MenuItem>
			</div>
		)
	}

	const handleDelete = () => {
		const updatedContacts = allContacts.filter((c: any, i: number) => i !== num)
		const deleted = allContacts.filter((c: any, i: number) => i === num)
		props.setDeletedContactList &&
			props.setDeletedContactList((prev: any) => {
				if (prev) return [...prev, deleted]
				else return [deleted]
			})
		setAllContacts(updatedContacts)
	}

	return (
		<Grid
			container
			columnSpacing={6}
			direction='row'
			columnGap={0}
			columns={12}
		>
			<Grid item xs={2}>
				<InputFieldText
					label='Name'
					type='text'
					value={name}
					onChange={setName}
					key={'name' + contactNumber + type}
					disabled={!props.ruleSet}
				/>
			</Grid>
			<Grid item xs={3}>
				<InputFieldText
					label='Email'
					type='email'
					value={email}
					onChange={setEmail}
					key={'email' + contactNumber + type}
					disabled={!props.ruleSet}
					warning={
						email
							? !isValidEmail(email)
								? "Email should contain '@'"
								: undefined
							: undefined
					}
					color={(email && !isValidEmail(email) && 'warning') || 'primary'}
				/>
			</Grid>
			{props.companyType === 'agency' ? (
				<Grid item xs={2}>
					<DropList
						onChange={(e: any) => setRole(e)}
						options={props.settings.contactRole}
						label={'Role'}
						value={role}
						disabled={!props.ruleSet}
					/>
				</Grid>
			) : (
				<Grid item xs={2}>
					<InputFieldText
						label='Role'
						type='text'
						value={role}
						onChange={(e: any) => setRole(e)}
						disabled={!props.ruleSet}
					/>
				</Grid>
			)}
			<Grid item xs={2}>
				<InputFieldText
					label='Skype'
					type='text'
					value={skype}
					onChange={setSkype}
					key={'skype' + contactNumber + type}
					disabled={!props.ruleSet}
				/>
			</Grid>
			<Grid item xs={2}>
				<CountryListSelector
					label='Geo'
					options={countryList}
					value={geo}
					singular
					onChange={(e: any) => setGeo(e)}
					disabled={!props.ruleSet}
				/>
			</Grid>
			<Grid
				item
				xs={1}
				style={{
					display: 'flex',
					alignItems: 'center',
					paddingTop: 20,
				}}
			>
				<SimpleActionsButton
					icon={<KeyboardArrowDownIcon />}
					options={actionOptions()}
				/>
			</Grid>
		</Grid>
	)
}
