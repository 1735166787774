import { Dispatch } from 'redux'
import { ActionType } from '../action-types'
import { Action } from '../actions/index'
import AxiosCustom from '../../utils/Axios'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { endpoints } from '../../config'

export const getNewsAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.NEWS, {
				headers: {
					'x-permission-name': permissionNames.VIEW_NEWS,
				},
			})
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GET_NEWS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			window.location.replace(window.location.origin)
		}
	}
}

export const postNewsAction = (obj: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const payload = {
				post: {
					id: 1,
					topics: obj.topics,
					deleted: obj.deleted,
				},
			}
			const { data } = await AxiosCustom.post(endpoints.NEWS, payload, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_NEWS,
				},
			})
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.POST_NEWS,
				payload: data.payload,
			})
			dispatch({
				type: ActionType.SET_SUCCESS,
				payload: {
					title: 'Upload News',
					message: 'News added successfully',
				},
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const postNewsImageAction = (payload: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			function base64Encode(str: any) {
				var CHARS =
					'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
				var out = '',
					i = 0,
					len = str.length,
					c1,
					c2,
					c3
				while (i < len) {
					c1 = str.charCodeAt(i++) & 0xff
					if (i == len) {
						out += CHARS.charAt(c1 >> 2)
						out += CHARS.charAt((c1 & 0x3) << 4)
						out += '=='
						break
					}
					c2 = str.charCodeAt(i++)
					if (i == len) {
						out += CHARS.charAt(c1 >> 2)
						out += CHARS.charAt(((c1 & 0x3) << 4) | ((c2 & 0xf0) >> 4))
						out += CHARS.charAt((c2 & 0xf) << 2)
						out += '='
						break
					}
					c3 = str.charCodeAt(i++)
					out += CHARS.charAt(c1 >> 2)
					out += CHARS.charAt(((c1 & 0x3) << 4) | ((c2 & 0xf0) >> 4))
					out += CHARS.charAt(((c2 & 0xf) << 2) | ((c3 & 0xc0) >> 6))
					out += CHARS.charAt(c3 & 0x3f)
				}
				return out
			}
			const obj = { post: payload }
			const { data } = await AxiosCustom.post(
				endpoints.NEWS_I,
				JSON.stringify(obj),
				{
					headers: {
						'x-permission-name': permissionNames.ADD_EDIT_NEWS,
					},
				},
			)
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.POST_IMAGE,
				payload: data.payload,
			})
			dispatch({
				type: ActionType.SET_SUCCESS,
				payload: {
					title: 'Upload News Image',
					message: 'Image Uploaded successfully',
				},
			})
		} catch (err) {
			console.error(err)
		}
	}
}
