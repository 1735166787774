import { Dispatch } from 'redux'
import { ActionType } from '../action-types'
import { Action } from '../actions/index'
import AxiosCustom from '../../utils/Axios'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { endpoints } from '../../config'

export const fetchRoles = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.GET_ROLES, {
				headers: {
					'x-permission-name': permissionNames.VIEW_SETTINGS, // TODO: MATAN -> need permissions for roles as well
				},
			})
			dispatch({
				type: ActionType.FETCH_ROLES,
				payload: data,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const updateRoles = (rolesArray: any[], setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.put(
				endpoints.UPDATE_ROLES,
				rolesArray,
				{
					headers: {
						'x-permission-name': permissionNames.ADD_EDIT_SETTINGS, // TODO: MATAN -> need permissions for roles as well
					},
				},
			)
			if (data && data.length > 0) {
				// console.log(data)
				dispatch({
					type: ActionType.FETCH_ROLES,
					payload: data,
				})
				dispatch({
					type: ActionType.SET_SUCCESS,
					payload: {
						title: 'Update Roles',
						message: 'Roles updated successfully',
					},
				})
			}
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const deleteRole = (roleId: number, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.delete(
				`${endpoints.DELETE_ROLE}/${roleId}`,
				{
					headers: {
						'x-permission-name': permissionNames.ADD_EDIT_SETTINGS, // TODO: MATAN -> need permissions for roles as well
					},
				},
			)
			dispatch({
				type: ActionType.FETCH_ROLES,
				payload: data,
			})
			dispatch({
				type: ActionType.SET_SUCCESS,
				payload: {
					title: 'Remove Role',
					message: 'Role removed successfully',
				},
			})
		} catch (err) {
			console.error(err)
			setErrorMessage('Failed to delete role')
		}
	}
}
