import { ActionType } from '../action-types'
import { Dispatch } from 'redux'
import { Action } from '../actions'

export const setError = (error: {
	statusCode: number
	errorCode: string
	message: string
}) => {
	return (dispatch: Dispatch<Action>) => {
		dispatch({
			type: ActionType.SET_ERROR,
			payload: error,
		})
	}
}

export const clearError = () => {
	return {
		type: ActionType.CLEAR_ERROR,
	}
}
