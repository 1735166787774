import localforage from 'localforage'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import advertiserReducer from './advertiserReducer'
import appReducer from './appReducer'
import appsflyerAccountsReducer from './appsflyerAccountsReducer'
import appsflyerReducer from './appsflyerReducer'
import autoswitchReducer from './autoswitchReducer'
import avatarsReducer from './avatarsReducer'
import campaignHistoryReducer from './campaignHistoryReducer'
import campaignReducer from './campaignReducer'
import dashboardReducer from './dashboardReducer'
import financeReducer from './financeReducer'
import learningReducer from './learningReducer'
import loginReducer from './loginReducer'
import logsReducer from './logsReducer'
import navigationReducer from './navigationReducer'
import networksReducer from './networksReducer'
import newsReducer from './newsReducer'
import p360reducer from './p360reducer'
import passwordsReducer from './passwordsReducer'
import presearchReducer from './presearchReducer'
import presetReducer from './presetReducer'
import publisherReducer from './publisherReducer'
import salesPlanReducer from './salesPlanReducer'
import settingsReducer from './settingsReducer'
import updateReducer from './updateReducer'
import usersReducer from './usersReducer'
import versionReducer from './versionReducer'
import publisherWishListReducer from './PublisherWishListReducer'
import creatorReducer from './creatorReducer'
import handshakeReducer from './handshakeReducer'
import reportReducer from './reportReducer'
import alertReducer from './alertReducer'
import permissionsReducer from './permissionsReducer'
import rolesReducer from './rolesReducer'
import companyReducer from './companyReducer'
import supportReducer from './supportReducer'
import notificationReducer from './notificationReducer'
import errorReducer from './errorReducer'
import successReducer from './successReducer'

const persistConfig = {
	key: 'root1',
	storage: localforage,
	whitelist: [
		'login',
		'users',
		'publisher',
		'advertiser',
		'campaign',
		'appsflyer',
		'app',
		'news',
		'navigation',
		'presearch',
		'logs',
		'preset',
		'networks',
		'storage',
		'update',
		'settings',
		'passwords',
		'dashboard',
		'campaignhistory',
		'finance',
		'autoswitch',
		'avatars',
		'accounts',
		'adjust',
		'version',
		'p360aggregated',
		'salesplan',
		'wishlist',
		'creator',
		'reports',
		'alerts',
		'permissions',
		'company',
		'support',
		'notifications',
	],
}

const rootReducer = combineReducers({
	login: loginReducer,
	users: usersReducer,
	advertiser: advertiserReducer,
	publisher: publisherReducer,
	campaign: campaignReducer,
	appsflyer: appsflyerReducer,
	app: appReducer,
	news: newsReducer,
	navigation: navigationReducer,
	presearch: presearchReducer,
	logs: logsReducer,
	preset: presetReducer,
	networks: networksReducer,
	update: updateReducer,
	settings: settingsReducer,
	passwords: passwordsReducer,
	learning: learningReducer,
	dashboard: dashboardReducer,
	accounts: appsflyerAccountsReducer,
	campaign_history: campaignHistoryReducer,
	finance: financeReducer,
	autoswitch: autoswitchReducer,
	avatars: avatarsReducer,
	version: versionReducer,
	p360aggregated: p360reducer,
	salesplan: salesPlanReducer,
	wishlist: publisherWishListReducer,
	creator: creatorReducer,
	handshake: handshakeReducer,
	reports: reportReducer,
	alerts: alertReducer,
	permissions: permissionsReducer,
	roles: rolesReducer,
	company: companyReducer,
	support: supportReducer,
	notifications: notificationReducer,
	errors: errorReducer,
	success: successReducer
})
const outReducer = persistReducer(persistConfig, rootReducer)
// export default rootReducer;
export default outReducer

export type RootState = ReturnType<typeof rootReducer>
