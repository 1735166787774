import { ActionType } from '../action-types';

export const setSuccess = (success: {
  title: string;
  message: string;
}) => {
  return {
    type: ActionType.SET_SUCCESS,
    payload: success,
  };
};

export const clearSuccess = () => {
  return {
    type: ActionType.CLEAR_SUCCESS,
  };
};
