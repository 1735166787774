import React, { useState, useEffect } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import {
	LocalizationProvider,
	StaticDateRangePicker,
	DateRangePickerDay,
} from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
	Box,
	Button,
	useTheme,
	IconButton,
	ClickAwayListener,
} from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { DateRange } from '@mui/x-date-pickers-pro/models'

import {
	CustomDatePickerInputBoxContainer,
	CustomInnerPlaceholderInputStyle,
	containerStyle,
	dropdownWrapperStyle,
} from './DateInputsStyles'
import { SubmitButton } from '../Buttons'
import { InfoOutlined } from '@mui/icons-material'

interface CustomDateRangePickerProps {
	startDate: Dayjs | null
	endDate: Dayjs | null
	onDateChange: (startDate: Dayjs | null, endDate: Dayjs | null) => void
	label?: string
	hint?: string
	mandatory?: boolean
}

const CustomDateRangePicker: React.FC<CustomDateRangePickerProps> = ({
	startDate,
	endDate,
	onDateChange,
	label,
	hint,
	mandatory,
}) => {
	const theme = useTheme()
	const [value, setValue] = useState<DateRange<Dayjs>>([startDate, endDate])
	const [open, setOpen] = useState(false)
	const [selectedShortcut, setSelectedShortcut] = useState<string | null>(null)
	const showStar = mandatory && (!startDate || !endDate)

	// Set today's date for limiting future date selection
	const today = dayjs()

	useEffect(() => {
		const handleClickOutside: any = (event: MouseEvent) => {
			const calendarElement = document.getElementById(
				'custom-date-range-picker',
			)
			const triggerElement = document.getElementById('date-picker-trigger')
			const target = event.target as Node

			// If the user clicked outside both the calendar and the trigger, close it
			if (
				calendarElement &&
				!calendarElement.contains(target) &&
				triggerElement &&
				!triggerElement.contains(target)
			) {
				setOpen(false)
			}
		}

		// Use 'click' instead of 'mousedown'
		if (open) {
			document.addEventListener('click', handleClickOutside)
		} else {
			document.removeEventListener('click', handleClickOutside)
		}

		return () => {
			document.removeEventListener('click', handleClickOutside)
		}
	}, [open])

	// Toggling on trigger click
	const handleClick = (event: React.MouseEvent) => {
		// Prevent any default or propagation
		event.stopPropagation()
		event.preventDefault()

		setOpen((prev) => !prev)
	}

	const handleDateChange = (newValue: DateRange<Dayjs>) => {
		setValue(newValue)
		onDateChange(newValue[0], newValue[1])
	}

	const handleShortcutClick = (
		label: string,
		getValue: () => DateRange<Dayjs>,
	) => {
		setSelectedShortcut(label)
		handleDateChange(getValue())
	}

	const shortcutsItems = [
		{
			label: 'This Week',
			getValue: () =>
				[dayjs().startOf('week'), dayjs().endOf('week')] as DateRange<Dayjs>,
		},
		{
			label: 'Last Week',
			getValue: () =>
				[
					dayjs().subtract(1, 'week').startOf('week'),
					dayjs().subtract(1, 'week').endOf('week'),
				] as DateRange<Dayjs>,
		},
		{
			label: 'Last 7 Days',
			getValue: () => [dayjs().subtract(7, 'day'), dayjs()] as DateRange<Dayjs>,
		},
		{
			label: 'Current Month',
			getValue: () => {
				const startOfMonth = dayjs().startOf('month')
				const today = dayjs()
				const endOfMonth = dayjs().endOf('month')

				// If today is earlier than the end of the month, cap the range at today's date
				return [
					startOfMonth,
					today.isBefore(endOfMonth) ? today : endOfMonth,
				] as DateRange<Dayjs>
			},
		},

		{
			label: 'Last Month',
			getValue: () =>
				[
					dayjs().subtract(1, 'month').startOf('month'),
					dayjs().subtract(1, 'month').endOf('month'),
				] as DateRange<Dayjs>,
		},
	]

	const handleReset = () => {
		handleDateChange([null, null])
		setSelectedShortcut(null)
	}

	const handleApply = () => {
		setOpen(false)
	}

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<Box style={containerStyle}>
				{label && (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							marginLeft: '0.8rem',
							color: 'red',
						}}
					>
						<label
							style={{
								fontSize: '0.8rem',
								color: theme.colors.text.grey,
								fontFamily: theme.typography.fontFamily,
							}}
						>
							{label}
							{showStar && (
								<span
									style={{
										color: 'red',
										fontSize: '1.1rem',
										marginLeft: '0.2rem',
										verticalAlign: 'middle',
										lineHeight: '1.2',
										position: 'relative',
										top: '0.1rem',
									}}
								>
									*
								</span>
							)}
						</label>
					</div>
				)}

				<CustomDatePickerInputBoxContainer onClick={handleClick}>
					<CalendarMonthIcon style={{ color: theme.colors.text.titles }} />
					{value[0] && value[1] ? (
						<span style={{ color: theme.colors.text.titles }}>
							{`${value[0]?.format('DD/MM/YYYY')} - ${value[1]?.format(
								'DD/MM/YYYY',
							)}`}
						</span>
					) : (
						<span style={CustomInnerPlaceholderInputStyle(theme)}>
							Select Date Range
						</span>
					)}
					<ArrowDropDownIcon />
				</CustomDatePickerInputBoxContainer>

				{open && (
					<ClickAwayListener onClickAway={() => setOpen(false)}>
						<Box id='custom-date-range-picker' sx={dropdownWrapperStyle(theme)}>
							<Box
								sx={{
									background: `${theme.colors.base.grey200}`,
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									padding: '4px 8px',
									borderBottom: `1px solid ${theme.colors.base.grey200}`,
									borderRadius: '4px 4px 0 0',
									marginBottom: '8px',
									width: '100%',
									maxWidth: '600px',
									margin: '0 auto',
								}}
							>
								{shortcutsItems.map((shortcut) => (
									<Button
										key={shortcut.label}
										onClick={() =>
											handleShortcutClick(shortcut.label, shortcut.getValue)
										}
										sx={{
											flex: 1,
											textTransform: 'none',
											color:
												selectedShortcut === shortcut.label
													? theme.palette.text.primary
													: theme.colors.base.grey500,
											borderBottom:
												selectedShortcut === shortcut.label
													? '2px solid black'
													: 'none',
											fontWeight:
												selectedShortcut === shortcut.label ? 'bold' : 'normal',
											padding: '6px',
											fontSize: theme.font.size.body,
											'&:not(:last-child)': {
												borderRight: `1px solid ${theme.palette.common.white}`,
											},
										}}
									>
										{shortcut.label}
									</Button>
								))}
							</Box>

							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									maxWidth: '600px',
									padding: '1rem 0',
									'& .MuiPickersCalendar-transitionContainer': {
										width: 'calc(50% - 10px)',
									},
								}}
							>
								<StaticDateRangePicker
									value={value}
									onChange={handleDateChange}
									calendars={2}
									displayStaticWrapperAs='desktop'
									maxDate={today} // Restrict future date selection
									minDate={dayjs().subtract(6, 'month').startOf('day')} // Restrict past date selection to 6 months ago
									renderInput={() => <></>}
									renderDay={(day, dateRangePickerDayProps) => (
										<DateRangePickerDay {...dateRangePickerDayProps} />
									)}
									components={{
										// Override the default left and right arrows
										LeftArrowButton: (props) => (
											<IconButton {...props}>
												<ChevronLeftIcon
													sx={{
														color:
															theme.palette.mode === 'dark'
																? theme.colors.white
																: theme.colors.black,
													}}
												/>
											</IconButton>
										),
										RightArrowButton: (props) => (
											<IconButton {...props}>
												<ChevronRightIcon
													sx={{
														color:
															theme.palette.mode === 'dark'
																? theme.colors.white
																: theme.colors.black,
													}}
												/>
											</IconButton>
										),
									}}
								/>
							</Box>

							<Box
								sx={{
									display: 'flex',
									justifyContent: 'flex-end',
									paddingTop: '8px',
									gap: '10px',
								}}
							>
								<SubmitButton noBackground onClick={handleReset}>
									Reset
								</SubmitButton>
								<SubmitButton onClick={handleApply}>Apply</SubmitButton>
							</Box>
						</Box>
					</ClickAwayListener>
				)}
			</Box>
		</LocalizationProvider>
	)
}

export default CustomDateRangePicker
