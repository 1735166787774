import { ActionType } from '../action-types'
import { Action } from '../actions'

interface SupportState {
	loading: boolean
	error: string | null
	success: boolean
}

const initialState: SupportState = {
	loading: false,
	error: null,
	success: false,
}

const supportReducer = (state = initialState, action: Action): SupportState => {
	switch (action.type) {
		case ActionType.CREATE_SUPPORT_TICKET_REQUEST:
			return { loading: true, error: null, success: false }
		case ActionType.CREATE_SUPPORT_TICKET_SUCCESS:
			return { loading: false, error: null, success: true }
		case ActionType.CREATE_SUPPORT_TICKET_ERROR:
			return { loading: false, error: action.payload, success: false }
		case ActionType.CLEAR_SUPPORT_ERROR:
			return { ...state, error: null }
		default:
			return state
	}
}

export default supportReducer
