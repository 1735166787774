import { ActionType } from '../action-types'
import { Action } from '../actions'

interface ErrorState {
	isError: boolean
	errorDetails: {
		statusCode: number
		errorCode: string
		message: string
		details?: any
	} | null
}

const initialState: ErrorState = {
	isError: false,
	errorDetails: null,
}

const errorReducer = (
	state: ErrorState = initialState,
	action: Action,
): ErrorState => {
	switch (action.type) {
		case ActionType.SET_ERROR: {
			return {
				...state,
				isError: true,
				errorDetails: action.payload,
			}
		}
		case ActionType.CLEAR_ERROR: {
			return {
				...state,
				isError: false,
				errorDetails: null,
			}
		}
		default:
			return state
	}
}

export default errorReducer
