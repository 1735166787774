import { ActionType } from '../action-types/index'
import { Action } from '../actions'
import { Notification } from '../../models/model.interface'

interface NotificationsState {
	notifications: Notification[]
	unread: number
}

const initialState: NotificationsState = {
	notifications: [],
	unread: 0,
}

const reducer = (
	state: NotificationsState = initialState,
	action: Action,
): NotificationsState => {
	switch (action.type) {
		case ActionType.GET_ALL_NOTIFICATIONS:
			return {
				...state,
				notifications: action.payload,
			}

		case ActionType.CREATE_NOTIFICATION:
			// Check if the notification already exists in the state
			const exists = state.notifications.some(
				(notification) => notification.id === action.payload.notification.id,
			)

			// Only add the notification if it doesn't already exist
			const updatedNotifications = exists
				? state.notifications
				: [action.payload.notification, ...state.notifications]

			return {
				...state,
				notifications: updatedNotifications,
				unread: action.payload.unread,
			}

		case ActionType.DELETE_NOTIFICATION:
			return {
				...state,
				notifications: state.notifications.filter(
					(notification) => !action.payload.includes(notification.id), 
				),
				unread: state.notifications.filter(
					(notification) =>
						!action.payload.includes(notification.id) && !notification.is_read,
				).length,
			}

		case ActionType.UPDATE_NOTIFICATION: {
			const updatedNotifications = state.notifications.map((notification) =>
				notification.id === action.payload.id ? action.payload : notification,
			)

			const unreadCount = updatedNotifications.filter(
				(notification) => !notification.is_read,
			).length

			return {
				...state,
				notifications: updatedNotifications,
				unread: unreadCount,
			}
		}

		case ActionType.GET_USER_NOTIFICATIONS:
			return {
				...state,
				notifications: action.payload.notifications,
				unread: action.payload.unread,
			}

		default:
			return state
	}
}

export default reducer
