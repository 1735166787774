import { Grid, useTheme } from '@mui/material'
import { useState } from 'react'
import React from 'react'
import { InputFieldText } from './Inputs'
import { UnborderedButton } from './Buttons'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import SimplePasswordsEdit from '../passwords/SimplePasswordsEdit'
import { canAccessResource } from '../../utils/helpers/helperFuncs'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useIsAuthorized } from '../../hooks/useIsAuthorized'
import { permissionNames } from '../../utils/helpers/permissionsHelper'

const canAccessPassword = (
	user: any,
	password: PasswordInterface,
	hasPermission: boolean,
) => {
	if (hasPermission) return true
	if (password.whiteList?.includes(user.name)) return true
	return false
}

export interface PasswordInterface {
	username: string
	url: string
	password: string
	whiteList: string[]
}

export const PasswordsComponent = (props: {
	passwords: PasswordInterface[]
	setPasswords: any
	handleNewPassword: any
	loginRole: string
	setErrorMessage: any
	setDeletedPasswordList?: any
	ownerEmail: string
	salesRep?: string
}) => {
	const { login, users } = useTypedSelector((state) => state)
	const [isEditVisible, setIsEditVisible] = useState(false)
	const [editPassword, setEditPassword] = useState<PasswordInterface | null>(
		null,
	)
	const [showFullPassword, setShowFullPassword] = useState<boolean[]>([])
	const theme = useTheme()
	const pubPasswordPermission = useIsAuthorized(
		permissionNames.VIEW_PUBLISHER_PASSWORDS,
	)
	const advPasswordsPermission = useIsAuthorized(
		permissionNames.VIEW_ADVERTISER_PASSWORDS,
	)
	const ownerUser = users.users.find((user: any) => {
		return user.email === props.ownerEmail
	})

	const canViewContactsAndPasswords =
		canAccessResource(login.user, props.ownerEmail, users, props.salesRep) ||
		pubPasswordPermission ||
		advPasswordsPermission

	const openEditModal = (password?: PasswordInterface) => {
		setEditPassword(
			password || { username: '', url: '', password: '', whiteList: [] },
		)
		setIsEditVisible(true)
	}

	const closeEditModal = () => {
		setIsEditVisible(false)
		setEditPassword(null)
	}

	const handleSavePassword = (newPassword: {
		username: string
		url: string
		password: string
		whiteList: string[]
	}) => {
		const updatedPasswords = [...props.passwords]
		if (editPassword) {
			const index = props.passwords.indexOf(editPassword)
			if (index > -1) {
				updatedPasswords[index] = newPassword
			} else {
				updatedPasswords.push(newPassword)
			}
		} else {
			updatedPasswords.push(newPassword)
		}
		props.setPasswords(updatedPasswords)
		setIsEditVisible(false)
	}

	const toggleShowPassword = (index: number) => {
		const updatedShowFullPassword = [...showFullPassword]
		updatedShowFullPassword[index] = !updatedShowFullPassword[index]
		setShowFullPassword(updatedShowFullPassword)
	}

	let PasswordList: any
	if (props.passwords) {
		PasswordList = props.passwords.map((p, index) => {
			const hasAccess = canAccessPassword(
				login.user,
				p,
				canViewContactsAndPasswords,
			)

			return (
				hasAccess && (
					<Grid
						key={p.url + index}
						container
						columnSpacing={6}
						direction='row'
						columns={12}
						style={{ alignItems: 'center' }}
					>
						<Grid item xs={3}>
							<InputFieldText label='URL' type='text' value={p.url} disabled />
						</Grid>
						<Grid item xs={3}>
							<InputFieldText
								label='Username'
								type='text'
								value={p.username}
								disabled
							/>
						</Grid>
						<Grid item xs={3}>
							<InputFieldText
								label='Password'
								type='password'
								placeholder='********'
								value={showFullPassword[index] ? p.password : '********'}
								onFocus={() => toggleShowPassword(index)}
								onBlur={() => toggleShowPassword(index)}
								disabled
							/>
						</Grid>
						<Grid
							item
							xs={3}
							style={{ display: 'flex', justifyContent: 'flex-start' }}
						>
							<UnborderedButton
								onClick={() => openEditModal(p)}
								style={{
									color: theme.colors.text.titles,
									fontSize: theme.font.size.caption,
								}}
								icon={<EditIcon style={{ width: '14px', height: '14px' }} />}
							>
								Edit
							</UnborderedButton>
							<UnborderedButton
								onClick={() => {
									const updatedPasswords = props.passwords.filter(
										(_, i) => i !== index,
									)
									props.setPasswords(updatedPasswords)
								}}
								style={{
									color: theme.colors.text.titles,
									fontSize: theme.font.size.caption,
								}}
								icon={<DeleteIcon style={{ width: '14px', height: '14px' }} />}
							>
								Delete
							</UnborderedButton>
						</Grid>
					</Grid>
				)
			)
		})
	} else {
		PasswordList = () => <></>
	}

	return (
		<>
			<Grid
				container
				columnSpacing={2}
				direction='column'
				style={{
					display: 'flex',
					columnGap: '3.75rem',
					rowGap: '1.2rem',
					marginTop: '2rem',
					marginLeft: 0,
				}}
			>
				<span
					style={{
						color: theme.colors.text.titles,
						fontSize: theme.font.size.body,
						marginInlineStart: '0',
					}}
				>
					Passwords
				</span>

				{PasswordList}

				<div style={{ display: 'flex', justifyContent: 'flex-start' }}>
					<UnborderedButton
						onClick={() => openEditModal()}
						icon={
							<AddIcon
								style={{
									border: '1px solid ' + theme.colors.base.grey900,
									borderRadius: '100px',
									width: '14px',
									height: '14px',
								}}
							/>
						}
						style={{
							color: theme.colors.text.titles,
							fontSize: theme.font.size.caption,
						}}
					>
						Add Password
					</UnborderedButton>
				</div>
			</Grid>

			{isEditVisible && (
				<SimplePasswordsEdit
					PopUpVisible={isEditVisible}
					setPopUpVisible={setIsEditVisible}
					row={editPassword}
					setLoading={() => {}}
					errorMessage={null}
					setErrorMessage={props.setErrorMessage}
					isAdding={!editPassword?.password}
					setIsAdding={() => {}}
					handleSave={handleSavePassword}
				/>
			)}
		</>
	)
}
