import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { RootState } from '../../../state/reducers'
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { useTheme } from '@mui/material'
import { Outlet, useNavigate } from 'react-router-dom'
import { Alert } from '../../../models/alert.interface'
import AlertComponent from './AlertComponent'
import {
	useAlertsActions,
	useNotificationActions,
} from '../../../hooks/useActions'

const AlertContainer = () => {
	const theme = useTheme()
	const state = useTypedSelector((state: RootState) => state.alerts)
	const {
		removeAlert,
		markAlertsAsSeen,
		closeAlert,
		openAlert,
		restoreAllAlerts,
	} = useAlertsActions()
	const { markAsRead } = useNotificationActions()
	const alerts = state.alerts
	const show = state.show
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [hover, setHover] = useState(false)
	const [bottomCss, setBottomCss] = useState('-25%')

	useEffect(() => {
		const unseenAlerts = alerts.filter((alert) => !alert.seen)
		if (unseenAlerts.length > 0) {
			dispatch(markAlertsAsSeen(unseenAlerts))
		}
	}, [alerts, dispatch])

	useEffect(() => {
		setBottomCss(show ? '10%' : '-40%')
		if (show) {
			dispatch(restoreAllAlerts()) // Restore all minimized alerts when opening the alert container
		}
	}, [show, dispatch])

	const handleClick = () => {
		if (show) {
			dispatch(closeAlert())
		} else {
			if (alerts.length > 0) {
				dispatch(openAlert())
			}
		}
	}

	const removeAlertHandler = (id: string) => {
		dispatch(removeAlert(id))
		markAsRead([id])
	}

	const handleLinkClick = (link: string) => {
		navigate(link)
	}

	return (
		<>
			<div style={{ width: '100%', height: '100%', zIndex: 1 }}>
				{show && (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '12px',
							position: 'fixed',
							bottom: bottomCss,
							right: '4%',
							zIndex: 18,
							transition: 'all 0.4s ease',
						}}
					>
						{alerts
							.slice(-4)
							.filter((alert) => !alert.minimized) // Render only non-minimized alerts
							.map((alert: Alert) => (
								<AlertComponent
									key={alert.id}
									alert={alert}
									removeAlert={removeAlertHandler}
									onLinkClick={handleLinkClick}
								/>
							))}
					</div>
				)}
				<div
					style={{
						display: 'flex',
						bottom: '4%',
						right: '4%',
						position: 'fixed',
						zIndex: 18,
					}}
				>
					<button
						style={{
							background:
								alerts.length === 0
									? theme.colors.alerts.grey250
									: theme.colors.alerts.grey50,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							boxShadow: '0px 2px 2px 1px ' + theme.colors.alerts.grey300,
							borderRadius: '30px',
							height: 40,
							width: 40,
							cursor: alerts.length === 0 ? 'not-allowed' : 'pointer',
							border: '1px solid ' + theme.colors.alerts.grey300,
						}}
						disabled={alerts.length === 0}
						onClick={handleClick}
						onMouseEnter={() => setHover(true)}
						onMouseLeave={() => setHover(false)}
					>
						{show ? (
							<CloseOutlinedIcon
								style={{
									color: hover
										? theme.colors.status.green
										: theme.colors.alerts.grey600,
									scale: '1.2',
								}}
							/>
						) : (
							<NotificationsNoneOutlinedIcon
								style={{
									color: hover
										? theme.colors.status.green
										: alerts.length > 0
										? theme.colors.alerts.grey600
										: theme.colors.alerts.grey300,
									scale: '1.2',
								}}
							/>
						)}
					</button>
				</div>
			</div>
			<Outlet />
		</>
	)
}

export default AlertContainer
