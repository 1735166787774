import React, { useState, useRef } from 'react'
import {
	Box,
	CircularProgress,
	styled,
	Button,
	Typography,
	IconButton,
	useTheme,
} from '@mui/material'
import { CloudUpload, HighlightOffOutlined } from '@mui/icons-material'
import { ACCEPTED_FILES } from '../../config'
import { SubmitButton, UnborderedButton } from './Buttons'

interface DropZoneProps {
	isDragging: boolean
}

const DropZone = styled('div', {
	shouldForwardProp: (prop) => prop !== 'isDragging',
})<DropZoneProps>(({ theme, isDragging }) => ({
	width: '100%',
	borderRadius: '5px',
	border: '2px dashed #9f9f9f',
	background: isDragging ? '#cacaca' : '#f4f3f9',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	color: 'rgba(128, 128, 128, 0.7)',
	transition: 'background 0.3s',
	padding: '2.5rem',
}))

const StyledImageContainer = styled('div')({
	margin: '10px',
	width: '100%',
	marginRight: '4px',
	position: 'relative',
	marginBottom: '8px',
	border: '1px solid #c6c5c5',
	borderRadius: '5px',
})

interface CustomFileUploaderProps {
	onSetFiles: (files: any[]) => void
	onRemoveFile?: (url: string, updatedFiles: any[]) => void
	onErrorMessage?: (message: string) => void
	onSuccess?: (filename: string) => void
	onError?: (filename: string) => void
	acceptedFiles?: string
}

const CustomFileUploader: React.FC<CustomFileUploaderProps> = ({
	onSetFiles,
	onRemoveFile,
	onErrorMessage,
	onSuccess,
	onError,
	acceptedFiles = ACCEPTED_FILES.DEFAULT,
}) => {
	const theme = useTheme()
	const [files, setFiles] = useState<any[]>([])
	const [isDragging, setIsDragging] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const fileInputRef = useRef<HTMLInputElement>(null)

	const handleFiles = (uploadedFiles: FileList) => {
		setIsLoading(true)
		const filesArray = Array.from(uploadedFiles)
		setFiles(filesArray)
		setIsLoading(false)
		onSetFiles(filesArray)
		if (filesArray.length > 0) {
			onSuccess?.(filesArray[0].name)
		}
	}

	const onFileSelect = (ev: React.ChangeEvent<HTMLInputElement>) => {
		const uploadedFiles = ev.target.files
		if (!uploadedFiles || uploadedFiles.length === 0) return
		handleFiles(uploadedFiles)
	}

	const onDrop = (ev: React.DragEvent<HTMLDivElement>) => {
		ev.preventDefault()
		setIsDragging(false)
		if (ev.dataTransfer.files && ev.dataTransfer.files.length > 0) {
			handleFiles(ev.dataTransfer.files)
		}
	}

	return (
		<DropZone
			onDragOver={(ev) => {
				ev.preventDefault()
				setIsDragging(true)
				ev.dataTransfer.dropEffect = 'copy'
			}}
			onDragLeave={(ev) => {
				ev.preventDefault()
				setIsDragging(false)
			}}
			onDrop={onDrop}
			isDragging={isDragging}
		>
			{files.map((file, index) => (
				<StyledImageContainer key={index}>
					<IconButton
						onClick={() => {
							const updatedFiles = files.filter((_, idx) => idx !== index)
							setFiles(updatedFiles)
							onSetFiles(updatedFiles)
						}}
					>
						<HighlightOffOutlined />
					</IconButton>
					<img
						src={URL.createObjectURL(file)}
						alt={file.name}
						style={{ width: '100%', height: '100%', objectFit: 'cover' }}
					/>
				</StyledImageContainer>
			))}

			{isLoading ? (
				<CircularProgress />
			) : (
				<>
					<CloudUpload
						sx={{
							fontSize: 60,
							animation: 'float 3s ease-in-out infinite',
							'@keyframes float': {
								'0%, 100%': { transform: 'translateY(0)' },
								'50%': { transform: 'translateY(-20px)' },
							},
							color: theme.colors.base.grey600,
						}}
					/>
					<Typography variant='h6'>Drag and drop files here</Typography>
					<div>
						<UnborderedButton
							style={{
								marginTop: '3rem',
								width: '79%',
								color: `${theme.colors.base.black}`,
							}}
							onClick={(e: any) => {
								e.preventDefault() // Prevent default behavior
								e.stopPropagation() // Stop event propagation to the form
								fileInputRef.current?.click() // Open the file selector
							}}
						>
							Browse
						</UnborderedButton>
					</div>
				</>
			)}
			<input
				type='file'
				ref={fileInputRef}
				onChange={onFileSelect}
				accept={acceptedFiles}
				style={{ display: 'none' }}
			/>
		</DropZone>
	)
}

export default CustomFileUploader
