import { Dispatch } from 'redux'
import { ActionType } from '../action-types'
import { Action } from '../actions/index'
import AxiosCustom from '../../utils/Axios'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { HTTP_STATUS_CODES, endpoints } from '../../config'

export const getLearningAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.DOCUMENTS, {
				headers: {
					'x-permission-name': permissionNames.VIEW_DOCUMENTS,
				},
			})
			// const learning = {
			// 	allowedPlatforms: JSON.parse(data.payload[0].allowedPlatforms),
			// 	vertical: JSON.parse(data.payload[0].vertical),
			// 	capabilities: JSON.parse(data.payload[0].capabilities),
			// 	salesRepresentative: JSON.parse(data.payload[0].salesRepresentative),
			// 	platforms: JSON.parse(data.payload[0].platforms),
			// 	contactRole: JSON.parse(data.payload[0].contactRole),
			// }
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GET_DOCUMENTS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const addLearningRow = (
	obj: any,
	setErrorMessage: any,
	setLoading: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const learning = {
				learning: obj,
			}
			const { data } = await AxiosCustom.post(endpoints.DOCUMENTS, learning, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_DOCUMENTS,
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				setLoading(false)
				return
			}
			dispatch({
				type: ActionType.SET_SUCCESS,
				payload: {
					title: 'Upload Document',
					message: 'Document added successfully',
				},
			})
			setLoading(false)
		} catch (err: any) {
			console.error(err)
			setLoading(false)
			if (
				err.response &&
				err.response.status === HTTP_STATUS_CODES.CONTENT_TOO_LARGE
			) {
				// Handle 413 error specifically
				setErrorMessage(
					'The file size is too large to upload. Please reduce the file size and try again.',
				)
			} else {
				// General error handling
				setErrorMessage('An unexpected error occurred. Please try again.')
			}
		}
	}
}

export const updateLearningRow = (
	obj: any,
	setErrorMessage: any,
	setLoading: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const learning = {
				learning: obj,
			}
			const { data } = await AxiosCustom.put(endpoints.DOCUMENTS, learning, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_DOCUMENTS,
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				setLoading(false)
				return
			}
			dispatch({
				type: ActionType.SET_SUCCESS,
				payload: {
					title: 'Update Document',
					message: 'Document updated successfully',
				},
			})
			setLoading(false)
		} catch (err: any) {
			console.error(err)
			setLoading(false)
			if (
				err.response &&
				err.response.status === HTTP_STATUS_CODES.CONTENT_TOO_LARGE
			) {
				// Handle 413 error specifically
				setErrorMessage(
					'The file size is too large to update. Please reduce the file size and try again.',
				)
			} else {
				// General error handling
				setErrorMessage('An unexpected error occurred. Please try again.')
			}
		}
	}
}

export const removeRow = (obj: any, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.delete(
				`${endpoints.DOCUMENTS}/${obj.id}`,
				{
					data: { learning: obj },
					headers: {
						'x-permission-name': permissionNames.ADD_EDIT_DOCUMENTS,
					},
				},
			)

			if (!data.successful) {
				setErrorMessage(data.payload || 'Failed to delete the document.')
				// Throw to indicate an error occurred
				throw new Error('Deletion failed on the backend.')
			}

			// Successfully deleted on backend; update Redux store
			dispatch({
				type: ActionType.SET_SUCCESS,
				payload: {
					title: 'Remove Document',
					message: 'Document removed successfully',
				},
			})

			dispatch({
				type: ActionType.REMOVE_DOCUMENTS,
				payload: obj,
			})

			// Return success if needed
			return true
		} catch (err) {
			console.error('Error removing document:', err)
			setErrorMessage('Failed to delete the document. Please try again.')
			// Return or throw error so caller knows it failed
			throw err
		}
	}
}
