import React, {
	ChangeEvent,
	Dispatch,
	SetStateAction,
	useEffect,
	useState,
} from 'react'
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import CloseIcon from '@mui/icons-material/Close'
import { PageWrapper, TableWrapper } from '../components/reusableComponents'
import { ThemeProvider } from '@emotion/react'
import { generateRowsWithIds, searchFor } from '../../utils/helpers/tableHelper'
import { GridColDef } from '@mui/x-data-grid-pro'

import {
	IconButton,
	MenuItem,
	Toolbar,
	Tooltip,
	Typography,
	useTheme,
	GlobalStyles,
} from '@mui/material'
import { LearningAdd } from './LearningAdd'
import { useLearningActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useNavigate, useParams } from 'react-router-dom'
import { getApplicableRules } from '../../utils/helpers/helperFuncs'
import FilterTabs from '../components/Filters'
import LinkIcon from '@mui/icons-material/Link'

import {
	SubmitButton,
	ActionsButton,
	UnborderedButton,
} from '../components/Buttons'
import { TableComponent } from '../components/TableComponents'
import { ReactComponent as EditIcon } from '../../assets/svg/edit-icon.svg'
import { ReactComponent as DeleteIcon } from '../../assets/svg/delete-icon.svg'
import { ReactComponent as DownloadIcon } from '../../assets/svg/download-icon.svg'
import { ReactComponent as PreviewIcon } from '../../assets/svg/preview-icon.svg'
import { useIsAuthorized } from '../../hooks/useIsAuthorized'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import DocumentPreview from '../components/DocumentPreview'

const LearningComponent = (props: {
	setLoading: any
	errorMessage: any
	setErrorMessage: any
	isErrorPromptOpened: any
	setIsErrorPromptOpened: any
	setIsWarningPromptOpened: any
	PopUpVisible: boolean
	setPopUpVisible: any
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	presetSearch: string
	setPresetSearch: Dispatch<SetStateAction<string>>
}) => {
	const theme = useTheme()
	const rowCount = 5
	const params = useParams()
	const navigate = useNavigate()
	const { getLearningAction, removeRow } = useLearningActions()
	const { learning, login, users, settings } = useTypedSelector(
		(state) => state,
	)
	const [pageSize, setPageSize] = useState(15)
	const [found, setFound] = useState<any[]>([])
	const [row, setRow] = useState()
	const [doc, setDoc] = useState<any>([])
	const [sortModel, setSortModel] = useState([
		{ field: 'file_title', sort: 'asc' },
	])
	const [rowWidth, setRowWidth] = useState(
		Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
	)

	const docCategories = [
		'Company Details',
		'Company Licenses',
		'HR',
		'Legal',
		'Operations',
		'Sales',
		'Varys',
		'Other',
	]

	const getDocsWithCategories = () => {
		let learnings = [...learning.learning]
		const learningsWithCategories = learnings.map((doc: any) => {
			const randomIndex = Math.floor(Math.random() * docCategories.length)
			doc.category = doc.category || docCategories[randomIndex]
			return doc
		})
		return learningsWithCategories
	}
	const [learnings, setItems] = useState(getDocsWithCategories())
	const [filteredLearning, setFilteredLearnings] = useState(
		getDocsWithCategories(),
	)
	const [filterKey, setFilterKey] = useState<string>('category')

	// const headers = {
	// 	Authorization: `Token ${login.user.token}`,
	// }

	const permissions = {
		edit: useIsAuthorized(permissionNames.ADD_EDIT_DOCUMENTS),
		view: useIsAuthorized(permissionNames.VIEW_DOCUMENTS),
		delete: useIsAuthorized(permissionNames.DELETE_DOCUMENTS),
	}

	// useEffect(() => {
	// 	loadCurrentData()
	// 	props.setLoading(false)
	// }, [])
	useEffect(() => {
		if (learning.learning.length > 0) {
			setFilteredLearnings(getDocsWithCategories())
			setItems(getDocsWithCategories())
		}
	}, [learning.learning])
	useEffect(() => {
		if (!props.PopUpVisible && params.id && learning.learning.length > 0) {
			const rowToCheck = learning.learning.filter((el: any) => {
				return Number(el.id) === Number(params.id)
			})
			handleEdit(rowToCheck[0])
		}
	}, [filteredLearning])

	const columns: GridColDef[] = [
		{
			field: 'file_title',
			headerName: 'Name',
			width: rowWidth * 1,
			disableColumnMenu: true,
			renderCell: ({ value }) => (
				<Typography sx={{ fontWeight: 600, fontSize: 14 }}>{value}</Typography>
			),
		},
		// {
		// 	field: 'file_open_link',
		// 	headerName: 'Open',
		// 	width: rowWidth * 0.4,
		// 	renderCell: ({ value }) => {
		// 		return (
		// 			value && (
		// 				<SubmitButton
		// 					onClick={() => {
		// 						setDoc([{ uri: value }])
		// 					}}
		// 					style={{ width: '60px', height: '26px' }}
		// 				>
		// 					<FileOpenIcon fontSize={'small'}></FileOpenIcon>
		// 				</SubmitButton>
		// 			)
		// 		)
		// 	},
		// },
		// {
		// 	field: 'file_link',
		// 	headerName: 'Drive link',
		// 	width: rowWidth * 0.5,
		// 	renderCell: ({ value }) => {
		// 		return (
		// 			<Typography sx={{ fontWeight: 600, fontSize: 14 }}>
		// 				<a href={value} rel='noreferrer noopener' target='_blank'>
		// 					Link
		// 				</a>
		// 			</Typography>
		// 		)
		// 	},
		// },
		{
			field: 'file_name',
			headerName: 'File name',
			width: rowWidth * 1.8,
			disableColumnMenu: true,
			renderCell: (row) => {
				const value = row.value
				const openLink = row.row.file_open_link
				const urlLink = row.row.file_link

				return (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-end',
						}}
					>
						<Typography sx={{ fontWeight: 600, fontSize: 14 }}>
							{value || 'No file'}
						</Typography>
						<div style={{ display: 'flex', marginLeft: 'auto' }}>
							{openLink && (
								<Tooltip title='Preview Document' placement='top'>
									<UnborderedButton
										onClick={() => {
											setDoc([{ uri: openLink }])
										}}
										style={{ transform: 'scale(0.8)', marginRight: '-0.7rem' }}
										icon={
											<PreviewIcon
												style={{
													color: theme.colors.text.titles,
													width: '20px',
													height: '20px',
												}}
												className={'action-button'}
											/>
										}
									/>
								</Tooltip>
							)}
							{urlLink && (
								<Tooltip title='Open URL' placement='top' arrow>
									<IconButton
										onClick={() => {
											window.open(urlLink, '_blank', 'noopener,noreferrer')
										}}
									>
										<LinkIcon
											style={{
												color: theme.colors.text.titles,
												width: '20px',
												height: '20px',
											}}
										/>
									</IconButton>
								</Tooltip>
							)}
						</div>
					</div>
				)
			},
		},

		{
			field: 'category',
			headerName: 'Category',
			width: rowWidth * 1,
			disableColumnMenu: true,
			renderCell: ({ value }) => (
				<Typography sx={{ fontWeight: 600, fontSize: 14 }}>
					{value || 'No category'}
				</Typography>
			),
		},
		(permissions.view as any) && {
			field: 'actions',
			headerName: 'Actions',
			resizable: false,
			headerAlign: 'center',
			align: 'center',
			disableColumnMenu: true,
			// width: rowWidth,
			sortable: false,
			renderCell: (row) => {
				return (
					<ActionsButton
						// label={'Actions'}
						// width={rowWidth}
						// row={row.row}
						options={actionOptions(row.row)}
						// style={{ width: '40px', height: '22px', fontSize: '30px' }}
						// arrowColor={'rgba(0,0,0,0.75)'}
					/>
				)
			},
		},
	]
	const actionOptions = (row: any) => {
		return [
			{
				label: '',
				icon: (
					<DownloadIcon
						style={{ color: theme.colors.text.titles, scale: '6.4' }}
						className={'action-button'}
					/>
				),
				onClick: () => {
					handleDownloadFile(row)
				},
				key: 'download',
				rule: permissions.view,
			},
			{
				label: '',
				icon: (
					<EditIcon
						style={{ color: theme.colors.text.titles, scale: '6.4' }}
						className={'action-button'}
					/>
				),
				onClick: () => {
					handleEdit(row)
				},
				key: 'edit',
				rule: permissions.edit,
			},
			{
				label: '',
				icon: (
					<DeleteIcon
						style={{ color: theme.colors.text.titles, scale: '6.4' }}
						className={'action-button'}
					/>
				),
				onClick: () => {
					handleRemove(row)
				},
				key: 'delete',
				rule: permissions.delete,
			},
		]
	}
	const handleDownloadFile = (rowToDownload: any) => {
		window.open(rowToDownload.file_open_link)
	}
	const handleEdit = (rowToEdit: any) => {
		setRow(rowToEdit)
		navigate('/documents/' + rowToEdit.id)
		props.setPopUpVisible(true)
	}
	const handleRemove = async (rowToRemove: any) => {
		try {
			// 1) Wait for removeRow to succeed or fail
			await removeRow(rowToRemove, props.setErrorMessage)

			// 2) If we get here, the backend confirmed deletion => now remove row from local state
			setItems((prevItems) =>
				prevItems.filter((item: any) => item.id !== rowToRemove.id),
			)
			setFilteredLearnings((prevItems) =>
				prevItems.filter((item: any) => item.id !== rowToRemove.id),
			)
		} catch (error) {
			// 3) If removeRow throws, we catch it here. Do NOT remove from UI in that case.
			console.error('Error in handleRemove:', error)
			// Optionally show an error dialog/snackbar. The setErrorMessage is already called, so you may not need more here.
		}
	}
	// const loadCurrentData = async () => {
	// 	getLearningAction()
	// }
	useEffect(() => {
		if (props.search.length > 0) {
			setFound(searchFor(props.search, filteredLearning, columns))
		}
	}, [props.search, filteredLearning])

	return !props.PopUpVisible ? (
		<ThemeProvider theme={theme}>
			{permissions.view && (
				<PageWrapper>
					{doc.length > 0 && (
						<div
							key={'testdocviewer'}
							style={{
								position: 'absolute',
								zIndex: 20,
								top: '10%',
								left: '2%',
								width: '95%',
								height: '80%',
								border: `2px solid ${theme.colors.base.grey300}`,
								backgroundColor: `${theme.colors.base.white}`,
								display: 'flex',
								flexDirection: 'column',
								borderRadius: '5px',
								boxSizing: 'border-box',
								overflow: 'hidden',
							}}
						>
							{/* Header / Close Button Section */}
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-end',
									backgroundColor: `${theme.colors.base.grey300}`,
									padding: '8px',
									borderRadius: '5px 5px 0 0',
									boxSizing: 'border-box',
								}}
							>
								<SubmitButton
									onClick={() => setDoc([])}
									style={{
										width: '20px',
										height: '20px',
										minWidth: '20px',
										minHeight: '20px',
										borderRadius: '10px',
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<CloseIcon
										fontSize='small'
										sx={{ color: `${theme.colors.base.black}` }}
									/>
								</SubmitButton>
							</div>

							{/* Content Area (scrollable) */}
							<div
								style={{
									flex: 1,
									overflow: 'auto',
									position: 'relative',
								}}
							>
								<DocumentPreview doc={doc} />
							</div>
						</div>
					)}

					<TableWrapper
						theme={theme}
						rowCount={rowCount}
						setRowWidth={setRowWidth}
						style={{ paddingBottom: '60px', width: '90%' }}
					>
						<TableComponent
							columns={columns}
							rows={generateRowsWithIds(
								props.search.length > 0 ? found : filteredLearning,
							)}
							setPageSize={setPageSize}
							pageSize={pageSize}
							rowsPerPageOptions={[15, 25, 50]}
							setLoading={props.setLoading}
							handlePopUp={
								permissions.edit
									? () => {
											setRow(undefined)
											props.setPopUpVisible(true)
									  }
									: undefined
							}
							sortModel={sortModel}
							onSortModelChange={(model: any) => setSortModel(model)}
						></TableComponent>
					</TableWrapper>
				</PageWrapper>
			)}
		</ThemeProvider>
	) : (
		<LearningAdd
			row={row}
			PopUpVisible={props.PopUpVisible}
			setPopUpVisible={props.setPopUpVisible}
			setLoading={props.setLoading}
			errorMessage={props.errorMessage}
			setErrorMessage={props.setErrorMessage}
			isErrorPromptOpened={props.isErrorPromptOpened}
			setIsErrorPromptOpened={props.setIsErrorPromptOpened}
			categoryOptions={docCategories}
		></LearningAdd>
	)
}

export default LearningComponent
