import { Grid, MenuItem } from '@mui/material'
import { InputFieldText } from '../components/Inputs'
import { StyledSelect } from './CampaignStyled'

export const dublicatedPidComponentsList = (
	pidList: Record<string, string[]>,
	handlePidResolver: (key: string, value: string) => void,
	pidConflictResolver: Record<string, string>,
) => {
	return Object.keys(pidList)
		.map((item, index) => {
			const publishers: string[] = pidList[item]

			const menuItems = publishers.map((pub: string) => (
				<MenuItem
					onClick={() => handlePidResolver(item, pub)}
					value={pub}
					key={pub}
				>
					{pub}
				</MenuItem>
			))

			return [
				<Grid item xs={12} key={index}>
					<Grid
						container
						style={{
							width: '100%',
							display: 'flex',
							marginBottom: '-1px',
							alignItems: 'flex-start',
						}}
					>
						<Grid item xs={5}>
							<InputFieldText label='' type={''} value={item} />
						</Grid>
						<Grid item xs={6} style={{ marginTop: '20px' }}>
							<StyledSelect value={pidConflictResolver[item] || ''}>
								{menuItems}
							</StyledSelect>
						</Grid>
					</Grid>
				</Grid>,
			]
		})
		.flat()
}

export const preparePrevState = (prevState: any) => {
	try {
		const { id, count, createdAt, status, platform, ...result } = prevState
		return result
	} catch (error) {
		return null
	}
}

export const prepareNewState = (prevState: any) => {
	const { id, user_login, ...result } = prevState
	return result
}

export const formUniqueDictionary = (
	campaigns: any[],
	uuidInUrl: string,
	duplicating: boolean,
) => {
	const foundCampaign = campaigns.find(
		(e: any) => e.uuid === uuidInUrl && e.role === 'M',
	)
	const foundCampaignsArray = campaigns.filter(
		(e: any) => e.campaign_name === foundCampaign.campaign_name,
	)

	let result: any = {}
	for (let e of foundCampaignsArray) {
		result[e.role] = {
			media_source_pid: e.media_source_pid,
			country: e.country,
			tracked_event: e.tracked_event,
			country_excluded: e.country_excluded,
			is_revenue_share: e.is_revenue_share,
			uuid: duplicating ? undefined : e.uuid,
			cost_from_data: e.cost_from_data,
		}
	}
	if (Object.keys(result).length === 0) {
		result['M'] = {
			media_source_pid: [],
			country: [''],
			tracked_event: [
				{
					order: 1,
					eventName: 'installs',
					defaultCost: 0,
					defaultRevenue: 0,
				},
			],
			uuid: undefined,
		}
	}
	return result
}

export const formUniqueDictionaryFromHistory = (
	campaignHistory: any[],
	date_added: any,
	duplicating: boolean,
) => {
	const foundCampaignsArray = campaignHistory.filter(
		(e: any) => e.date_added === date_added,
	)
	let result: any = {}
	for (let e of foundCampaignsArray) {
		result[e.role] = {
			media_source_pid: e.media_source_pid,
			country: e.country,
			tracked_event: e.tracked_event,
			is_revenue_share: e.is_revenue_share,
			uuid: duplicating ? undefined : e.uuid,
		}
	}
	if (Object.keys(result).length === 0) {
		result['M'] = {
			media_source_pid: [],
			country: [''],
			tracked_event: [
				{
					order: 1,
					eventName: 'installs',
					defaultCost: 0,
					defaultRevenue: 0,
				},
			],
			uuid: undefined,
		}
	}
	return result
}

export const updateCampaignName = (
	appName: string,
	geos: string[],
	campaignFlow: string,
	additionalTag: string,
) => {
	let name = appName || ''

	if (geos.length > 0 && !geos.includes('GLOBAL')) {
		name += ' - ' + geos.join(',')
	}

	if (campaignFlow) {
		name += ' - ' + campaignFlow
	}

	if (additionalTag) {
		name += ' - ' + additionalTag
	}

	return name
}

export const getCampaignStates = (foundCampaign: any, dict: any) => {
	if (!foundCampaign) return {}

	// Initialize variables
	let splitResult: string[] = []
	let campaignNameSplit: string[] = []
	let tag = ''

	if (foundCampaign.campaign_name) {
		splitResult = foundCampaign.campaign_name.split(' - ')
		campaignNameSplit = splitResult

		tag = ['CPI', 'CPA', 'CPC', 'CPM'].includes(
			splitResult[splitResult.length - 1]?.trim(),
		)
			? ''
			: splitResult[splitResult.length - 1]?.trim()
	}

	return {
		iconUrl: foundCampaign.icon || '',
		campaignOwner: foundCampaign.email,
		previewUrl: foundCampaign.preview_url,
		cap: foundCampaign.cap,
		status: foundCampaign.status,
		campaignName: foundCampaign.campaign_name,
		cParam: foundCampaign.c_parameter,
		isRevenueShare: foundCampaign.is_revenue_share,
		goal: foundCampaign.goal,
		targetAudience: foundCampaign.target_audience,
		kpi: foundCampaign.kpi,
		note: foundCampaign.note,
		vtaAllowed: foundCampaign.vta_allowed,
		retargeting: foundCampaign.retargeting,
		traffic: foundCampaign.traffic || [],
		probVta: foundCampaign.prob_vta,
		revenueNeeded: foundCampaign.revenue_needed,
		organicNeeded: foundCampaign.organic_needed,
		advfileLink: foundCampaign.adv_file_link,
		creativesLink: foundCampaign.creatives,
		timeFrame: foundCampaign.timeframe,
		endDate: foundCampaign.end_date,
		rawdata: foundCampaign.rawdata,
		budgetAmount: foundCampaign.budget_amount,
		fixPrice: foundCampaign.fix_price,
		createdBy: foundCampaign.created_by,
		fixPriceSelected: foundCampaign.fix_price,
		pidConflictResolver: foundCampaign.pidConflicts,
		privacyLevel: foundCampaign.privacy_level,
		advertiserName: foundCampaign.advertiser_name,
		appId: foundCampaign.app_id,
		incentive: foundCampaign.incentive,
		appName: campaignNameSplit[0] || '',
		campaignFlow:
			splitResult.length > 0 &&
			['CPI', 'CPA', 'CPC', 'CPM'].includes(
				splitResult[splitResult.length - 1]?.trim(),
			)
				? splitResult[splitResult.length - 1]?.trim()
				: splitResult[splitResult.length - 2]?.trim() || '',
		additionalTag: tag,
		networkAccount: foundCampaign.network_account,
		fraudTools: foundCampaign.fraud_tool,
		uniques: dict,
		eventNamesChosen:
			dict && dict['M']
				? Object.values(dict['M']).map((event: any) => event?.name)
				: [],
		historyUuid: undefined,
		dateAdded: undefined,
		isEditingHistory: false,
		gender: foundCampaign.gender,
		targetAudienceAgeFrom: foundCampaign.age_from,
		targetAudienceAgeTo: foundCampaign.age_to,
		usedExistingChannels: foundCampaign.channels || [],
		networkAccountList: foundCampaign.network_account || [],
		targetAudienceInterest: foundCampaign.interests || [],
		campaignGoals: foundCampaign.campaign_goals || [],
		targetAudienceOthers: foundCampaign.other_caricaturists || [],
		softKpi: foundCampaign.soft_kpi,
		hardKpi: foundCampaign.hard_kpi,
		eventsFlow: foundCampaign.campaign_flow,
		campaignRules: foundCampaign.campaign_rules
			? JSON.parse(foundCampaign.campaign_rules)
			: {},
		dummyPids: foundCampaign.dummy_pids,
	}
}
