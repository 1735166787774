import { TableCell, TableRow } from '@mui/material'
import React from 'react'
import {
	AdvertiserInterface,
	AppInterface,
	CampaignInterface,
	GetAppsflayerInterface,
	PublisherInterface,
	UserInterface,
} from '../../models/model.interface'
import { summedRowInterface } from '../../pages/dashboard/DashboardTable'
import { presetReducerInterface } from '../../state/reducers/presetReducer'

interface Props {
	data: SingleItem[]
}

export type SingleItem =
	| AdvertiserInterface
	| UserInterface
	| AppInterface
	| PublisherInterface
	| CampaignInterface
	| GetAppsflayerInterface
	| presetReducerInterface

const TableHelper: React.FC<Props> = ({ data }): any => {
	const rowHelper = (row: SingleItem) => {
		const arr: any = []
		Object.values(row).forEach((item: any, index) => {
			arr.push(
				<TableCell key={index} align='right'>
					{item}
				</TableCell>,
			)
		})
		return arr
	}

	const table = data.map((row: SingleItem, index) => {
		return (
			<TableRow
				key={index}
				sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
			>
				{rowHelper(row)}
			</TableRow>
		)
	})
	return <>{table}</>
}

export default TableHelper

const customColumnStyle = {
	color: 'white',
	backgroundColor: 'red',
	fontWeight: 'bold',
}
export const capitalizeFirstLetter = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1)
}
export const findUsernameByEmail = (email: string, users: UserInterface[]) => {
	let res = ''
	users.forEach((user) => {
		if (user.email === email) {
			res = user.name
		}
	})
	return res
}
export const cellGenerator = (tableHeaders: string[]) => {
	const generate = tableHeaders.map((item, index) => {
		return (
			<TableCell
				key={index}
				style={customColumnStyle}
				className='tableCell'
				align='right'
			>
				{item}
			</TableCell>
		)
	})
	return generate
}

export const generateRows = (data: SingleItem[]): SingleItem[] => {
	try {
		const rows: SingleItem[] = []
		data.forEach((item: SingleItem) => {
			rows.push({ ...item })
		})
		return rows
	} catch (err) {
		return []
	}
}
const trimString = (s: string) => {
	var l = 0,
		r = s.length - 1
	while (l < s.length && s[l] === ' ') {
		l++
	}
	while (r > l && s[r] === ' ') {
		r -= 1
	}
	return s.substring(l, r + 1)
}

const compareObjects = (o1: any, o2: any) => {
	var k = ''
	for (k in o1)
		if (o1[k] !== o2[k]) {
			return false
		}
	for (k in o2)
		if (o1[k] !== o2[k]) {
			return false
		}
	return true
}

export const itemExists = (haystack: any, needle: any) => {
	for (var i = 0; i < haystack.length; i++)
		if (compareObjects(haystack[i], needle)) {
			return true
		}
	return false
}

export const searchFor = (
	search: string | string[],
	objects: any[],
	columns: any[],
) => {
	const keysToSearch = []
	const keysToIgnore = [
		'actions',
		'country_resume',
		'system',
		'status',
		'count',
		'abilities_resume',
		'badges',
		'has_agent',
		'month',
		'report',
		'repeat',
		'timeframe',
	]
	for (const col of columns) {
		if (!keysToIgnore.includes(col.field)) {
			keysToSearch.push(col.field)
		}
	}
	var results = []
	if (!Array.isArray(search)) {
		const toSearch = trimString(search).toLowerCase() // trim it
		for (let i = 0; i < objects.length; i++) {
			for (const key of keysToSearch) {
				if (
					objects[i][key] &&
					objects[i][key].toString().toLowerCase().indexOf(toSearch) !== -1
				) {
					if (!itemExists(results, objects[i])) {
						results.push(objects[i])
						break
					}
				}
			}
		}
		return results
	} else {
		const keysToSearch = []
		for (const col of columns) {
			if (col.field !== 'actions') {
				keysToSearch.push(col.field)
			}
		}
		for (let i = 0; i < objects.length; i++) {
			for (const key of keysToSearch) {
				if (objects[i][key] && search.includes(objects[i][key].toString())) {
					if (!itemExists(results, objects[i])) {
						results.push(objects[i])
						break
					}
				}
			}
		}
		return results
	}
}

export const generateRowsWithIdsAndSort = (data: (SingleItem | any)[]) => {
	try {
		const parseId = (id: string | number | undefined): string => {
			if (typeof id === 'number') return id.toString()
			if (typeof id === 'string') return id
			return '' // Default if id is undefined or invalid
		}

		// Find the maximum existing numeric id in the dataset
		const maxExistingId = Math.max(
			...data.map((item) => {
				if ('id' in item && item.id !== undefined) {
					const parsed = parseFloat(parseId(item.id))
					return isNaN(parsed) ? 0 : parsed
				}
				return 0
			}),
			0,
		)

		let nextId = maxExistingId + 1

		const rowsWithUniqueIds = data.map((item, index) => {
			const existingId = 'id' in item ? parseId(item.id) : ''
			return {
				...item,
				id: existingId || (nextId++).toString(), // Normalize id to string
			}
		})

		// Sort rows by id in descending order (numerical comparison)
		const sortedRows = rowsWithUniqueIds.sort(
			(a, b) => parseFloat(b.id) - parseFloat(a.id),
		)

		return sortedRows
	} catch (err) {
		console.error('Error in generateRowsWithIds:', err)
		return []
	}
}

export const generateCampaignRowsWithIdsAndSort = (
	data: CampaignInterface[],
): CampaignInterface[] => {
	try {
		// Generate unique IDs for campaigns
		const rowsWithUniqueIds = data.map((item) => ({
			...item,
			id: `${item.id}-${item.uuid}`, 
		}))

		// Sort rows by `date_added` in descending order (newest first)
		const sortedRows = rowsWithUniqueIds.sort((a, b) => {
			const aDate = new Date(a.date_added).getTime() 
			const bDate = new Date(b.date_added).getTime() 
			return bDate - aDate // Descending order
		})

		return sortedRows
	} catch (err) {
		console.error('Error in generateCampaignRowsWithIdsAndSort:', err)
		return []
	}
}

export const generateRowsWithIds = (data: SingleItem[]) => {
	try {
		const rowsWithIds = data.map((item, index) => {
			return {
				id: index,
				...item,
			}
		})
		return rowsWithIds
	} catch (err) {
		return []
	}
}

export const generateRowsFromMap = (data: any, statistic?: any[]) => {
	try {
		const keys = Array.from(data.keys())
		const rowsWithIds = keys.map((item, index) => {
			return {
				id: index,
				...data.get(item),
			}
		})
		const rowsFromApps = rowsWithIds.map((v) => {
			const apps = Array.from(v.keys())
			return apps.map((app, index) => {
				return {
					id: index,
					...v.get(app),
				}
			})
		})

		return rowsWithIds
	} catch (err) {
		console.error(err)
		return []
	}
}
export function padTo2Digits(num: number) {
	return num.toString().padStart(2, '0')
}

export const arraysAreEqual = (a: any, b: any) => {
	if (a.length !== b.length) return false
	return a.every((val: any, index: number) => val === b[index])
}

export function formatDate(date: any) {
	let dateCopy = new Date(date)
	let offset = 0
	if (!date) {
		return new Date()
	}
	if (date && date.getUTCHours() > 12) {
		offset = 1
		dateCopy.setUTCDate(dateCopy.getUTCDate() + offset)
	}
	return [
		dateCopy.getUTCFullYear(),
		padTo2Digits(dateCopy.getUTCMonth() + 1),
		padTo2Digits(dateCopy.getUTCDate()),
	].join('-')
}
// export const shallowEqual = (object: any, new_object: any) => {
// 	let changedArray: any = []
// 	if (object !== null) {
// 		const keys = Object.keys(object)

// 		keys.forEach((k) =>
// 			object[k]?.toString() !== new_object[k]?.toString()
// 				? changedArray.push(k)
// 				: null,
// 		)
// 		return changedArray
// 	}
// 	const keys = Object.keys(new_object)
// 	keys.forEach((k) => changedArray.push(k))
// 	return changedArray
// }

export const shallowEqual = (
	object: any,
	new_object: any,
	//by default the function will work for not campaigns
	isCampaign: boolean = false,
) => {
	let changedArray: any = []
	//there are some Keys we use that change when the user updates the page but that we don't want to show in logs since it's only for us.
	let keysNotForUsers = [
		'daily_revenue',
		'monthly_revenue',
		'total_revenue',
		'used_publisher',
		'user_login',
		'past_owner',
		'created_by',
		'uuid',
		'timeframe',
		'end_date',
		'id',
	]
	if (isCampaign) {
		keysNotForUsers = [
			...keysNotForUsers,
			'allowed_platforms',
			'link_to_adv_file',
			'team',
			'advertiser_type',
			'communication_channel',
			'vertical',
			'date_added',
			'legal_name',
			'address',
			'geo',
			'contacts',
			'icon',
			'country',
			'media_source_pid',
			'tracked_event',
		]
	}
	//IF: it's a not a new thing (advertiser/campaign/publisher etc)
	if (object !== null) {
		// console.log(object, new_object)
		const keys = Object.keys(object)
		for (const key of keys) {
			if (keysNotForUsers.includes(key)) {
				//if keys included we just go ignore them
				continue
			}
			if (JSON.stringify(object[key]) !== JSON.stringify(new_object[key])) {
				let itemBefore = object[key]
				let itemAfter = new_object[key]
				//if key is media_buying_capabilities (from publishers)
				//if this is the case we don't need to show the data change but we need to show that SOMETHING happened.
				if (key === 'media_buying_capabilities') {
					for (const key in itemBefore) {
						//if the object has data saved it's going to be an array
						if (Array.isArray(itemBefore[key])) {
							//since we dont care about the order, we are just going to use the longest array to loop over it.
							const [longArr, shortArr] = getLongAndShortArray(
								itemBefore[key],
								itemAfter[key],
							)
							if (longArr.length !== shortArr.length) {
								//if the length is not the same, there was a change so we conclude we can push a log.
								changedArray.push(
									`Media buying capabilities - ${key} changed from no_logs_data to no_logs_data`,
								)
								break
							} else {
								for (const elementFromLong of longArr) {
									let elementIsSaved = false
									for (const elementFromShort of shortArr) {
										for (const elementKey in elementFromLong) {
											if (
												elementFromLong[elementKey] ===
												elementFromShort[elementKey]
											) {
												elementIsSaved = true
												break
											}
										}
									}
									if (elementIsSaved) {
										break
									} else {
										changedArray.push(
											`Media buying capabilities - ${key} changed from no_logs_data to no_logs_data`,
										)
										break
									}
								}
							}
							//we do this because SOME media_buying_capabilities are not an array.
						} else if (itemBefore[key] !== itemAfter[key]) {
							changedArray.push(
								`Media buying capabilities - ${key} changed from ${itemBefore[key]} to ${itemAfter[key]}`,
							)
						}
					}
					//this is the logic we will use for campaign uniques:
				} else if (key === 'uniques') {
					//we don't need the long and short but we can use the function to know the order:
					const [_1, _2, normalOrder] = getLongAndShortArray(
						Object.keys(itemAfter),
						Object.keys(itemBefore),
					)
					let longArr: any
					let shortArr: any
					if (normalOrder) {
						longArr = itemAfter
						shortArr = itemBefore
					} else {
						longArr = itemBefore
						shortArr = itemAfter
					}
					for (const role in longArr) {
						const roledLongArr = longArr[role]
						const roledShortArr = shortArr[role]
						//if one unique (Q1 for example) is registered in one Arr but not in the other, then there was a change.
						//since we are ordering the arrays, the one missing Q1 will always be the shortArr.
						if (roledLongArr && !roledShortArr) {
							const eventData = []
							//we get all the events
							for (const event of roledLongArr.tracked_event) {
								eventData.push(
									`Order: ${event.order}, Event Name: ${event.eventName}, Cost: ${event.defaultCost}, Revenue: ${event.defaultRevenue}`,
								)
							}
							//and we save. Based on the order, the new change.
							const data = `Country: ${roledLongArr.country}, is Revenue Share: ${roledLongArr.is_revenue_share}, Media Source PID: ${roledLongArr.media_source_pid}, Tracked Events: ${eventData}`
							if (normalOrder) {
								changedArray.push(
									`Uniques ${role} - ${key} changed from 'empty' to ${data}`,
								)
							} else {
								changedArray.push(
									`Uniques ${role} - ${key} changed from ${data} to 'empty'`,
								)
							}
						} else {
							//if both uniques exist, we need to compare them.
							for (const key in roledLongArr) {
								if (key === 'tracked_event') {
									//we need to find the long arr, the short one and the order to compare the tracked_events of both uniques.
									const [longArrInsde, shortArrInside, logicalOrder] =
										getLongAndShortArray(roledLongArr[key], roledShortArr[key])
									for (const event of longArrInsde) {
										for (const secondEvent of shortArrInside) {
											let differentEvent = false
											if (event.order === secondEvent.order) {
												for (const keys in event) {
													if (event[keys] !== secondEvent[keys]) {
														differentEvent = true
														const oldEvent = `Order: ${event.order}, Event Name: ${event.eventName}, Cost: ${event.defaultCost}, Revenue: ${event.defaultRevenue}`
														const newEvent = `Order: ${secondEvent.order}, Event Name: ${secondEvent.eventName}, Cost: ${secondEvent.defaultCost}, Revenue: ${secondEvent.defaultRevenue}`

														if (logicalOrder) {
															changedArray.push(
																`Uniques ${role} - ${key} changed from ${oldEvent} to ${newEvent}`,
															)
														} else {
															changedArray.push(
																`Uniques ${role} - ${key} changed from ${newEvent} to ${oldEvent}`,
															)
														}
														break
													}
												}
												if (differentEvent) {
													//since we are saving THE ENTIRE event, we don't want to save it one time for each different key so we just break the loop.
													break
												}
											}
										}
										// console.log(event, changedArray)
									}
									//some properties of the unique are arrays (countries) so we need to handle them
								} else if (Array.isArray(roledLongArr[key])) {
									if (!arraysAreEqual(roledLongArr[key], roledShortArr[key])) {
										changedArray.push(
											`Uniques ${role} - ${key} changed from ${roledLongArr[key]} to ${roledShortArr[key]}`,
										)
									}
								}
							}
						}
					}
					//contacts work similar than uniques but with the difference that they are actually an array.
					//the basic idea we are going to follow is comparing contacts and, if they are equal, we will remove them from the main array
					//then we will just check if something is left in that array. If that happens, it means that there was a difference (addition or remove)
				} else if (key === 'contacts') {
					// console.log(
					// 	'INSIDE SECOND CHECKER OF CONTACTS before:',
					// 	object[key],
					// 	'____AFTER:',
					// 	new_object[key],
					// )

					//first we are handeling what happens if before or after the update we have no contacts.
					if (object[key].length === 0 && new_object[key].length === 0) {
						continue
					} else if (object[key].length === 0 && new_object[key].length > 0) {
						let endData: any = []
						for (const contact of new_object[key]) {
							endData.push(
								`Name: ${contact.name}, Email: ${contact.email}, Role: ${contact.role}, Skype: ${contact.skype}, Type: ${contact.type}`,
							)
						}
						changedArray.push(`Contacts changed from empty to ${endData}`)
					} else if (new_object[key].length === 0 && object[key].length > 0) {
						let endData: any = []
						for (const contact of object[key]) {
							endData.push(
								`Name: ${contact.name}, Email: ${contact.email}, Role: ${contact.role}, Skype: ${contact.skype}, Type: ${contact.type}`,
							)
						}

						changedArray.push(`Contacts changed from ${endData} to empty`)
					} else {
						//if both previous and new state have contacts, we need to check them. First by getting the longest array and looping
						const [longArr, shortArr, sameOrder] = getLongAndShortArray(
							new_object[key],
							object[key],
						)
						// const shortCopy = [...shortArr]
						// const longCopy = [...longArr]
						// console.log(longCopy, shortCopy, sameOrder)
						for (let k = 0; k < longArr.length; k++) {
							const longContact = longArr[k]
							// let registered = false
							for (let i = 0; i < shortArr.length; i++) {
								const shortContact = shortArr[i]
								for (const key in longContact) {
									// console.log(
									// 	'key',
									// 	key,
									// 	'LONG',
									// 	longContact,
									// 	'SHORT',
									// 	shortContact,
									// 	key === 'geo' &&
									// 		!arraysAreEqual(shortContact[key], longContact[key]),
									// 	longContact[key] !== shortContact[key],
									// 	longContact[key].length !== 0 &&
									// 		shortContact[key].length !== 0,
									// )

									//when comparing 2 contacts, we need to compare the geo (an array) and the rest of the keys.
									if (
										(key === 'geo' &&
											!arraysAreEqual(shortContact[key], longContact[key]) &&
											longContact[key].length !== 0 &&
											shortContact[key].length !== 0) ||
										(key !== 'geo' && longContact[key] !== shortContact[key])
									) {
										// console.log(key, longContact[key], shortContact[key])

										//if the key is not equal we are going to save the log (based on the order of the long and short array)
										if (sameOrder) {
											const oldData = `Name: ${shortContact.name}, Email: ${shortContact.email}, Role: ${shortContact.role}, Skype: ${shortContact.skype}, Type: ${shortContact.type}`
											const newData = `Name: ${longContact.name}, Email: ${longContact.email}, Role: ${longContact.role}, Skype: ${longContact.skype}, Type: ${longContact.type}`

											changedArray.push(
												`Contacts changed from ${oldData} to ${newData}`,
											)
											// registered = true
											//SO, once we checked an element of the short array and we registered it, we dont want to check it again so we are deleting it.
											//on the other side, we want to delete the element from the longArr too because we want to have a tracking of what elements haven't been checked.
											// shortArr.splice(i, 1)
											// longArr.splice(i, 1)
											//we need to update k and i in order to update the loop. We also break here because we already registered THIS contact.
											// k--
											// i--
											break
										} else {
											//same logic than before but for the inverted order (when user removes a contact)
											const oldData = `Name: ${longContact.name}, Email: ${longContact.email}, Role: ${longContact.role}, Skype: ${longContact.skype}, Type: ${longContact.type}`
											const newData = `Name: ${shortContact.name}, Email: ${shortContact.email}, Role: ${shortContact.role}, Skype: ${shortContact.skype}, Type: ${shortContact.type}`

											changedArray.push(
												`Contacts changed from ${oldData} to ${newData}`,
											)
											// registered = true
											// shortArr.splice(i, 1)
											// longArr.splice(i, 1)
											// k--
											// i--
											break
										}
									}
								}

								// if (registered) {
								// 	longArr.splice(i, 1)
								// 	shortArr.splice(i, 1)
								// 	k--
								// 	i--
								// 	break
								// }
								//if we finish checking this contact, then we just update the arrays by removing the element we already checked.
								// const shortArrCopy = [...shortArr]
								// const longArrCopy = [...longArr]
								// console.log(longArrCopy, shortArrCopy)
								shortArr.shift()
								longArr.shift()
								i--
								k--
								break
							}
						}
						//AFTER looping over all the contacts, we are checking if there is any remaining contact in the long array. If so we are going to register it based on the order.
						if (longArr.length > 0) {
							for (const remainingContanct of longArr) {
								const data = `Name: ${remainingContanct.name}, Email: ${remainingContanct.email}, Role: ${remainingContanct.role}, Skype: ${remainingContanct.skype}, Type: ${remainingContanct.type}`
								if (sameOrder) {
									changedArray.push(`Contacts changed from empty to ${data}`)
								} else {
									changedArray.push(`Contacts changed from ${data} to empty`)
								}
							}
						}
					}
					// if (typeof itemBefore === 'object' && key === 'contacts') {
					// 	itemBefore = JSON.stringify(object[key])
					// }
					// if (typeof itemAfter === 'object' && key === 'contacts') {
					// 	itemAfter = JSON.stringify(new_object[key])

					// 	for(const contactKey of object[key])
					// 	if (itemBefore === itemAfter) {
					// 		continue
					// 	}
					// }

					//finally, for every other key we just use this approach:
				} else {
					//if items are empty then we will just update it to empty.
					if (!itemBefore || itemBefore.length === 0) {
						itemBefore = 'empty'
					}
					if (!itemAfter || itemAfter.length === 0) {
						itemAfter = 'empty'
						if (itemBefore === 'empty') {
							//if both are empty then we are not adding any log at all.
							continue
						}
					}
					let dataBefore = []
					let dataAfter = []
					try {
						//here we are going to TRY parsing. This will help with some small keys that have really small objects on them. Normally for advertiser.
						itemBefore = JSON.parse(itemBefore)
						for (const element of itemBefore) {
							dataBefore.push(`Value: ${element.value}`)
						}
						itemAfter = JSON.parse(itemAfter)
						for (const element of itemAfter) {
							dataAfter.push(`Value: ${element.value}`)
						}
					} catch (error) {
						//its empty catch, do not remove.
					}
					// console.log('WE ARE HERE', key, itemBefore, itemAfter)
					let endBeforeData: any
					let endAfterData: any
					//then we handle, again, the empty state just in case we were able to do json.
					if (dataBefore.length > 0) {
						endBeforeData = dataBefore
					} else {
						if (key === 'external_id') {
						}
						if (
							itemBefore.length > 0 ||
							(typeof itemBefore === 'number' &&
								itemBefore.toString().length > 0)
						) {
							endBeforeData = itemBefore
						} else {
							endBeforeData = 'empty'
						}
					}
					if (dataAfter.length > 0) {
						endAfterData = dataAfter
					} else {
						if (
							itemAfter.length > 0 ||
							(typeof itemAfter === 'number' && itemAfter.toString().length > 0)
						) {
							endAfterData = itemAfter
						} else {
							endAfterData = 'empty'
						}
					}
					//and we update the logs.
					// console.log(endAfterData, endBeforeData, itemBefore, itemAfter, key)
					//DO NOT REMOVE THE NEXT COMMENT LINE OR IT WILL BREAK THE LOGS WHEN SAVING THE FILE:
					// prettier-ignore
					changedArray.push(`${key} changed from ${endBeforeData} to ${endAfterData}`)
				}
			}
		}
		return changedArray
	}
	//IF the prev is not there it means it's a new object so we are just going to push every item into it.
	//we dont need the same kind of acuracy in this logs because as a user you are able to see in the real page or db what happened. So we just need to track it a bit.
	const keys = Object.keys(new_object)
	for (const key of keys) {
		if (keysNotForUsers.includes(key)) {
			continue
		}
		let itemLater = new_object[key]
		if (typeof itemLater === 'object' && key === 'contacts') {
			itemLater = JSON.stringify(new_object[key])
		}
		//DO NOT REMOVE THE NEXT COMMENT LINE OR IT WILL BREAK THE LOGS WHEN SAVING THE FILE:
		// prettier-ignore
		changedArray.push(`${key} changed from empty to ${itemLater}`)
	}

	return changedArray
}

export const checkInputStartsWithHttp = (inputValue: string) => {
	if (inputValue === '' || inputValue === undefined || inputValue === null) {
		return false
	}
	return (
		inputValue.startsWith('http://') ||
		inputValue.startsWith('https://') ||
		inputValue.startsWith('G:') ||
		inputValue.startsWith('g:')
	)
}

export const getLongAndShortArray = (arr1: any, arr2: any) => {
	if (arr1.length >= arr2.length) {
		return [arr1, arr2, true]
	} else {
		return [arr2, arr1, false]
	}
}

export const filterForDates = (
	nameField: string,
	spreadByDate: boolean,
	dictionary: any,
	datesArray: any[],
) => {
	let aggregatedResultsArray: any[] = []

	if (
		dictionary &&
		dictionary.filled &&
		dictionary.empty &&
		datesArray.length > 0
	) {
		//objects is an object of type {empty: empty DICTIONARY OF KEYS that are NAMEFIELD, filled: DICTIONARY of all data}
		const timeNow = Date.now()
		var results: any = dictionary.empty
		for (let name of Object.keys(results)) {
			datesArray.map((d) => (results[name][d] = dictionary.filled[name][d]))
		}
		if (spreadByDate === true) {
			for (let date of datesArray) {
				for (let res of Object.keys(results)) {
					const validFields = dictionary.filled[res][date]['valid']
					const invalidFields = dictionary.filled[res][date]['invalid']
					const aggregatedByName = summariseDictionaryField(
						validFields,
						false,
						nameField,
						false,
						invalidFields,
					)
					if (aggregatedByName) {
						aggregatedResultsArray.push({
							...aggregatedByName,
						})
					}
				}
			}
		} else {
			let temp_dictionary = Object.assign(
				{},
				...Object.keys(results).map((x: any) => ({
					[x]: [],
				})),
			)

			for (let date of datesArray) {
				for (let res of Object.keys(results)) {
					const validFields = dictionary.filled[res][date]['valid']
					const invalidFields = dictionary.filled[res][date]['invalid']
					const aggregatedByName = summariseDictionaryField(
						validFields,
						false,
						nameField,
						false,
						invalidFields,
					)

					if (aggregatedByName) {
						temp_dictionary[res].push({
							...aggregatedByName,
						})
					}
				}
			}
			for (let res of Object.keys(temp_dictionary)) {
				const aggregatedByDate = summariseDictionaryField(
					temp_dictionary[res],
					false,
					nameField,
					true,
				)
				if (aggregatedByDate) {
					aggregatedResultsArray.push(aggregatedByDate)
				}
			}
		}
	}

	return aggregatedResultsArray
}

export const summariseAggregatedDictionary = (
	objects: any[],
	nameField?: string,
	sum?: boolean,
	secondary?: boolean,
	filled?: any,
) => {
	const countryArray: any[] = []
	const advertiserArray: any[] = []
	const publisherArray: any[] = []
	const advertiserOwnderArray: any[] = []
	const publisherOwnderArray: any[] = []
	if (sum !== true) {
		if (objects && objects.length > 0) {
			const obj = Object.assign(
				{
					['invalidImpressions']: null,
					['invalidClicks']: null,
					['invalidInstalls']: null,
				},

				...Object.keys(objects[0]).map((x: any) => ({
					[x]: null,
				})),
			)

			const aggregatedRow = Object.keys(objects[0])
			if (objects[0].length > 0) {
				if (!secondary) {
					for (var i = 0; i <= objects[0].length; i++) {
						for (const key in obj) {
							if (key === 'country') {
								countryArray.push(objects[i][key])
							} else if (key == 'date' || key == 'campaign_name') {
								obj[key] = objects[i][key]
								obj[key] = objects[i][key]
							} else if (key == 'team' && objects[i][key] != null) {
								obj[key] = objects[i][key]
							} else if (
								key === 'publisher_name' &&
								!publisherArray.includes(objects[i][key])
							) {
								publisherArray.push(objects[i][key])
							} else if (
								key === 'advertiser_name' &&
								!advertiserArray.includes(objects[i][key])
							) {
								advertiserArray.push(objects[i][key])
							} else if (
								key === 'publisher_owner' &&
								!publisherOwnderArray.includes(objects[i][key])
							) {
								publisherOwnderArray.push(objects[i][key])
							} else if (
								key === 'advertiser_owner' &&
								!advertiserOwnderArray.includes(objects[i][key])
							) {
								advertiserOwnderArray.push(objects[i][key])
							} else if (
								key === 'invalidImpressions' ||
								key === 'invalidClicks' ||
								key === 'invalidInstalls'
							) {
								obj[key] = parseFloat(filled[key]?.toString() || '0')
							} else if (key === 'id') {
								obj[key] = i + key + 123
							} else if (
								key === 'advertiser_sales_rep' ||
								key === 'publisher_sales_rep'
							) {
								obj[key] = objects[i][key]
							} else {
								obj[key] =
									parseFloat(obj[key]?.toString() || '0') +
									parseFloat(objects[i][key]?.toString() || '0')
							}
						}
					}
					if (nameField === 'country') {
						obj['country'] = objects[0]['country']
					} else {
						obj['country'] = countryArray
						obj['advertiser_name'] = advertiserArray
						obj['publisher_name'] = publisherArray
						obj['advertiser_owner'] = advertiserOwnderArray
						obj['publisher_owner'] = publisherOwnderArray
					}
				} else {
					for (var i = 0; i <= objects.length; i++) {
						for (const key in objects[i]) {
							if (key === 'country') {
								for (let countries of objects[i][key]) {
									if (!countryArray.includes(countries)) {
										countryArray.push(countries)
									}
								}
							} else if (key == 'date' || key == 'campaign_name') {
								obj[key] = objects[i][key]
							} else if (key == 'team' && objects[i][key] != null) {
								obj[key] = objects[i][key]
							} else if (key === 'id') {
								obj[key] = i + key + 123
							} else if (
								key === 'advertiser_sales_rep' ||
								key === 'publisher_sales_rep'
							) {
								obj[key] = objects[i][key]
							} else if (key === 'publisher_name') {
								for (let pub of objects[i][key]) {
									if (!publisherArray.includes(pub)) {
										publisherArray.push(pub)
									}
								}
							} else if (key === 'advertiser_name') {
								for (let adv of objects[i][key]) {
									if (!advertiserArray.includes(adv)) {
										advertiserArray.push(adv)
									}
								}
							} else if (key === 'publisher_owner') {
								for (let po of objects[i][key]) {
									if (!publisherOwnderArray.includes(po)) {
										publisherOwnderArray.push(po)
									}
								}
							} else if (key === 'advertiser_owner') {
								for (let ao of objects[i][key]) {
									if (!advertiserOwnderArray.includes(ao)) {
										advertiserOwnderArray.push(ao)
									}
								}
							} else if (
								key === 'invalidImpressions' ||
								key === 'invalidClicks' ||
								key === 'invalidInstalls'
							) {
								obj[key] += parseFloat(objects[i][key]?.toString() || '0')
							} else {
								obj[key] =
									parseFloat(obj[key]?.toString() || '0') +
									parseFloat(objects[i][key]?.toString() || '0')
							}
						}
					}
					if (nameField === 'country') {
						obj['country'] = objects[0]['country']
					} else {
						obj['country'] = countryArray
						obj['advertiser_name'] = advertiserArray
						obj['publisher_name'] = publisherArray
						obj['advertiser_owner'] = advertiserOwnderArray
						obj['publisher_owner'] = publisherOwnderArray
					}
				}
			}

			return obj
		}
	} else {
		const summedRow: summedRowInterface = !sum
			? {
					clicks: 0,
					cost: 0,
					date: null,
					cr: 0,
					impressions: 0,
					installs: 0,
					invalidClicks: 0,
					invalidImpressions: 0,
					invalidInstalls: 0,
					invalidCr: 0,
					profit: 0,
					publisher_name: null,
					advertiser_name: null,
					campaign_name: null,
					country: [],
					revenue: 0,
					level_2_event: 0,
					level_3_event: 0,
					level_4_event: 0,
					level_5_event: 0,
			  }
			: {
					clicks: 0,
					cost: 0,
					date: null,
					cr: 0,
					id: '837f6g438fg43ffhdksjhfjhdfe-wefbwefjhewvfyewgfjewyvfuew',
					impressions: 0,
					installs: 0,
					invalidClicks: 0,
					invalidImpressions: 0,
					invalidInstalls: 0,
					invalidCr: 0,
					profit: 0,
					publisher_name: null,
					advertiser_name: null,
					campaign_name: null,
					country: null,
					revenue: 0,
					level_2_event: 0,
					level_3_event: 0,
					level_4_event: 0,
					level_5_event: 0,
			  }

		if (objects && objects.length) {
			for (var i = 0; i < objects.length; i++) {
				for (const key in objects[i]) {
					if (
						key === 'publisher_name' ||
						key === 'campaign_name' ||
						key === 'advertiser_name' ||
						key === 'advertiser_owner' ||
						key === 'publisher_owner'
					) {
						summedRow[key] = !sum ? objects[i][key] : 'SUM'
					} else if (key === 'date') {
						summedRow[key] = !sum ? objects[i][key] : 'SUM'
					} else if (key === 'id') {
						summedRow[key] = i + key + 123
					} else if (key === 'country') {
						countryArray.push(objects[i][key])
					} else {
						summedRow[key] =
							parseInt(summedRow[key]?.toString() || '0') +
							parseInt(objects[i][key])
					}
				}
			}
			summedRow['country'] = 'SUM'
		}
		return summedRow
	}
}

export const summariseDictionaryField = (
	field: any[],
	sum: boolean,
	nameField?: string,
	secondary?: boolean,
	invalidFields?: any,
) => {
	const countryArray: any[] = []
	const advertiserArray: any[] = []
	const publisherArray: any[] = []
	const advertiserOwnderArray: any[] = []
	const publisherOwnderArray: any[] = []
	if (field.length > 0) {
		const obj = !sum
			? Object.assign(
					{
						['invalidImpressions']: null,
						['invalidClicks']: null,
						['invalidInstalls']: null,
					},

					...Object.keys(field[0]).map((x: any) => ({
						[x]: null,
					})),
			  )
			: Object.assign(
					{
						['invalidImpressions']: null,
						['invalidClicks']: null,
						['invalidInstalls']: null,
						['id']: 'wefigwef834gfi',
					},

					...Object.keys(field[0]).map((x: any) => ({
						[x]: null,
					})),
			  )

		if (field.length > 0) {
			if (!secondary) {
				for (var i = 0; i < field.length; i++) {
					for (const key in obj) {
						switch (key) {
							case 'date':
							case 'team':
							case 'campaign_name':
								obj[key] = field[i][key]
								break
							case 'country':
								countryArray.push(field[i][key])
								break
							case 'publisher_name':
								publisherArray.push(field[i][key])
								break
							case 'advertiser_name':
								advertiserArray.push(field[i][key])
								break
							case 'publisher_owner':
								publisherOwnderArray.push(field[i][key])
								break
							case 'advertiser_owner':
								advertiserOwnderArray.push(field[i][key])
								break
							case 'invalidImpressions':
							case 'invalidClicks':
							case 'invalidInstalls':
								obj[key] = parseFloat(invalidFields[key]?.toString() || '0')
								break
							case 'id':
								obj[key] = i + key + 1233
								break
							case 'advertiser_sales_rep':
							case 'publisher_sales_rep':
								obj[key] = field[i][key]
								break
							case 'cost':
								obj[key] =
									parseFloat(obj[key]?.toString() || '0') +
									parseFloat(field[i][key]?.toString() || '0')
								break
							default:
								obj[key] =
									parseFloat(obj[key]?.toString() || '0') +
									parseFloat(field[i][key]?.toString() || '0')
								break
						}
					}
				}

				if (nameField === 'country') {
					obj['country'] = field[0]['country']
				} else {
					obj['country'] = filterUniques(countryArray)
					obj['advertiser_name'] = filterUniques(advertiserArray)
					obj['publisher_name'] = filterUniques(publisherArray)
					obj['advertiser_owner'] = filterUniques(advertiserOwnderArray)
					obj['publisher_owner'] = filterUniques(publisherOwnderArray)
				}
			} else {
				for (var i = 0; i < field.length; i++) {
					for (const key in obj) {
						switch (key) {
							case 'country':
								countryArray.push(field[i][key])

								break
							case 'date':
							case 'campaign_name':
							case 'team':
							case 'advertiser_sales_rep':
							case 'publisher_sales_rep':
							case 'advertiser_sales_rep':
							case 'publisher_sales_rep':
								obj[key] = field[i][key]
								break
							case 'publisher_name':
								publisherArray.push(field[i][key])
								break
							case 'advertiser_name':
								advertiserArray.push(field[i][key])
								break
							case 'publisher_owner':
								publisherOwnderArray.push(field[i][key])
								break
							case 'advertiser_owner':
								advertiserOwnderArray.push(field[i][key])
								break

							case 'id':
								obj[key] = i + key + 1233
								break
							case 'revenue':
							case 'cost':
							case 'profit':
								obj[key] =
									parseFloat(obj[key]?.toString() || '0') +
									parseFloat(field[i][key]?.toString() || '0')
								break
							default:
								obj[key] =
									parseFloat(obj[key]?.toString() || '0') +
									parseFloat(field[i][key]?.toString() || '0')
								break
						}
					}
				}

				switch (sum) {
					case false:
						if (nameField === 'country') {
							obj['country'] = field[0]['country']
						} else {
							obj['country'] = filterNestedUniques(countryArray)
							obj['advertiser_name'] = filterNestedUniques(advertiserArray)
							obj['publisher_name'] = filterNestedUniques(publisherArray)
							obj['advertiser_owner'] = filterNestedUniques(
								advertiserOwnderArray,
							)
							obj['publisher_owner'] = filterNestedUniques(publisherOwnderArray)
						}
						break
					default:
						obj['country'] = 'SUM'
						obj['date'] = 'SUM'
						obj['advertiser_name'] = ['SUM']
						obj['publisher_name'] = ['SUM']
						obj['advertiser_owner'] = ['SUM']
						obj['publisher_owner'] = ['SUM']
						obj['campaign_name'] = ['SUM']
						obj['team'] = ''
						break
				}
			}
		}
		return obj
	}
}
const filterUniques = (arr: any[]) => {
	const uniq: any[] = []
	let ind = 0
	for (let entry of arr) {
		if (!uniq.includes(entry)) {
			uniq.push(entry)
		}
	}

	// while (true) {
	// 	ind = binarySearch(arr, arr[ind], ind, arr.length - 1)
	// 	if (ind >= arr.length) {
	// 		break
	// 	}
	// 	uniq.push(arr[ind])
	// }
	return uniq
}

const filterNestedUniques = (arr: any[]) => {
	const uniq: any[] = []
	let ind = 0
	for (let entry of arr) {
		for (let nestedEntry of entry) {
			if (!uniq.includes(nestedEntry)) {
				uniq.push(nestedEntry)
			}
		}
	}

	// while (true) {
	// 	ind = binarySearch(arr, arr[ind], ind, arr.length - 1)
	// 	if (ind >= arr.length) {
	// 		break
	// 	}
	// 	uniq.push(arr[ind])
	// }
	return uniq
}

const binarySearch = (arr: any[], x: any, start: number, end: number): any => {
	if (start > end) {
		return false
	}
	let mid = Math.floor((start + end) / 2)
	if (arr[mid] === x) {
		return true
	}
	if (arr[mid] > x) {
		return binarySearch(arr, x, start, mid - 1)
	} else {
		return binarySearch(arr, x, mid + 1, end)
	}
}

export const sortFieldNames = (arr: string[]) => {
	const sortedFieldNames: string[] = []
	let element = arr.find((el) => el.toLowerCase() === 'date')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'month')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'day')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'day_of_the_week')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'country')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'app_id')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'os')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'app_name')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'app_token')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'partner')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'advertiser_name')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'advertiser_owner')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'campaign_name')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'c_parameter')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'media_source_pid')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'platform')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'agency')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'username')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'publisher_name')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'impressions')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'clicks')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'installs')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 're-attributions')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 're-engagements')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'total_revenue')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'level_2_event')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'level_3_event')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'level_4_event')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'level_5_event')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'revenue')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'cost')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'profit')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el.toLowerCase() === 'cr')
	element && sortedFieldNames.push(element)

	if (arr.length !== sortedFieldNames.length) {
		arr.forEach((el) => {
			if (!sortedFieldNames.includes(el)) {
				sortedFieldNames.push(el)
			}
		})
	}
	return sortedFieldNames
}

export const sortFieldNamesp360 = (arr: string[]) => {
	const sortedFieldNames: string[] = []
	let element: any = arr.find((el) => el === 'Date')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el === 'App ID')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el === 'Total fraudulent attribution')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el === 'Fraudulent attribution percent')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el === 'Fraud trend')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el === 'Real-time blocks')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el === 'Real-time blocks percent')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el === 'Post-attribution fraud')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el === 'Post-attribution fraud percent')
	element && sortedFieldNames.push(element)

	element = arr.find((el) => el === 'Installs')
	element && sortedFieldNames.push(element)

	return sortedFieldNames
}

export const generateCampaignRowsWithIds = (
	data: CampaignInterface[],
): CampaignInterface[] => {
	return data.map((item) => ({
		...item,
		id: `${item.id}-${item.uuid}`,
	}))
}

export const generateCampaignRowsFromMap = (data: Map<any, any>) => {
	try {
		const keys = Array.from(data.keys())
		const rowsWithIds = keys.map((key, index) => {
			const value = data.get(key)
			return {
				id: `${value.uuid}-${index}`, // Use the unique uuid combined with index
				...value,
			}
		})
		return rowsWithIds
	} catch (err) {
		console.error(err)
		return []
	}
}

export const generatePaginationOptions = (totalRows: number) => {
	const options: Array<number | { label: string; value: number }> = []
	const minStep = 5
	const maxOptions = 5 // Set a reasonable limit for the number of options
	const breakPoints = [25, 50, 100] // Commonly used pagination sizes

	// If totalRows is small, just return relevant options and 'All'
	if (totalRows <= minStep) {
		return [totalRows, { label: 'All', value: -1 }]
	}

	// Dynamically add breakpoints that make sense based on dataset size
	for (const breakPoint of breakPoints) {
		if (breakPoint < totalRows) {
			options.push(breakPoint)
		} else {
			break
		}
	}

	// Add a higher step based on dataset size for large datasets
	const half = Math.floor(totalRows / 2)
	if (
		half > (options[options.length - 1] as number) &&
		options.length < maxOptions
	) {
		options.push(half)
	}

	// Always include 'Show All'
	options.push({ label: 'All', value: -1 })

	return options
}
