import { Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { PlusButton } from '../campaigns/CampaignStyled'

const TextPiece = (props: {
	value: string
	boldValue: string
	editMode: boolean
	id: string
	handleDeleteTextElement: any
	titles: any
}) => {
	const theme = useTheme()
	const [value, setValue] = useState(props.value)
	const [boldValue, setBoldValue] = useState(props.boldValue)
	const [isEditing, setIsEditing] = useState(false)
	const [isBoldEditing, setIsBoldEditing] = useState(false)
	const handleInputChange = (e: any) => {
		const updatedObj = {
			value: value === props.value ? props.value : value,
			boldValue: boldValue === props.boldValue ? props.boldValue : boldValue,
		}
		if (isEditing) {
			updatedObj.value = e.target.value
			setValue(e.target.value)
		} else if (isBoldEditing) {
			updatedObj.boldValue = e.target.value
			setBoldValue(e.target.value)
		}
		props.titles.forEach((el: any) => {
			if (el.id === props.id) {
				el.value = updatedObj.value
				el.boldValue = updatedObj.boldValue
			}
		})
	}
	const handleEnter = (e: any) => {
		if (e.key === 'Enter') {
			setIsEditing(false)
			setIsBoldEditing(false)
		}
	}
	useEffect(() => {
		if (props.editMode === false && isEditing) {
			setIsEditing(false)
		}
	}, [props.editMode])

	return (
		<span style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
			{isBoldEditing ? (
				<input
					type='text'
					onChange={handleInputChange}
					onKeyDown={handleEnter}
					defaultValue={
						boldValue
							? boldValue !== ''
								? boldValue
								: 'Empty'
							: props.boldValue !== ''
							? props.boldValue
							: 'Empty'
					}
				/>
			) : props.editMode ? (
				<>
					<PlusButton
						style={{
							background: theme.colors.base.red300,
							margin: '0px',
							left: '-12%',
							top: '6px',
							marginTop: '-6px',
							width: '20px',
							height: '20px',
							paddingTop: '4px',
							marginBottom: '-16px',
							opacity: '0.5',
						}}
						onClick={() => props.handleDeleteTextElement(props.id)}
					>
						x
					</PlusButton>
					<Typography
						component={'span'}
						style={{ fontSize: '14px', fontWeight: '600', marginLeft: '-20px' }}
						onDoubleClick={() => setIsBoldEditing(true)}
						key={'boldvaluekeytext' + props.boldValue + props.id}
					>
						{boldValue
							? boldValue !== ''
								? boldValue
								: 'Empty'
							: props.boldValue !== ''
							? props.boldValue
							: 'Empty'}
					</Typography>
				</>
			) : (
				<Typography
					component={'span'}
					style={{ fontSize: '14px', fontWeight: '600' }}
					key={'boldvaluekeytext' + props.boldValue + props.id}
				>
					{props.boldValue}
				</Typography>
			)}
			{isEditing ? (
				<input
					type='text'
					onChange={handleInputChange}
					onKeyDown={handleEnter}
					defaultValue={
						value
							? value !== ''
								? value
								: 'Empty'
							: props.value !== ''
							? props.value
							: 'Empty'
					}
				/>
			) : props.editMode ? (
				<>
					<Typography
						component={'span'}
						style={{ fontSize: '14px' }}
						onDoubleClick={() => setIsEditing(true)}
						key={'valuekeytext' + props.value + props.id}
					>
						&nbsp;
						{value
							? value !== ''
								? value
								: 'Empty'
							: props.value !== ''
							? props.value
							: 'Empty'}
					</Typography>
				</>
			) : (
				<Typography
					component={'span'}
					style={{ fontSize: '14px' }}
					key={'valuekeytext' + props.value + props.id}
				>
					&nbsp;{props.value}
				</Typography>
			)}
		</span>
	)
}

export default TextPiece
