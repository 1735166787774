import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { UserNameWithAvatar } from './reusableComponents'
import { ReactComponent as NotificationsIcon } from '../../assets/svg/notifications-bell-icon.svg'


const UserInfoBar = (props: {
	handleAvatarClick: any
	avatarMenuIsSelected: any
}) => {
	const { handleAvatarClick, avatarMenuIsSelected } = props
	const navigate = useNavigate()
	const location = useLocation()

	// Redux selectors
	const unreadCount = useTypedSelector((state) => state.notifications.unread)
	const login = useTypedSelector((state) => state.login)
	const avatars = useTypedSelector((state) => state.avatars)

	// State to handle pulse animation
	const [isPulsing, setIsPulsing] = useState(false)

	// Trigger pulse animation on new notifications
	useEffect(() => {
		if (unreadCount > 0) {
			setIsPulsing(true)
			const timeout = setTimeout(() => setIsPulsing(false), 1000) // Pulse lasts 1 second
			return () => clearTimeout(timeout)
		}
	}, [unreadCount])

	// Handle navigation to notifications page
	const handleNavigate = () => {
		navigate('/notifications')
	}

	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				width: 250,
				gap: '10px', // Adds space between the avatar and notification icon
			}}
		>
			{/* Notifications Button */}
			<button
				style={{
					position: 'relative',
					display: 'flex',
					width: 'fit-content',
					height: 'fit-content',
					cursor: 'pointer',
					border: 'none',
					background: 'none',
					alignItems: 'center',
					transition: 'transform 0.2s ease',
				}}
				onClick={(e) => {
					if (e.currentTarget) {
						e.currentTarget.style.transform = 'scale(0.9)' // Click animation
						setTimeout(() => {
							if (e.currentTarget) {
								e.currentTarget.style.transform = 'scale(1)'
							}
						}, 150) // Reset after animation
						handleNavigate()
					}
				}}
				onMouseEnter={(e) => {
					if (e.currentTarget) {
						e.currentTarget.style.transform = 'scale(1.1)' // Hover effect
					}
				}}
				onMouseLeave={(e) => {
					if (e.currentTarget) {
						e.currentTarget.style.transform = 'scale(1)' // Reset hover effect
					}
				}}
			>
				{/* Notification Bell Icon */}
				<NotificationsIcon
					style={{
						fontSize: 28,
						color: 'rgba(255,255,255,1)',
						transform: `scale(${
							location.pathname === '/notifications' ? 1 : 1.1
						}) ${
							location.pathname === '/notifications' ? 'rotate(25deg)' : ''
						}`,
						transition:
							'transform 0.3s ease, filter 0.3s ease, text-shadow 0.3s ease',
						filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.3))', // Shadow following the bell shape
						transformOrigin: 'center', // Center rotation
						textShadow: '0px 0px 1px black', // Thin black outline effect
					}}
				/>

				{/* Red Badge for Unread Count */}
				{unreadCount > 0 && (
					<div
						style={{
							position: 'absolute',
							top: '-4px',
							right: '0px',
							backgroundColor: 'red',
							color: 'white',
							width: '18px',
							height: '18px',
							borderRadius: '50%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							fontSize: '10px',
							fontWeight: 'bold',
							boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
							transform: isPulsing ? 'scale(1.2)' : 'scale(1)',
							transition: 'transform 0.3s ease',
						}}
					>
						{unreadCount}
					</div>
				)}
			</button>

			{/* User Avatar and Name */}
			{login.user.name && (
				<UserNameWithAvatar
					data-tag={'_navbarButton'}
					avatars={avatars.avatars}
					value={login.user.name}
					position={login.user.position}
					country={login.user.location}
					onClick={handleAvatarClick}
					selected={avatarMenuIsSelected}
				/>
			)}
		</div>
	)
}

export default UserInfoBar
