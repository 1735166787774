import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
	Autocomplete,
	Box,
	Checkbox,
	FormControl,
	FormControlProps,
	Grid,
	LinearProgress,
	MenuItem,
	styled,
	TextField,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material'
import { objectShallowCompare } from '@mui/x-data-grid/hooks/utils/useGridSelector'
import React, { useEffect, useState } from 'react'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import {
	AdvertiserInterface,
	AppInterface,
	PublisherInterface,
	TrackedEvent,
} from '../../models/model.interface'
import { EventNameAutocomplete } from '../components/reusableComponents'
import { StyledCheckboxFormControl } from '../reports/aggregated/FiltersStyled'
import {
	EventWrapper,
	PlusButton,
	StyledInputEvent,
	StyledInputLabel,
	StyledInputLabelEvent,
	StyledItem,
	StyledItemLabels,
	StyledSelect,
} from './CampaignStyled'
import { getApplicableRules } from '../../utils/helpers/helperFuncs'
import { UnborderedButton } from '../components/Buttons'
import AddIcon from '@mui/icons-material/Add'
import { useIsAuthorized } from '../../hooks/useIsAuthorized'
import { permissionNames } from '../../utils/helpers/permissionsHelper'

interface TabPanelProps {
	children?: React.ReactNode
	index: number
	value: number
}

const StyledFormControl = (
	props: FormControlProps & { children: any; fullWidth?: boolean },
) => {
	const StyledForm = styled(FormControl)(({ theme }) => ({
		width: props.fullWidth ? '100%' : '45%',
		marginTop: '20px',
	}))
	return (
		<StyledForm focused {...props}>
			{props.children}
		</StyledForm>
	)
}
export function isUuid(data: any) {
	const regexExp =
		/^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
	return regexExp.test(data)
}
export const AdvertiserList = (props: {
	value: string | undefined
	onChange: any
	disabled?: any
	style?: any
}) => {
	const { advertiser } = useTypedSelector((state) => state)

	const test_adv = ['1', '2', '3']
	const advertiserList: JSX.Element[] =
		advertiser.advertiser.length > 0
			? advertiser.advertiser
					.sort((adv1: any, adv2: any) =>
						adv1.advertiser_name.localeCompare(adv2.advertiser_name),
					)
					.map((adv: AdvertiserInterface, key: number) => (
						<MenuItem key={key} value={adv.advertiser_name}>
							{adv.advertiser_name}
						</MenuItem>
					))
			: test_adv.map((adv: any, key: number) => (
					<MenuItem key={key} value={adv}>
						{adv}
					</MenuItem>
			  ))
	return (
		<div>
			<StyledInputLabel>Advertiser</StyledInputLabel>
			<StyledSelect
				value={props.value || ''}
				onChange={props.onChange}
				IconComponent={KeyboardArrowDownIcon}
				disabled={props.disabled}
			>
				{advertiserList}
			</StyledSelect>
		</div>
	)
}

export const PublishersList = (props: {
	value: string | null
	onChange?: any
	disabled?: any
}) => {
	const { publisher } = useTypedSelector((state) => state)
	const publisherList: JSX.Element[] = publisher.publisher.map(
		(adv: PublisherInterface, key: number) => (
			<MenuItem key={key} value={adv.publisher_name}>
				{adv.publisher_name}
			</MenuItem>
		),
	)

	return (
		<StyledFormControl
			disabled={props.disabled}
			focused
			//style={{ float: 'inline-start', marginTop: '0px' }}
			variant='outlined'
		>
			<StyledSelect
				value={props.value || ''}
				onChange={props.onChange}
				IconComponent={KeyboardArrowDownIcon}
			>
				{publisherList}
			</StyledSelect>
		</StyledFormControl>
	)
}

export const CostOptions = (props: {
	fixPriceSelected: boolean
	setFixPriceSelected: any
	// setIsEditing: any
	// handleChangeField: any
	fixPrice: string | null
	setFixPrice: any
	pointer: number
	isRevenueShare: boolean
	setIsRevenueShare: any
	role: any
	disabled: boolean
	campaignObject: any
}) => {
	return (
		<Grid container direction={'row'}>
			{/* <Grid
				item
				xs={3}
				style={{ width: '70vw', marginTop: '22px', height: '60px' }}
			>
				{/* <StyledCheckboxFormControl
					key={'fixPriceCheckBox' + props.pointer.toString()}
					control={
						<Checkbox
							onChange={() => {
								props.setFixPriceSelected(!props.fixPriceSelected)
								// props.handleChangeField(
								// 	'',
								// 	props.pointer,
								// 	'fix_price',
								// 	props.setFixPrice,
								// )
								// props.setIsEditing(true)
							}}
							checked={props.fixPriceSelected}
							key={'fixPrice' + props.pointer.toString()}
							inputProps={{
								'aria-label': 'controlled',
							}}
							style={{ marginLeft: '25px', color: '#ee1d4a' }}
							disabled={true}
						/>
					}
					label={
						<Typography fontSize='12px' fontWeight={500} color={'secondary'}>
							Fix Price
						</Typography>
					}
				/>
			</Grid> */}
			{/* <Grid item xs={2} style={{ width: '70vw', marginLeft: '-60px' }}>
				{props.fixPriceSelected ? (
					<StyledNameField
						label='Cost'
						type='number'
						keyname='costKeyTest'
						value={props.fixPrice}
						onChange={(e: any) => {}}
					/>
				) : (
					<></>
				)}
			</Grid> */}
			<Grid
				item
				xs={12}
				style={{
					width: '100%',
					marginTop: '22px',
					height: '60px',
				}}
			>
				<StyledCheckboxFormControl
					key={'RevenueShareCheckBox' + props.pointer.toString()}
					control={
						<Checkbox
							onChange={(e) => {
								props.campaignObject['uniques'][props.role].is_revenue_share =
									!props.campaignObject['uniques'][props.role].is_revenue_share
								props.setIsRevenueShare(!props.isRevenueShare)
							}}
							checked={
								props.campaignObject['uniques'][props.role].is_revenue_share
							}
							key={'isRevenueShareOption' + props.pointer.toString()}
							inputProps={{
								'aria-label': 'controlled',
							}}
							style={{ color: '#ee1d4a' }}
							disabled={props.disabled}
						/>
					}
					label={
						<Typography fontSize='12px' fontWeight={500} color={'secondary'}>
							Revenue Share
						</Typography>
					}
				/>
			</Grid>
		</Grid>
	)
}

export const EventForm = (props: {
	value: any
	onChange: any
	handleEventAddition: any
	setTrackedEventChanged: any
	trackedEventChanged: string[]
	role: any
	campaignObject: any
	available: boolean
	options: string[]
	setUniques: any
	pointer?: any
	isRevenueShare: boolean
	loginRole: string
	eventNamesChosen: any
	setEventNamesChosen: any

	externalFunction: any
	listOfHooks: any
	isCostFromDate: any
	companyId: number
}) => {
	const theme = useTheme()
	const { users, login, settings } = useTypedSelector((state) => state)

	const sameCompanyId = props.companyId === login.company.id
	const permissions = {
		edit: useIsAuthorized(permissionNames.ADD_EDIT_CAMPAIGN) && sameCompanyId,
		preview: useIsAuthorized(permissionNames.PREVIEW_CAMPAIGNS),
		editOtherParams:
			useIsAuthorized(permissionNames.EDIT_OTHER_PARAMETERS) && sameCompanyId,
		editMainParams:
			useIsAuthorized(permissionNames.EDIT_MAIN_PARAMETERS) && sameCompanyId,
		addPayouts:
			useIsAuthorized(permissionNames.ADD_UNIQUE_PAYOUTS) && sameCompanyId,
		openHistory:
			useIsAuthorized(permissionNames.OPENING_HISTORY) && sameCompanyId,
	}
	if (props.value === null || props.value === undefined) {
		return null
	}

	const handleDeleteEvent = (indexForDeletion: number, role: string) => {
		const new_value = props.value
			.filter((_: any, index: any) => index !== indexForDeletion)
			.map((el: any, index: any) => ({
				...el,
				order: index + 1, // Reassign order starting from 1
			}))

		if (role === 'M') {
			for (let rolekey of Object.keys(props.campaignObject.uniques)) {
				props.campaignObject.uniques[rolekey].tracked_event = new_value
			}
			props.setEventNamesChosen(
				props.campaignObject.uniques['M'].tracked_event.map(
					(e: any) => e.eventName,
				),
			)
		} else {
			props.campaignObject.uniques[role].tracked_event = new_value
		}

		props.onChange()
	}

	// const handleChangeEvent = (index: number) => {
	// 	for (let rolekey of Object.keys(props.campaignObject.uniques)) {
	// 		if (props.campaignObject.uniques[rolekey].tracked_event) {
	// 			props.campaignObject.uniques[rolekey].tracked_event[index].eventName =
	// 				props.value
	// 		}
	// 	}
	// }

	const handleChangeEvent = (index: number) => {
		props.campaignObject.uniques[props.role].tracked_event[index].eventName =
			props.value
		props.onChange()
	}

	const sortedEventList = props.value?.sort(
		(a: any, b: any) => a.order - b.order,
	)

	const checkLengthOfChosenNames: any = (eventNamesChosen: any) =>
		props.campaignObject.uniques['M'].tracked_event.length

	const getTotalMEventCount = (): number => {
		const res = props.campaignObject.uniques?.M?.tracked_event?.length || 0

		return res
	}

	const EventList = sortedEventList.map(
		(trackedEvent: TrackedEvent, index: number) => {
			if (
				props.campaignObject.uniques[props.role].tracked_event[index]
					?.eventName != undefined
			) {
				return (
					<EventListItem
						key={'eventList' + index + props.role}
						trackedEvent={trackedEvent}
						handleDeleteEvent={() => handleDeleteEvent(index, props.role)}
						handleChangeEvent={handleChangeEvent}
						index={index}
						role={props.role}
						isRevenueShare={props.isRevenueShare}
						options={props.options}
						loginRole={props.loginRole}
						campaignObject={props.campaignObject}
						eventNamesChosen={props.eventNamesChosen}
						setEventNamesChosen={props.setEventNamesChosen}
						externalFunction={props.externalFunction}
						listOfHooks={props.listOfHooks}
						setUniques={props.setUniques}
						isCostFromDate={props.isCostFromDate}
						permissions={permissions}
					/>
				)
			}
		},
	)

	if (props.available) {
		return (
			<div>
				<div>
					<EventWrapper
						container
						eventCount={EventList.length + 1}
						// parentWidth={
						// 	// ref.current ? (ref.current as any).offsetWidth : undefined
						// }
						key={'eventform'}
					>
						<StyledItemLabels
							style={{
								boxShadow: 'none',
								marginTop: 8,
								backgroundColor: theme.colors.base.white,
								marginRight: 24,
								color: theme.colors.text.titles,
								// padding: 12,
							}}
						>
							<StyledInputLabelEvent disabled={!permissions.addPayouts}>
								Event Name
							</StyledInputLabelEvent>
							<StyledInputLabelEvent disabled={!permissions.addPayouts}>
								{props.isRevenueShare
									? 'Shared Revenue (%)'
									: 'Default Revenue'}
							</StyledInputLabelEvent>
							<StyledInputLabelEvent disabled={!permissions.addPayouts}>
								Default Cost
							</StyledInputLabelEvent>
						</StyledItemLabels>
						{EventList}

						{props.loginRole !== 'view' &&
							(EventList.length > 7 || !permissions.addPayouts ? (
								<></>
							) : (
								<StyledItem
									style={{
										padding: '0.2%',
										width: '180px',
										display: 'flex',
										marginBottom: '40px',
										marginRight: '20px',
										marginTop: '4px',
										// border: '1px solid rgb(0, 0, 0, 0.3)',
										background: theme.colors.base.white,
										justifyContent: 'center',
										alignContent: 'center',
										color: theme.colors.text.titles,
										// transition: 'box-shadow 1.2s, margin 0.6s',
									}}
								>
									<Tooltip
										title={
											// If Q already has as many events as M => disable
											getTotalMEventCount() <= EventList.length &&
											props.role !== 'M'
												? 'Cannot exceed the total number of events in the main sub-campaign.'
												: ''
										}
										// Only show tooltip if the button is disabled
										disableHoverListener={
											!(
												getTotalMEventCount() <= EventList.length &&
												props.role !== 'M'
											)
										}
										disableFocusListener={
											!(
												getTotalMEventCount() <= EventList.length &&
												props.role !== 'M'
											)
										}
										disableTouchListener={
											!(
												getTotalMEventCount() <= EventList.length &&
												props.role !== 'M'
											)
										}
									>
										<span>
											<UnborderedButton
												label={'Add Event'}
												onClick={props.handleEventAddition}
												disableForRestrictedUsage={
													// If we have enough events in Q to match M — disable
													(getTotalMEventCount() <= EventList.length &&
														props.role !== 'M') ||
													!permissions.addPayouts
												}
												icon={
													<AddIcon
														style={{
															border: '1px solid ' + theme.colors.text.titles,
															borderRadius: '100px',
															width: '14px',
															height: '14px',
															color: theme.colors.text.titles,
														}}
													/>
												}
												style={{
													color: theme.colors.text.titles,
													marginLeft: -18,
												}}
											/>
										</span>
									</Tooltip>
								</StyledItem>
							))}
					</EventWrapper>
				</div>
			</div>
		)
	}
	return <div></div>
}
// <div
// 	style={{
// 		display: 'flex',
// 		justifyContent: 'center',
// 		// width: '66vw',
// 	}}
// >
// 	<PlusButton
// 		style={{ marginTop: '0px' }}
// 		onClick={props.handleEventAddition}
// 		disabled={
// 			(checkLengthOfChosenNames(props.eventNamesChosen) <=
// 				EventList.length &&
// 				props.role !== 'M') ||
// 			!permissions.addPayouts
// 		}
// 	>
// 		+
// 	</PlusButton>
// 	<Typography
// 		fontSize='11px'
// 		fontWeight='bold'
// 		style={{
// 			color: theme.colors.base.green300,
// 			position: 'relative',
// 			marginTop: '5px',
// 			marginLeft: '5px',
// 		}}
// 	>
// 		Add Event
// 	</Typography>
// </div>

export const ProgressBar = (props: {
	timeFrame: any
	amount: any
	progress: any
}) => {
	return <LinearProgress variant='determinate' {...props} />
}

export const EventListItem = (props: {
	trackedEvent: TrackedEvent
	index: number
	campaignObject: any
	handleChangeEvent: any
	handleDeleteEvent: any
	options: string[]
	role: string
	isRevenueShare: boolean
	loginRole: string
	eventNamesChosen: any
	setEventNamesChosen: any
	externalFunction: any
	listOfHooks: any
	setUniques: any
	isCostFromDate: any
	permissions: any
}) => {
	const theme = useTheme()
	const isInstall = props.index === 0 && props.trackedEvent.order === 1
	const [eventName, setEventName] = useState(
		isInstall
			? 'installs'
			: props.eventNamesChosen && props.eventNamesChosen[props.index]
			? props.eventNamesChosen[props.index]
			: '',
	)
	const [isImportant, setIsImportant] = useState(false)

	const [defaultRevenue, setDefaultRevenue] = useState<any>(
		props.campaignObject.uniques[props.role]
			? props.campaignObject.uniques[props.role].tracked_event[props.index]
					?.defaultRevenue
			: '',
	)
	const [defaultCost, setDefaultCost] = useState(
		props.campaignObject.uniques[props.role]
			? props.campaignObject.uniques[props.role].tracked_event[props.index]
					?.defaultCost
			: '',
	)
	const tryReturnInfoUpTheHierarchy = (new_value: string, index: number) => {
		for (let rolekeys of Object.keys(props.listOfHooks)) {
			const indexFound = parseInt(rolekeys.split('___')[1])
			if (index === indexFound) {
				props.listOfHooks[rolekeys](new_value)
			}
		}
	}

	const changeEventNameChosen = () => {
		const nameArray = props.campaignObject.uniques['M']?.tracked_event.map(
			(e: any) => e.eventName,
		)

		props.setEventNamesChosen(nameArray)
	}
	useEffect(() => {
		props.listOfHooks[props.role + '___' + props.index] = setEventName
	}, [])
	useEffect(() => {
		if (
			props.trackedEvent?.defaultRevenue > 0 ||
			props.trackedEvent?.defaultCost > 0
		) {
			setIsImportant(true)
		} else {
			setIsImportant(false)
		}
	}, [props.trackedEvent.defaultRevenue, props.trackedEvent.defaultCost])
	useEffect(() => {
		tryReturnInfoUpTheHierarchy(eventName, props.index)
	}, [eventName])
	useEffect(() => {
		setEventName(
			props.campaignObject.uniques['M']?.tracked_event[props.index]?.eventName,
		)
	}, [
		props.campaignObject.uniques[props.role].tracked_event[props.index]
			.eventName,
	])
	return (
		<StyledItem
			style={{
				padding: '0.2%',
				width: '180px',
				display: 'inline',
				marginBottom: '40px',
				marginRight: '20px',
				marginTop: 8,
				border: '1px solid ' + theme.colors.text.titles,
				background: theme.colors.base.white,
				color: theme.colors.text.titles,
				// transition: 'box-shadow 1.2s, margin 0.6s',
			}}
		>
			{props.index !== 0 ? (
				<PlusButton
					style={{
						background: theme.colors.base.grey300,
						color: theme.colors.text.titles,
						border: '1px solid ' + theme.colors.base.grey500,
						margin: '0px',
						left: '170px',
						top: '-12px',
						marginTop: '-5px',
						width: '20px',
						height: '20px',
						paddingTop: '6px',
						zIndex: '4',
						opacity: !props.permissions.addPayouts ? '0' : 1,
					}}
					onClick={(e: any) => props.handleDeleteEvent(props.index)}
					disabled={!props.permissions.addPayouts}
					// disabled={
					// 	props.loginRole === 'view' || props.loginRole === 'designer'
					// }
				>
					x
				</PlusButton>
			) : (
				<div
					style={{
						// margin: '0px',
						// marginTop: '-6px',
						width: '20px',
						height: '19px',
						paddingTop: '4px',
					}}
				></div>
			)}
			<FormControl
				key={props.index + '_name_control'}
				focused
				style={{
					width: '100%',
					borderRadius: '10px',
					marginTop: '-22px',
					background: 'transparent',
				}}
			>
				{props.role === 'M' && eventName ? (
					<EventNameAutocomplete
						name='eventName'
						id={'eventName' + props.role}
						disabled={isInstall || !props.permissions.addPayouts}
						// disabled={
						// 	isInstall ||
						// 	props.loginRole === 'view' ||
						// 	props.loginRole === 'designer' ||
						// 	props.role !== 'M'
						// }
						options={props.options}
						// key={props.index + '_select'}
						value={isInstall ? 'installs' : eventName}
						onChange={(e: any) => {
							props.campaignObject.uniques['M'].tracked_event[
								props.index
							].eventName = e

							setEventName(e)
						}}
						onBlur={() => changeEventNameChosen()}
					/>
				) : (
					//This is rendered if there is no eventName present in M
					<EventNameAutocomplete
						name='eventName'
						disabled={props.role !== 'M' || !props.permissions.addPayouts}
						id={'eventName'}
						options={props.options}
						// key={props.index + '_select'}
						value={
							isInstall
								? 'installs'
								: props.campaignObject.uniques['M'].tracked_event[props.index]
										?.eventName
						}
						onChange={(e: any) => {
							props.campaignObject.uniques['M'].tracked_event[
								props.index
							].eventName = e

							setEventName(e)
							// for(let rolekey in Object.keys(props.campaignObject.uniques)) {
							// 	props.campaignObject.uniques[rolekey].tracked_event[
							// 		props.index
							// 	].eventName = e
							// }
						}}
					/>
				)}
			</FormControl>
			<FormControl
				key={props.index + '_revenue_control'}
				focused
				style={{
					width: '100%',
					borderRadius: '10px',
					background: theme.colors.base.white,
				}}
			>
				<StyledInputEvent
					variant='outlined'
					focused
					name='defaultRevenue'
					type='number'
					key={props.index + '_revenue'}
					value={defaultRevenue}
					onChange={(e: any) => {
						setDefaultRevenue(e.target.value)
						props.campaignObject.uniques[props.role].tracked_event[
							props.index
						].defaultRevenue = e.target.value
						// props.handleChangeEvent(e, props.index, props.role)
					}}
					disabled={!props.permissions.addPayouts}
					// disabled={
					// 	props.loginRole === 'view' || props.loginRole === 'designer'
					// }
				/>
				{/* Ad agency account chooser to campaign, switch for incent/not incent */}
			</FormControl>
			<FormControl
				key={props.index + '_cost_control'}
				focused
				style={{ width: '100%', borderRadius: '10px' }}
			>
				<StyledInputEvent
					variant='outlined'
					focused
					name='defaultCost'
					type='number'
					key={props.index + '_cost'}
					value={props.isCostFromDate ? 0 : defaultCost}
					onChange={(e: any) => {
						setDefaultCost(e.target.value)
						props.campaignObject.uniques[props.role].tracked_event[
							props.index
						].defaultCost = e.target.value
					}}
					disabled={!props.permissions.addPayouts || props.isCostFromDate}
				/>
			</FormControl>
		</StyledItem>
	)
}

export const AppIdList = (props: {
	value: string | undefined
	onChange: any
	advertiser?: string
	disabled?: boolean
	style?: any
}) => {
	const theme = useTheme()
	const { app } = useTypedSelector((state) => state)
	const [open, setOpen] = useState(false)
	const [notification, setNotification] = useState(false)
	var appList: JSX.Element[] = []

	if (app.app.length > 0) {
		appList = app.app.map((adv: AppInterface, key: number) => {
			if (props.advertiser === adv.advertiser_name) {
				return (
					<MenuItem key={key} value={adv.app_id}>
						{adv.app_id}
					</MenuItem>
				)
			}
			return null
		})
	}

	return (
		<div>
			<StyledInputLabel
			// required={props.required}
			>
				App ID
			</StyledInputLabel>
			<StyledSelect
				labelId='demo-simple-select-label'
				id='demo-simple-select'
				value={props.value || ''}
				onChange={props.onChange}
				IconComponent={KeyboardArrowDownIcon}
				open={open}
				style={{ ...props.style }}
				disabled={props.disabled}
				renderValue={(value) => {
					return (
						<>
							<div
								style={{
									width: '100%',
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								<span>{value as string}</span>
								<button
									title={'Copy App Id'}
									onClick={() => {
										navigator.clipboard.writeText(value as string)
										setNotification(true)
										setTimeout(() => setNotification(false), 800)
									}}
									style={{
										background: 'none',
										border: 'none',
										cursor: 'pointer',
										marginRight: '24px',
										marginTop: '4px',
									}}
									id={'copyButton'}
								>
									<ContentCopyIcon
										style={{ scale: '0.8' }}
										id={'copyButton'}
									></ContentCopyIcon>
								</button>
								<p
									style={{
										display: notification ? 'flex' : 'none',
										border: '1px solid black',
										borderRadius: '6px',
										padding: '4px',
										position: 'absolute',
										right: '18%',
										fontSize: '12px',
										color: theme.colors.text.titles,
										background: theme.colors.base.white,
									}}
								>
									Copied
								</p>
							</div>
						</>
					)
				}}
				onClick={(e: any) => {
					if (e.target.id !== 'copyButton') {
						setOpen(!open)
					}
				}}
			>
				{appList}
			</StyledSelect>
		</div>
	)
}

const StyleForSelectedOption = (value: string, list: string[]) => {
	if (list.indexOf(value) !== -1) {
		return { fontWeight: '900', backgroundColor: 'rgba(238,238,238,0.7)' }
	}
}

export const DropList = (props: {
	onChange: any
	disabled?: boolean
	float?: 'right' | 'left'
	options: string[]
	label: string
	value: any
	fontSize?: string
	multiple?: boolean
	noNull?: boolean
	noBackground?: boolean
	customColor?: string
	fontWeight?: string
	errored?: boolean
	style?: any
}) => {
	return (
		<div
			style={{
				width: '100%',
				marginTop: '0px',
				...props.style,
			}}
		>
			<StyledInputLabel
				disabled={props.disabled}
				style={{
					fontSize: props.fontSize ? props.fontSize : '12px',
				}}
				// required={props.required}
			>
				{props.label}
			</StyledInputLabel>
			<Autocomplete
				disabled={props.disabled}
				style={{
					// height: '38px',
					width: '100%',
					minHeight: '40px',
					// backgroundColor: props.errored ? '#eeafaf' : 'rgba(238,238,238,0.6)',
					boxShadow: props.errored ? 'red 0px 0px 7px' : 'none',
					background: props.noBackground ? '' : 'rgb(243,243,243)',
					backgroundColor: props.noBackground ? '' : 'rgba(238,238,238,0.6)',
					borderRadius: '4px',
					marginTop: '0px',
				}}
				sx={{
					'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
						border: 'none',
						padding: '0px',
					},
					'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
						border: 'none',
						padding: '0px',
					},
					'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
						// border: 'none',
						color: props.customColor ? props.customColor : 'currentColor',
						padding: '0px',
						fontSize: props.fontSize ? props.fontSize : '14px',
						fontWeight: props.fontWeight ? props.fontWeight : '500',
					},
				}}
				options={props.options}
				renderInput={(params) => (
					<TextField
						{...params}
						style={{
							height: 'inherit',
							fontSize: props.fontSize ? props.fontSize : '12px',
						}}
						// placeholder={props.label}
					/>
				)}
				getOptionLabel={(option) => {
					return option?.toString() ?? ''
				}}
				onChange={(e: any, value: any) => {
					if (props.noNull && !value) {
						props.onChange('')
					} else {
						props.onChange(value)
					}
				}}
				value={props.value}
				isOptionEqualToValue={(option: any, value: any) => {
					if (typeof option === 'object') {
						return (
							value === '' ||
							option.email.toLowerCase() ===
								(value && typeof value === 'string' ? value.toLowerCase() : '')
						)
					} else {
						return (
							value === '' ||
							option.toLowerCase() ===
								(value && typeof value === 'string' ? value.toLowerCase() : '')
						)
					}
				}}
			/>
		</div>
	)
}

export const TabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			<Box style={{ height: '200%' }}>{children}</Box>
		</div>
	)
}
