import InvalidReportsPage from './pages/reports/invalid/InvalidPage'
import { NetworkReportTable } from './pages/reports/network/ReportsTable'
import { ReportsTable as AggregatedReportTable } from './pages/reports/aggregated/ReportsTable'
import { ReportsTable as InvalidReportTable } from './pages/reports/invalid/ReportsTable'
import { ReportType } from './models/reports.interface'

export const CLIENT_VERSION = '1.0.0' //THE HARDCODED VERSION NUMBER NEEDS TO BE CHECKED WITH SERVER EVERY PUSH
export const UNDERMAINTENANCE = false
export const API = 'api/'

export const devUrl = process.env.REACT_APP_DEV_URL
export const wsDevUrl = process.env.REACT_APP_WS_DEV_URL
export const lmsDevUrl = 'http://localhost:4001/api'
export const lmsProdUrl = 'https://www.theskillshop.io/api'
export const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY
export const CRYPTO_ALGORITHM = process.env.REACT_APP_CRYPTO_ALGORITHM
export const AEC_ALGORITHM = 'AES-GCM'
export const ENCRYPTION_PREFIX = 'ENC:'
export const HTTPS_PROTOCOL = 'https:'
export const WSS_PROTOCOL_SECURE = 'wss://'
export const WS_PROTOCOL = 'ws://'
export const DEFAULT_WS_URL = 'ws://localhost:8000/ws' // Provide a fallback default WebSocket URL
export const SYSTEM_DARK_MODE_QUERY = '(prefers-color-scheme: dark)'
export const MIN_LOADING_TIME = 3000 // in milliseconds
export const MAX_PROGRESS_BEFORE_DATA = 90 // Maximum progress percentage before data is ready
export const S3_MAX_RETRIES = 3
export const S3_RETRY_DELAY_BASE = 500
export const THING_OR_TWO_COMPANY_ID = 83838320

export const S3_VARYS_ICON_URL_BLACK =
	'https://ads-placement-bucket.s3.eu-central-1.amazonaws.com/varys-logo-black.png'
export const S3_VARYS_ICON_URL_COLORED =
	'https://ads-placement-bucket.s3.eu-central-1.amazonaws.com/varys-logo-colored.png'
export const S3_VARYS_ICON_URL_WHITE =
	'https://ads-placement-bucket.s3.eu-central-1.amazonaws.com/varys-logo-white.png'
export interface routeItem {
	route: PAGE_NAVIGATION | null
	displayString: string
	icon?: string
}
export enum PAGE_NAVIGATION {
	NEWS = 'News',
	ADVERTISERS = 'Advertisers',
	APPS = 'Apps',
	PUBLISHERS = 'Publishers',
	CAMPAIGN = 'Campaigns',
	AGGREGATED = 'Aggregated',
	DATACENTER = 'Data Center',
	USERS = 'Users',
	APPSFLYER = 'Appsflyer',
	LOGS = 'Logs',
	NETWORK = 'Network',
	RAWDATA = 'RawData',
	SETTINGS = 'Settings',
	PASSWORDS = 'Passwords',
	DOCUMENTS = 'Documents',
	//DASHBOARD = 'Dashboard',
	AUTOSWITCH = 'Autoswitch',
	P360DASHBOARD = 'P360DASHBOARD',
}

export const routes: routeItem[] = [
	{
		route: PAGE_NAVIGATION.NEWS,
		displayString: 'News',
		icon: '<path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />',
	},
	{
		route: null,
		displayString: 'Dashboard',
		icon: `
	  <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
	`,
	},
	{
		route: PAGE_NAVIGATION.ADVERTISERS,
		displayString: 'Advertisers',
		icon: `
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    `,
	},
	{
		route: PAGE_NAVIGATION.PUBLISHERS,
		displayString: 'Publishers',
		icon: `
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    `,
	},
	{
		route: PAGE_NAVIGATION.APPS,
		displayString: 'Apps',
		icon: `
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    `,
	},
	{
		route: PAGE_NAVIGATION.CAMPAIGN,
		displayString: 'Campaigns',
		icon: `
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    `,
	},
	{
		route: null,
		displayString: 'Selector',
		icon: `
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    `,
	},
	{
		route: null,
		displayString: 'Finance',
		icon: `
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    `,
	},
	{
		route: null,
		displayString: 'Tools',
		icon: `
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    `,
	},
	{
		route: PAGE_NAVIGATION.USERS,
		displayString: 'Users',
		icon: `
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    `,
	},
	{
		route: PAGE_NAVIGATION.PASSWORDS,
		displayString: 'Passwords',
		icon: `
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    `,
	},
	{
		route: PAGE_NAVIGATION.DOCUMENTS,
		displayString: 'Documents',
		icon: `
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    `,
	},
	{
		route: PAGE_NAVIGATION.AGGREGATED,
		displayString: 'Aggregated',
		icon: `
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    `,
	},

	{
		route: PAGE_NAVIGATION.NETWORK,
		displayString: 'Network',
		icon: `
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    `,
	},
	{
		route: PAGE_NAVIGATION.RAWDATA,
		displayString: 'RawData',
		icon: `
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    `,
	},
	{
		route: PAGE_NAVIGATION.AUTOSWITCH,
		displayString: 'Autoswitch',
		icon: '<path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />',
	},
	// {
	// 	route: PAGE_NAVIGATION.P360DASHBOARD,
	// 	displayString: 'P360Dashboard',
	// 	icon: '<path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />',
	// },
]

export enum endpoints {
	USER = 'user',
	REGISTER = 'register',
	SIGNUP = 'signup',
	SIGNUPDATA = 'signupdata',
	RESET_PASSWORD = 'reset_password',
	LOGIN = 'login',
	LOGOUT = 'logout',
	ADVERTISER = 'advertiser',
	ADVERTISER_META = 'advertisermeta',
	ADVERTISER_BY_COMPANY_ID = 'advertiser_by_id',
	CAMPAIGN = 'campaign',
	PUBLISHER = 'publisher',
	PUBLISHER_MASTER = 'publishermaster',
	PUBLISHER_META = 'publishermeta',
	PUBLISHER_WISHLIST = 'publisherwishlist',
	PUBLISHER_WISHLIST_DELETE = 'publisherwishlistdelete',
	NEWS = 'news',
	NEWS_I = 'news/image',
	LOGS = 'logs',
	SETTINGS = 'settings',
	COMPANY_SETTINGS = 'company_settings',
	COMPANY_AGENCY_ACCESS = 'company_agency_access',
	COMPANY = 'company',
	COMPANIES = 'companies',
	REPORTS = 'reports',
	APPSFLYER = 'appsflyer',
	SAVE = 'appsflyer/save',
	APP = 'app',
	P360APP = 'app/p360',
	P360APPADD = 'app/p360/add',
	P360APPREMOVE = 'app/p360/removerule',
	REMOVEP360APP = 'app/p360/remove',
	ICONS = 'icons',
	APPUPDATE = 'app/update',
	PASSWORD = 'password',
	PRESET = 'preset',
	RAWDATA = 'rawdata',
	NETWORK = 'network',
	NETWORK_U = 'network/update',
	APPSFLYER_U = 'appsflyer/update',
	STORAGE = 'storage',
	UPDATE = 'update',
	FORGOTPASSWORD = 'forgotpwd',
	UNIQUE_CARD = 'unique_card',
	PASSWORDS = 'passwords',
	CHANGE_PASSWORD_WITH_TOKEN = 'changepasswordwithtoken',
	SHOW_PASSWORD = 'showpasswords',
	INVALID = 'invalid',
	DOCUMENTS = 'documents',
	DASHBOARD = 'dashboard',
	APPSFLYER_ACCOUNTS = 'appsflyeraccounts',
	CAMPAIGNHISTORY = 'campaignhistory',
	AVATAR = 'avatar',
	FINANCE = 'finance',
	AUTOSWITCH = 'autoswitch',
	ADJUST = 'adjust',
	VERSION = 'version',
	P360DASHBOARD = 'p360dashboard',
	p360aggregated = 'p360aggregated',
	SALES_PLAN = 'salesplan',
	ADS_PLACEMENT = 'adsplacement',
	CREATOR = 'creator',
	CREATORS = 'creators',
	HANDSHAKE = 'handshake',
	LOGGED_IN_USER = 'loggedinuser',
	LIST_ALL_PERMISSIONS = 'listallpermissions',
	GET_ROLES = 'getroles',
	GET_ROLE = 'getrole',
	CREATE_ROLE = 'createrole',
	UPDATE_ROLE = 'updaterole',
	DELETE_ROLE = 'deleterole',
	UPDATE_ROLES = 'updateroles',
	REPORT = 'report',
	REDIRECT_TO_LMS = 'redirecttolms',
	UPDATE_USERS_LMS_STATUS = 'updateuserslmsstatus',
	SUPPORT = 'support',
	// notifications
	NOTIFICATIONS = 'notifications',
	GET_ALL_NOTIFICATIONS = 'notifications/all',
	GET_NOTIFICATION_BY_ID = 'notifications/:id',
	CREATE_NOTIFICATION = 'notifications/create',
	UPDATE_NOTIFICATION = 'notifications/update/:id',
	DELETE_NOTIFICATIONS = 'notifications/delete',
	MARK_AS_READ = 'notifications/markasread',
	MARK_AS_UNREAD = 'notifications/markasunread',
	GET_UNREAD_COUNT = 'notifications/unreadcount',
	GET_NOTIFICATION_BY_EMAIL = 'notifications/user/:email',
}

export const reportEndpoints = {
	INVALID_USER: '/invalid-report-user',
	INVALID_DASHBOARD: '/invalid-report-dashboard',
	AGGREGATED_USER: '/aggregated-report-user',
	AGGREGATED_DASHBOARD: '/aggregated-dashboard',
	NETWORK_USER: '/network-report-user',
	ALL_REPORTS_PER_USER: '/user-reports',
}

export const reportComponentMapping: Record<
	ReportType,
	React.ComponentType<any>
> = {
	network: NetworkReportTable,
	invalid: InvalidReportTable,
	aggregated: AggregatedReportTable,
}

export const reportTypeMapping: Record<
	ReportType,
	keyof typeof reportEndpoints
> = {
	network: 'NETWORK_USER',
	invalid: 'INVALID_USER',
	aggregated: 'AGGREGATED_USER',
}

export function getReportMappingString(
	reportType: ReportType,
): keyof typeof reportEndpoints {
	return reportTypeMapping[reportType]
}

export const REPORTS_TYPES = {
	NETWORK: 'network',
	INVALID: 'invalid',
	AGGREGATED: 'aggregated',
	LOGGED_IN_USER: 'loggedinuser',
	LIST_ALL_PERMISSIONS: 'listallpermissions',
	GET_ROLES: 'getroles',
	GET_ROLE: 'getrole',
	CREATE_ROLE: 'createrole',
	UPDATE_ROLE: 'updaterole',
	DELETE_ROLE: 'deleterole',
	UPDATE_ROLES: 'updateroles',
}

export const reportRoutes: Record<ReportType, string> = {
	network: '/network',
	invalid: '/invalid',
	aggregated: '/aggregated',
}

export const getReportRoute = (reportType: ReportType): string => {
	const routes = {
		network: '/network',
		invalid: '/invalid',
		aggregated: '/aggregated',
	}

	return routes[reportType] || '/'
}

export const reverseReportTypeMapping: Record<
	keyof typeof reportEndpoints,
	ReportType | undefined
> = {
	NETWORK_USER: 'network',
	INVALID_USER: 'invalid',
	AGGREGATED_USER: 'aggregated',
	INVALID_DASHBOARD: undefined,
	AGGREGATED_DASHBOARD: undefined,
	ALL_REPORTS_PER_USER: undefined,
}

export const entityEndpoints: Record<
	'advertiser' | 'publisher' | 'creator',
	keyof typeof endpoints
> = {
	advertiser: 'ADVERTISER',
	publisher: 'PUBLISHER',
	creator: 'CREATOR',
}

export const ACCEPTED_FILES = {
	DEFAULT: '*', // Accepts all file types
	IMAGES_ONLY: '.jpg, .jpeg, .png, .svg', // Specific types for the advertiser
	VIDEO: 'video/*',
}

export const companyTypeConfig = {
	advertiser: {
		shouldInclude: (pub: any, companyId: string) => true, // Advertiser sees all PIDs
	},
	agency: {
		shouldInclude: (pub: any, companyId: string) => true, // Agency sees all PIDs
	},
	publisher: {
		shouldInclude: (pub: any, companyId: string) =>
			pub.company_id === companyId, // Publisher sees its own PIDs
	},
}

export const HTTP_STATUS_CODES = {
	OK: 200,
	CREATED: 201,
	NO_CONTENT: 204,
	BAD_REQUEST: 400,
	UNAUTHORIZED: 401,
	FORBIDDEN: 403,
	NOT_FOUND: 404,
	CONFLICT: 409,
	CONTENT_TOO_LARGE: 413,
	INTERNAL_SERVER_ERROR: 500,
	BAD_GATEWAY: 502,
	SERVICE_UNAVAILABLE: 503,
} as const

export const HTTP_RESPONSE_MESSAGES: Record<number, string> = {
	[HTTP_STATUS_CODES.OK]: 'OK - The request was successful.',
	[HTTP_STATUS_CODES.BAD_REQUEST]:
		'Bad Request - The request could not be understood or was missing required parameters.',
	[HTTP_STATUS_CODES.UNAUTHORIZED]:
		'Unauthorized - Authentication failed or user does not have permissions for the desired action.',
	[HTTP_STATUS_CODES.FORBIDDEN]:
		'Forbidden - Authentication succeeded but authenticated user does not have access to the requested resource.',
	[HTTP_STATUS_CODES.NOT_FOUND]:
		'Not Found - The requested resource could not be found.',
	[HTTP_STATUS_CODES.CONFLICT]:
		'Conflict - The request could not be completed due to a conflict with the current state of the target resource.',
	[HTTP_STATUS_CODES.INTERNAL_SERVER_ERROR]:
		'Internal Server Error - An error occurred on the server.',
	[HTTP_STATUS_CODES.BAD_GATEWAY]:
		'Bad Gateway - The server was acting as a gateway or proxy and received an invalid response from the upstream server.',
	[HTTP_STATUS_CODES.SERVICE_UNAVAILABLE]:
		'Service Unavailable - The server is not ready to handle the request.',
}
