import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useRef,
	useState,
} from 'react'
import { PageWrapper } from '../components/reusableComponents'
import { Box, ThemeProvider, useTheme } from '@mui/material'
import { StyledTab, StyledTabs } from '../advertisers/AdvertiserStyled'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { TabPanel } from '../campaigns/CampaignComponents'
import { useParams, useNavigate } from 'react-router'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import SupplyBreakdown from './SupplyBreakdown'
import SupplyStatus from './SupplyStatus'
import './finance.css'
import { SubmitButton, UnborderedButton } from '../components/Buttons'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

import { TitleComponent } from '../components/Informative'
import { useActions, useFinanceActions } from '../../hooks/useActions'
import { useDispatch } from 'react-redux'
import { emptyFinance } from '../../state/actions'
import { TabContainer } from '../components/TabComponent'

//this is the supply preview page. It will render 2 tabs. One for breakdown and one for status. Each of them have their own file.
const SupplyPreview = (props: {
	setLoading: any
	errorMessage: any
	setErrorMessage: any
	isErrorPromptOpened: any
	setIsErrorPromptOpened: any
	isEditorOpened?: any
	isAddEditorOpened?: any
	PopUpVisible: any
	setPopUpVisible: any
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	presetSearch: string
	setPresetSearch: Dispatch<SetStateAction<string>>
}) => {
	const theme = useTheme()
	const [tab, setTab] = useState<number>(0)
	const params = useParams()
	const navigate = useNavigate()
	const [isEmailPreviewOpen, setIsEmailPreviewOpen] = useState(false)
	const { getInternalFinanceData } = useFinanceActions()
	const { login, finance } = useTypedSelector((state) => state)
	// const headers: any = {
	// 	Authorization: `Token ${login.user.token}`,
	// }
	const [publisher, setPublisher] = useState<any>({})
	const [data, setData] = useState<any>([])
	const [oldSearchValue, setOldSearchValue] = useState<string>('')
	// const [month, setMonth] = useState<any>('')
	// const [paramsUuid, setParamsUuid] = useState<string>('')
	//we use the uuid in order to find the row. With that we can find the publisher and the period (month).
	const paramsUuid = params?.id?.split('-supply-')[0]
	// const foundRow = finance.finance.filter((el: any) => {
	// 	return el.uuid === paramsUuid
	// })
	// const publisher = foundRow[0].publisher
	const month = params?.id?.split('-supply-')[1].replaceAll('-', '/')
	// const [updateTriggerer, setUpdateTriggerer] = useState('')

	//tab logic that we use in multiple components.
	const [dataFetched, setDataFetched] = useState(false)
	const dispatch = useDispatch()
	const hasFetched = useRef(false)
	const [mainPublisher, setMainPublisher] = useState('')

	const fetchData = async () => {
		let i = 0
		let result
		do {
			result = await getInternalFinanceData(
				'supply',
				props.errorMessage,
				params.id!,
				login.user.email,
				50,
				i,
			)
			i++
			setDataFetched(true)
		} while (
			result &&
			result.length > 0 &&
			window.location.pathname.includes('-supply-')
		)
	}

	const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue)
	}
	function a11yProps(index: number) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		}
	}
	const closeDetailedView = () => {
		props.setSearch(oldSearchValue)
		setDataFetched(false)
		navigate('/finance/supply')
	}

	const tabOptions = [
		{ label: 'Breakdown', tabIndex: 0 },
		{ label: 'Status', tabIndex: 1 },
	]

	useEffect(() => {
		dispatch(emptyFinance())
		//this needs to be added for the StrictMode. Otherwise data is duplicated.
		if (hasFetched.current) {
			return
		}
		hasFetched.current = true
		fetchData()
		setOldSearchValue(props.search)
	}, [])
	// useEffect(() => {
	// 	console.log('search value', props.search)
	// }, [props.search])

	useEffect(() => {
		// console.log(dataFetched, finance.finance)
		props.setSearch('')
		if (dataFetched) {
			const currentUuid = params.id!.split('-supply-')[0]
			const foundRow = finance.finance.filter((el: any) => {
				return el.uuid === currentUuid
			})
			if (foundRow[0]) {
				const publisherName = foundRow[0].publisher
				const currentMonth = params
					.id!.split('-supply-')[1]
					.replaceAll('-', '/')
				// setParamsUuid(currentUuid)
				// setMonth(currentMonth)
				setPublisher(publisherName)
				setMainPublisher(publisherName)
			}
			setData(finance.finance)
		}
	}, [finance.finance])

	return (
		<ThemeProvider theme={theme}>
			{isEmailPreviewOpen && (
				<div
					style={{
						display: 'flex',
						position: 'absolute',
						width: '100%',
						height: '100%',
						background: 'rgba(0,0,0,0.6)',
						top: '0%',
						left: '0%',
						zIndex: '20',
					}}
				></div>
			)}
			<div
				style={{
					width: '70%',
					// paddingTop: '20px',
					// position: 'absolute',
					zIndex: '11',
					// top: 150,
					// left: 40,
					marginTop: 20,
				}}
			>
				<TitleComponent
					style={{
						// marginBottom: '12px',
						marginLeft: 50,
						marginTop: 30,
						fontSize: theme.font.size.titles,
						color: theme.colors.text.titles,
						display: 'flex',
						gap: '8px',
					}}
				>
					{mainPublisher}
				</TitleComponent>
				<UnborderedButton
					style={{
						width: '80px',
						display: 'flex',
						justifyContent: 'flex-start',
						// margin: 'auto',
						marginTop: 20,
						// position: 'absolute',
						// top: 26,
					}}
					onClick={() => closeDetailedView()}
					icon={
						<ArrowBackIosIcon
							style={{
								height: '16px',
								marginRight: '0px',
								marginLeft: 40,
							}}
						/>
					}
				>
					Back
				</UnborderedButton>
				{/* <div
					style={{
						marginLeft: 660,
						marginTop: -70,
						display: 'flex',
					}}
				>
					<StyledTabs
						value={tab}
						onChange={handleChangeTab}
						aria-label='basic tabs example'
					>
						<StyledTab
							style={{
								paddingLeft: '20px',
								paddingRight: '20px',
								textTransform: 'none',
								color: theme.colors.text.titles,
							}}
							label='Breakdown'
							{...a11yProps(0)}
						/>
						<StyledTab
							style={{
								paddingLeft: '20px',
								paddingRight: '20px',
								textTransform: 'none',
								color: theme.colors.text.titles,
							}}
							label='Status'
							{...a11yProps(1)}
						/>
					</StyledTabs>
				</div> */}
				<TabContainer
					tab={tab}
					handleChangeTab={handleChangeTab}
					tabProps={a11yProps}
					options={tabOptions}
					tabStyle={{
						textTransform: 'none',
						minWidth: '8vw',
						fontSize: theme.font.size.buttons,
					}}
					mainStyle={{
						display: 'flex',
						position: 'absolute',
						left: '42%',
						top: '20%',
					}}
				/>
			</div>
			<PageWrapper
				style={{
					paddingTop: '30px',
					maxHeight: '200%',
					overflow: 'visible',
					height: 'fit-content',
					marginTop: 14,
				}}
			>
				<TabPanel value={tab} index={0}>
					<SupplyBreakdown
						setLoading={props.setLoading}
						setErrorMessage={props.setErrorMessage}
						publisher={publisher}
						month={month}
						paramsUuid={paramsUuid}
						userEmail={login.user.email}
						setIsEmailPreviewOpen={setIsEmailPreviewOpen}
						search={props.search}
						setSearch={props.setSearch}
						presetSearch={props.presetSearch}
						setPresetSearch={props.setPresetSearch}
						data={data}
					></SupplyBreakdown>
				</TabPanel>
				<TabPanel value={tab} index={1}>
					<SupplyStatus
						setLoading={props.setLoading}
						setErrorMessage={props.setErrorMessage}
						publisher={publisher}
						month={month}
						paramsUuid={paramsUuid}
						data={data}
					></SupplyStatus>
				</TabPanel>
			</PageWrapper>
		</ThemeProvider>
	)
}

export default SupplyPreview
